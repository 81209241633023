/*=== 10. GENERIC CONTENT ===*/
.genricContent{
	h3{
		margin-bottom: 30px;
		font-weight: 400;
	}
	p{
		color: $text-slider-title;
		font-size: 14px;
		line-height: 22px;
		margin-bottom: 30px;
	}
	h4{
		color: $secondary-color;
		font-weight: 400;
		margin-bottom: 20px;
	}
}