/*=== 2.1 HEADER V1 ===*/
.header{
	background-color: $white-color;
	position: relative;
	top: 0;
	width: 100%;
	z-index: 999;
}
.navbar-sticky {
	@include box-shadow (0, 2px, 4px, rgba(0, 0, 0, 0.1));
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 999;
}
/*=== 2.1.1 TOPBAR ===*/
.topBar{
	width: 100%;
	display: block;
	min-height: 35px;
	height: auto;
	background-color: $secondary-color;
	ul{
		li{
			padding-right: 5px;
			padding-left: 5px;
			a{
				padding:0 3px;
				display: block;
				line-height: 35px;
				font-size: 14px;
				@include transition(all 0.3s ease-in-out);
				color: $text-slider-title;
				&:hover {
					@include opacity(.8);
				}
			}
		}
	}
	ul.float-right{
		li{
			padding: 0;
			float: left;
			position: relative;
			a{
				font-size: 12px;
				display: inline-block;
			}
		}
		>li{
			margin: 0 0 0 10px;
			&.cart-dropdown {
				margin-left: 3px;
			}
		}
		>li:nth-child(2)::before{
			content: "";
			position: absolute;
			top: 6px;
			height: 24px;
			width: 1px;
			background: rgba($white-color, 0.2);
		}
	}
}
.display-block{
	display:block;
}
.display-none{
	display:none;
}
.top-right .account-login{
	span{
		small{
			color: rgba($white-color, 0.5);
			padding: 0 10px;
		}
		a{
			color: #d9d9d9;
			@include transition(all 0.3s ease-in-out);
			&:hover{
				color: rgba($white-color, 0.5);
			}
		}
	}
}
.top-right .searchBox{
	position:relative;
	a{
		color: $white-color;
		@include transition(all 0.3s ease-in-out);
		padding: 0 10px;
		&:hover{
			opacity: .5;
		}
		i{
			font-size: 16px;
			width: 20px;
		}
	}
	.dropdown-menu{
		padding: 10px 15px;
		border-radius: 0;
		right: 0;
		left: auto;
		@include mobile-xs {
			width: 280px;
			margin: 0 -65px 0 0;
		}
		@include mobile {
			width: 350px;
			margin: 0;
		}
		li{
			line-height: 0;
			width: 100%;
		}
	}
}
.searchBox .input-group{
	width: 100%;
	position: relative;
	.form-control{
		box-shadow: none;
		border-radius: 0;
		height: 34px;
		line-height: 34px;
		&:focus{
			border-color: $primary-color;
		}
	}
	.input-group-addon{
		border-radius: 0;
		background-color: $primary-color;
		color: $white-color;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 700;
		border-color: $primary-color;
	    width: auto;
	    position: absolute;
	    top: 0;
	    right: 0;
	    z-index: 9;
		height: 34px;
		&:focus {
			outline: none;
		}
	}
}
.top-right .cart-dropdown,
.cart-dropdown{
	a{
		background-color: $primary-color;
		line-height: 35px;
		min-height: 36px;
		font-size: 12px;
		color: $white-color;
		display: block;
		@include transition(all 0.3s ease-in-out);
		@include mobile-xs {
			padding: 0 7px;
		}
		@include mobile {
			padding: 0 10px;
		}
		@include tablet {
			font-size: 13px;
			padding: 0 19px;
		}
		i{
			font-size: 18px;
			margin-right: 10px;
		}
		&:hover{
			background-color: $primary-color;
			@include opacity(1);
		}
		&:focus{
			@include opacity(1);
		}
		&:after {
			border: none;
			display: none;
		}
	}
	ul.dropdown-menu{
		background-color: $primary-color;
		border-radius: 0;
		margin: 0;
		box-shadow: none;
		border: none;
		z-index: 9999;
		@include mobile-xs {
			width: 280px;
		}
		@include mobile {
			width: 300px;
		}
		@include tablet {
			width: auto;
		}
		li:first-child{
			color: $white-color;
			padding: 10px 0 8px 19px;
		}
		li{
			width: 100%;
			a{
				line-height: 26px;
				display: block;
				.media{
					@include add-border(1px, rgba(0, 0, 0, 0.1), bottom);
					padding: 12px 0;
					.media-body{
						h5{
							color: $white-color;
							line-height: 20px;
							letter-spacing: 1px;
							span{
								font-size: 18px;
							}
						}
					}
				}
				&:hover{
					opacity: .8;
					background-color: transparent;
				}
			}
		}
		li{
			.btn-group{
				padding: 14px 19px;
				.btn{
					border: none;
					height: 36px;
					padding: 0;
					line-height: 36px;
					font-size: 13px;
					font-weight: 700;
					letter-spacing: 1px;
					border-radius: 4px!important;
					color: $secondary-color;
					background-color: #f8f8f8;
					@include transition(all 0.3s ease-in-out);
				}
				button:first-child{
					&:hover{
						opacity: .8;
					}
					@include mobile-xs {
						width: 135px;
					}
					@include mobile {
						width: 149px;
					}
				}
				button:last-child{
					margin-left: 8px;
					@include mobile-xs {
						width: 100px;
					}
					@include mobile {
						width: 109px;
					}
					&:hover{
						opacity: .8;
						color: #777777;
					}
				}

			}
		}
	}
}

/*=== 2.1.2 NAVBAR ===*/
.navbar-brand{
	width:auto;
	height:auto;
	img {
		height: 40px;
	}
	@include transition(height 0.3s, width 0.3s);
}
.navbar-default{
	box-shadow: 0 2px 4px rgba(0,0,0,0.1);
	background-color: $white-color;
	margin-bottom: 0;
	padding: 10px 0;
	border: none;
	@include tablet {
		padding: 0;
	}
	.container{
		position: relative;
	}

	.navbar-toggler{
		background-color: $primary-color;
		border-radius: 0;
		border: none;
		padding: 5px 8px;
		margin-right: 15px;
		margin-top: 0px;
		color: $white-color;
		font-size: 22px;
		position: absolute;
		right: 0;
		z-index: 555;
		top: 12px;
		&:focus {
			border: none;
			outline: none;
		}
	}
	.navbar-brand{
		display: inline-block;
		padding: 15px 15px;
		@include mobile {
			padding:15px;
		}
		@include tablet {
			position: absolute;
			z-index: 5;
			padding: 0;
		}
	}
	.navbar-collapse.show {
		overflow-y: auto;
		right: 0;
	}
	.navbar-collapse{
		width: 100%;
		top: 100%;
		// border-top: 1px solid #e7e7e7;
		@include tablet {
			position: relative;
			margin-top: 0px;
			box-shadow: none;
		}
		background:#ffffff;
		@include mobile-xs{
			max-height: 250px;
		}
		@include mobile{
			max-height: 250px;
		}
		@include tablet{
			height: auto;
			padding: 0 0;
		}
		.navbar-nav {

			&.ml-auto {
				@include tablet {
					margin: 0px 0;
					margin-right: -15px;
				}
			}
		}
		li{
			a.nav-link{
				font-size: 14px;
				color: $secondary-color;
				text-transform: uppercase;
				font-weight: 700;
				@include transition(all 0.3s ease-in-out);
				padding: 10px 15px;
				@include tablet {
					padding:26px  8px;
					font-size: 12px;
					line-height: 1;
				}
				@include desktops {
					letter-spacing: 1px;
					padding:37px  15px;
					font-size: 14px;
				}
				&:hover, &.focus, &:focus{
					color: $primary-color;
				}
				&.dropdown-toggle:after {
					border: none;
				}
			}
			&.dropdown{
				.dropdown-menu{
					box-shadow: none;
					margin-top: 0;
					border-radius: 0px;
					li{
						a{
							font-weight: 300;
							padding: 10px 25px;
							text-transform: capitalize;
							width: 100%;
							font-size: 14px;
							color: $secondary-color;
							float: left;
							&:hover{
								color: $primary-color;
							}
						}
					}
					@include mobile-xs{
						position: relative;
						width: 100%;
						float: left;
						border: 0;
						display: block;
					}
					@include mobile{
						position: relative;
						width: 100%;
						float: left;
						border: 0;
						display: block;
					}
					@include tablet{
						position: absolute;
						float: left;
						@include add-border(1px, $border-lighter, all);
						display: none;
					}
				}
			}
		}
		li.active{
			a{
				background-color: transparent;
				color: $primary-color;
				&:focus, &:hover{
					background-color: transparent;
					color: $primary-color;
				}
			}
			&.dropdown{
				.dropdown-menu{
					li{
						a{
							color: $secondary-color;
							&:hover{
								color: $primary-color;
							}
						}
					}
				}
			}
		}
		li.dropdown{
			&.show {
				a{
					&:hover, &.focus, &:focus{
						background-color: transparent;
						color: $primary-color;
					}
				}
				.dropdown-menu{
					box-shadow: none;
					border-radius: 0;
					margin-top: 0px;
					min-width: 200px;
					display: block;
					border-radius: 0px;
					li{
						a{
							padding: 10px 25px;
							font-weight: 300;
							text-transform: capitalize;
							width: 100%;
						}
					}
					li{
						a{
							float: left;
							@include mobile-xs{
								padding: 10px 12px 10px 0;
							}
							@include mobile{
								padding: 10px 12px 10px 0;
							}
							@include tablet{
								padding: 6px 12px 6px 30px;
								font-size: 11px;
							}
							@include desktops{
								padding: 10px 12px 10px 30px;
								font-size: 14px;
							}
						}
					}
				}
			}
			&.megaDropMenu{
				position: static;
				.dropdown-menu{
					right: 15px;
					@include mobile-xs{
						left: 0;
						padding: 0;
					}
					@include mobile{
						left: 0;
						padding: 0;
					}
					@include tablet{
						left: 0;
						right: 0;
						
						top: auto;
						
					}
					> li {
						float: left;

					}
					.list-unstyled{
						width: 100%;
						float: left;
						@include mobile-xs{
							padding-bottom: 10px;
						}
						@include mobile{
							padding-bottom: 10px;
						}
						@include tablet{
							padding-bottom: 18px;
							padding-top: 18px;
						}
						li{
							&.listHeading, &:first-child{
								font-weight: 700;
								text-transform: uppercase;
								font-size: 12px;
								color: $secondary-color;
								float: left;
								@include mobile-xs{
									padding: 10px 12px 10px 0;
								}
								@include mobile{
									padding: 10px 12px 10px 0;
								}
								@include tablet{
									padding: 10px 12px 10px 8px;
								}
								@include desktops {
									padding: 10px 12px 10px 20px;
								}
							}
							a{
								padding: 10px 25px 10px 10px;
								@include mobile{
									padding: 10px 25px 10px 10px;
								}
								@include tablet {
									
									padding: 10px 0px 10px 8px;
								}
								@include desktops {
									padding: 10px 20px;
								}
							}
						}
					}
					a.menu-photo{
						img{
							@include mobile-xs{
								width: 100%;
							}
							@include mobile{
								width: 100%;
							}
							@include tablet{
								width: 100%;
							}
							@include large-desktops{
								width: auto;
							}
						}
						padding: 0 0 26px 0 ;
						@include mobile{
							padding: 0 0 26px 0;
						}
						@include tablet{
							padding: 26px 0 0 0;
						}
					}
				}
				> ul {
					> li {
						@include desktops {
							border-right: 1px solid #f0f0f0;
							&:last-child {
								border-right: 0px;
							}
						}
					}
				}
			}
		}
	}
}
.navbar-default .navbar-collapse li.dropdown .dropdown-menu li.active a{
	color: $primary-color;
}
.version1 {
	.version2 {
		.cart-dropdown {
			display: none;
		}
	}
	.navbar-sticky {
		.navbar-collapse {
			margin-right: 50px;
		}
		.version2 {
			.cart-dropdown {
				display: block;
			}
		}
	}
}
.navbar-default{
	.navbar-nav{
		>.show {
			>a{
				background-color: transparent;
				color: $primary-color;
				&:focus, &:hover{
					background-color: transparent;
					color: $primary-color;
				}
			}
		}
	}
}
body.bodyColor{
	background:#DADADA;
}
body.bodyColor.container {
    padding: 0;
}
@include desktops {
	.container {
	    width: 970px!important;
	}
	body.bodyColor .header {
	    max-width: 970px;
	}
	body.bodyColor .navbar-fixed-top {
	    right: auto;
	    left: auto;
	}
}
@include large-desktops {
	.container {
	    width: 1170px!important;
	}
	body.bodyColor .header {
	    max-width: 1170px;
	}
}
