@charset "UTF-8";
/* ====== index =====
1. COMMON
2.HEADER
	2.1 HEADER V1
	2.1 HEADER V2
	2.1 HEADER V3
MAIN SLIDER 
	BANNER
	BANNER V1
	BANNER V2
	BANNER V3
	BANNER V4
3.HOME 
	3.1 HOME
		3.1.1 WHITE SECTION
		3.1.2 LIGHT SECTION
	3.2 HOME V2
		3.2.1 ABOUT
		3.2.2 FEATURE COLLECTION
		3.2.3 FEATURE PRODUCT
		3.2.4 FEATURES
		3.2.5 INSTAGRAM SECTION
	3.3 HOME V3
		3.3.1 DEAL SECTION
		3.3.2 FEATURE PRODUCT SECTION
		3.3.3 FEATURES
		3.3.4 TESTIMONIAL SECTION
		3.3.5 LIGHT SECTION
	3.4 HOME V4
		3.4.1 FEATURE COLLECTION
		3.4.2 CATEGORY SECTION
4.FOOTER
	4.1 FOOTER V1
	4.2 FOOTER V2
5. FORM
	5.1 FORM COMMON
	5.2 FORM SIGN UP
	5.3 FORM LOST PASSWORD
	5.4 FORM LOG IN
	5.5 BLOG SINGLE COMMENTS FORM
	5.6 STEPS PAGE FORM
6. MODAL
	6.1 LOGIN MODAL
	6.2 PORDUCT QUICK VIEW MODAL
7. PAGE HEADER
	7.1 COMMON HEADER
	7.2 ABOUT US HEADER
	7.3 CART PAGE HEADER
8. SIDEBARS
9. PRODUCTS
	7.1 PRODUCTS COMMON
	7.2 SINGLE PRODUCTS
	7.3 CART PRODUCTS
10. GENERIC CONTENT
11. 404 PAGE
12. BLOG
13. ABOUT US
14. STEPS
	14.1 STEP # 01
	14.2 STEP # 02
15. USER PROFILE
	15.1 COMMON PART
	15.2 PROFILE
16. COMMING SOON

===================== */
/*=== MIXINS ===*/
/*=== VARIABLES ===*/
/*============ FUNCTIONS =============*/
/*=== MEDIA QUERY ===*/
@import url("https://fonts.googleapis.com/css?family=Oxygen:400,300,700");
* {
  outline: 0;
  margin: 0;
  padding: 0;
}

html, * {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Oxygen', sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #252525;
  font-weight: 400;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
}

p {
  font-size: 13px;
  color: #252525;
  font-weight: 400;
  line-height: 18px;
}

h1, h2, h3, h4, h5 {
  font-family: 'Oxygen', sans-serif;
  color: #252525;
  margin-top: 0;
  margin-bottom: 0px;
}

h4 {
  font-size: 16px;
  font-weight: 700;
}

h5 {
  font-size: 14px;
  font-weight: 700;
}

/*=== 1.COMMON ===*/
.container {
  width: 100%;
}

@media (min-width: 480px) {
  .container {
    width: 450px;
  }
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.main-wrapper {
  background-color: #ffffff;
}

body.bodyColor.default {
  background-color: #f5f5f5;
}

.default {
  background: #f5f5f5;
}

.pattern-01 {
  background-image: url("../img/patterns/001.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

.pattern-02 {
  background-image: url("../img/patterns/002.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

.pattern-03 {
  background-image: url("../img/patterns/003.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

.pattern-04 {
  background-image: url("../img/patterns/004.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

.pattern-05 {
  background-image: url("../img/patterns/005.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

.pattern-06 {
  background-image: url("../img/patterns/006.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

.pattern-07 {
  background-image: url("../img/patterns/007.png");
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
}

.navbar {
  transition: .4s ease-in-out;
}

body.static .navbar-sticky {
  display: none;
}

/* Boxed Layout */
body.boxed {
  position: relative;
  margin: 0 auto;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
}

body.boxed .navbar-sticky {
  margin: 0 auto;
}

@media (min-width: 480px) {
  body.boxed {
    max-width: 450px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  body.boxed {
    max-width: 700px;
  }
  body.boxed .navbar-sticky {
    max-width: 700px;
  }
}

@media (min-width: 992px) {
  body.boxed {
    max-width: 950px;
  }
  body.boxed .navbar-sticky {
    max-width: 950px;
  }
}

@media (min-width: 1200px) {
  body.boxed {
    max-width: 1250px;
  }
  body.boxed .navbar-sticky {
    max-width: 1250px;
  }
}

body.boxed .main-wrapper {
  background: #ffffff;
}

/* Fixed navber when scroll up */
.up-scroll .navbar-scrollUp.navbar-sticky {
  transform: translateY(-100%);
  transition: .4s ease-in-out;
}

.bg-image {
  background-size: cover !important;
  background-position: 50% 50% !important;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  position: relative;
}

.bg-image:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(27, 45, 84, 0.7);
}

.btn {
  display: inline-block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  padding: 9px 0;
  outline: 0 !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (min-width: 480px) {
  .btn {
    font-size: 15px;
    padding: 13px 0;
  }
}

@media (min-width: 768px) {
  .btn {
    font-size: 12px;
    padding: 8px 0;
  }
}

@media (min-width: 992px) {
  .btn {
    font-size: 14px;
    padding: 12px 0;
  }
}

@media (min-width: 1200px) {
  .btn {
    font-size: 15px;
    padding: 13px 0;
  }
}

.btn-border {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  border: 2px solid #b1b1b1;
  padding: 7px 15px;
  color: #252525;
  font-weight: 700;
}

@media (min-width: 1200px) {
  .btn-border {
    font-size: 15px;
    padding: 9px 15px;
  }
}

.btn-border i {
  padding-left: 20px;
  font-weight: 700;
  font-size: 17px;
}

.main-wrapper {
  position: relative;
  overflow: hidden;
}

a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: rgba(255, 255, 255, 0.25);
  text-decoration: none !important;
}

a:hover, a:focus {
  color: rgba(255, 255, 255, 0.5);
  outline: 0;
}

.media-left, .media > .pull-left {
  float: left;
  padding-right: 10px;
}

.list-inline li {
  display: inline-block;
}

.featuredCollection.version2 button.slick-arrow, .featuredProducts button.slick-arrow, .dealSlider button.slick-arrow, .testimonialSlider button.slick-arrow {
  top: -40px;
}

.featuredCollection.version2 button.slick-arrow.slick-prev:before, .featuredCollection.version2 button.slick-arrow.slick-next:before, .featuredProducts button.slick-arrow.slick-prev:before, .featuredProducts button.slick-arrow.slick-next:before, .dealSlider button.slick-arrow.slick-prev:before, .dealSlider button.slick-arrow.slick-next:before, .testimonialSlider button.slick-arrow.slick-prev:before, .testimonialSlider button.slick-arrow.slick-next:before {
  font-family: FontAwesome;
  font-size: 11px;
  line-height: 1;
  opacity: 1;
  color: #d5d5d5;
}

.featuredCollection.version2 button.slick-arrow.slick-prev, .featuredProducts button.slick-arrow.slick-prev, .dealSlider button.slick-arrow.slick-prev, .testimonialSlider button.slick-arrow.slick-prev {
  left: auto;
  right: 48px;
}

.featuredCollection.version2 button.slick-arrow.slick-prev:before, .featuredProducts button.slick-arrow.slick-prev:before, .dealSlider button.slick-arrow.slick-prev:before, .testimonialSlider button.slick-arrow.slick-prev:before {
  content: '\f053';
}

.featuredCollection.version2 button.slick-arrow.slick-prev:after, .featuredProducts button.slick-arrow.slick-prev:after, .dealSlider button.slick-arrow.slick-prev:after, .testimonialSlider button.slick-arrow.slick-prev:after {
  position: absolute;
  content: '';
  border: 1px solid #c5c5c5;
  height: 20px;
  right: -7px;
  top: 0px;
}

.featuredCollection.version2 button.slick-arrow.slick-prev:hover:before, .featuredProducts button.slick-arrow.slick-prev:hover:before, .dealSlider button.slick-arrow.slick-prev:hover:before, .testimonialSlider button.slick-arrow.slick-prev:hover:before {
  color: #2c2c2c;
}

.featuredCollection.version2 button.slick-arrow.slick-next, .featuredProducts button.slick-arrow.slick-next, .dealSlider button.slick-arrow.slick-next, .testimonialSlider button.slick-arrow.slick-next {
  left: auto;
  right: 15px;
}

.featuredCollection.version2 button.slick-arrow.slick-next:before, .featuredProducts button.slick-arrow.slick-next:before, .dealSlider button.slick-arrow.slick-next:before, .testimonialSlider button.slick-arrow.slick-next:before {
  content: '\f054';
}

.featuredCollection.version2 button.slick-arrow.slick-next:hover:before, .featuredProducts button.slick-arrow.slick-next:hover:before, .dealSlider button.slick-arrow.slick-next:hover:before, .testimonialSlider button.slick-arrow.slick-next:hover:before {
  color: #2c2c2c;
}

.iziToast {
  border: 1px solid #e1e7ec;
  background: #f5f5f5;
}

.iziToast::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0 8px 10px -4px rgba(0, 0, 0, 0.2);
  content: '';
  z-index: -1;
}

.iziToast > .iziToast-body {
  margin-left: 15px;
}

.iziToast > .iziToast-close {
  width: 40px;
  transition: opacity .25s;
  background: none;
  background-position: center;
  background-color: transparent;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDE1LjY0MiAxNS42NDIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1LjY0MiAxNS42NDIiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTguODgyLDcuODIxbDYuNTQxLTYuNTQxYzAuMjkzLTAuMjkzLDAuMjkzLTAuNzY4LDAtMS4wNjEgIGMtMC4yOTMtMC4yOTMtMC43NjgtMC4yOTMtMS4wNjEsMEw3LjgyMSw2Ljc2TDEuMjgsMC4yMmMtMC4yOTMtMC4yOTMtMC43NjgtMC4yOTMtMS4wNjEsMGMtMC4yOTMsMC4yOTMtMC4yOTMsMC43NjgsMCwxLjA2MSAgbDYuNTQxLDYuNTQxTDAuMjIsMTQuMzYyYy0wLjI5MywwLjI5My0wLjI5MywwLjc2OCwwLDEuMDYxYzAuMTQ3LDAuMTQ2LDAuMzM4LDAuMjIsMC41MywwLjIyczAuMzg0LTAuMDczLDAuNTMtMC4yMmw2LjU0MS02LjU0MSAgbDYuNTQxLDYuNTQxYzAuMTQ3LDAuMTQ2LDAuMzM4LDAuMjIsMC41MywwLjIyYzAuMTkyLDAsMC4zODQtMC4wNzMsMC41My0wLjIyYzAuMjkzLTAuMjkzLDAuMjkzLTAuNzY4LDAtMS4wNjFMOC44ODIsNy44MjF6IiBmaWxsPSIjMzc0MjUwIi8+Cjwvc3ZnPgo=);
  background-size: 8px;
  background-repeat: no-repeat;
}

.iziToast.iziToast-info {
  border-color: rgba(80, 198, 233, 0.3);
  background-color: #e6f7fc;
  color: #39bfe6;
}

.iziToast.iziToast-info > .iziToast-close {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDE1LjY0MiAxNS42NDIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1LjY0MiAxNS42NDIiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTguODgyLDcuODIxbDYuNTQxLTYuNTQxYzAuMjkzLTAuMjkzLDAuMjkzLTAuNzY4LDAtMS4wNjEgIGMtMC4yOTMtMC4yOTMtMC43NjgtMC4yOTMtMS4wNjEsMEw3LjgyMSw2Ljc2TDEuMjgsMC4yMmMtMC4yOTMtMC4yOTMtMC43NjgtMC4yOTMtMS4wNjEsMGMtMC4yOTMsMC4yOTMtMC4yOTMsMC43NjgsMCwxLjA2MSAgbDYuNTQxLDYuNTQxTDAuMjIsMTQuMzYyYy0wLjI5MywwLjI5My0wLjI5MywwLjc2OCwwLDEuMDYxYzAuMTQ3LDAuMTQ2LDAuMzM4LDAuMjIsMC41MywwLjIyczAuMzg0LTAuMDczLDAuNTMtMC4yMmw2LjU0MS02LjU0MSAgbDYuNTQxLDYuNTQxYzAuMTQ3LDAuMTQ2LDAuMzM4LDAuMjIsMC41MywwLjIyYzAuMTkyLDAsMC4zODQtMC4wNzMsMC41My0wLjIyYzAuMjkzLTAuMjkzLDAuMjkzLTAuNzY4LDAtMS4wNjFMOC44ODIsNy44MjF6IiBmaWxsPSIjMTg5NmJiIi8+Cjwvc3ZnPgo=);
}

.iziToast.iziToast-success {
  border-color: rgba(67, 217, 163, 0.3);
  background-color: #dcf8ee;
  color: #2bd396;
}

.iziToast.iziToast-success > .iziToast-close {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDE1LjY0MiAxNS42NDIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1LjY0MiAxNS42NDIiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTguODgyLDcuODIxbDYuNTQxLTYuNTQxYzAuMjkzLTAuMjkzLDAuMjkzLTAuNzY4LDAtMS4wNjEgIGMtMC4yOTMtMC4yOTMtMC43NjgtMC4yOTMtMS4wNjEsMEw3LjgyMSw2Ljc2TDEuMjgsMC4yMmMtMC4yOTMtMC4yOTMtMC43NjgtMC4yOTMtMS4wNjEsMGMtMC4yOTMsMC4yOTMtMC4yOTMsMC43NjgsMCwxLjA2MSAgbDYuNTQxLDYuNTQxTDAuMjIsMTQuMzYyYy0wLjI5MywwLjI5My0wLjI5MywwLjc2OCwwLDEuMDYxYzAuMTQ3LDAuMTQ2LDAuMzM4LDAuMjIsMC41MywwLjIyczAuMzg0LTAuMDczLDAuNTMtMC4yMmw2LjU0MS02LjU0MSAgbDYuNTQxLDYuNTQxYzAuMTQ3LDAuMTQ2LDAuMzM4LDAuMjIsMC41MywwLjIyYzAuMTkyLDAsMC4zODQtMC4wNzMsMC41My0wLjIyYzAuMjkzLTAuMjkzLDAuMjkzLTAuNzY4LDAtMS4wNjFMOC44ODIsNy44MjF6IiBmaWxsPSIjMWY5NzZjIi8+Cjwvc3ZnPgo=);
}

.iziToast.iziToast-warning {
  border-color: rgba(255, 183, 79, 0.3);
  background-color: #fff6e8;
  color: #ffb140;
}

.iziToast.iziToast-warning > .iziToast-close {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDE1LjY0MiAxNS42NDIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1LjY0MiAxNS42NDIiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTguODgyLDcuODIxbDYuNTQxLTYuNTQxYzAuMjkzLTAuMjkzLDAuMjkzLTAuNzY4LDAtMS4wNjEgIGMtMC4yOTMtMC4yOTMtMC43NjgtMC4yOTMtMS4wNjEsMEw3LjgyMSw2Ljc2TDEuMjgsMC4yMmMtMC4yOTMtMC4yOTMtMC43NjgtMC4yOTMtMS4wNjEsMGMtMC4yOTMsMC4yOTMtMC4yOTMsMC43NjgsMCwxLjA2MSAgbDYuNTQxLDYuNTQxTDAuMjIsMTQuMzYyYy0wLjI5MywwLjI5My0wLjI5MywwLjc2OCwwLDEuMDYxYzAuMTQ3LDAuMTQ2LDAuMzM4LDAuMjIsMC41MywwLjIyczAuMzg0LTAuMDczLDAuNTMtMC4yMmw2LjU0MS02LjU0MSAgbDYuNTQxLDYuNTQxYzAuMTQ3LDAuMTQ2LDAuMzM4LDAuMjIsMC41MywwLjIyYzAuMTkyLDAsMC4zODQtMC4wNzMsMC41My0wLjIyYzAuMjkzLTAuMjkzLDAuMjkzLTAuNzY4LDAtMS4wNjFMOC44ODIsNy44MjF6IiBmaWxsPSIjZTg4OTAwIi8+Cjwvc3ZnPgo=);
}

.iziToast.iziToast-danger {
  border-color: rgba(255, 82, 82, 0.3);
  background-color: #ffebeb;
  color: #ff5252;
}

.iziToast.iziToast-danger > .iziToast-close {
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDE1LjY0MiAxNS42NDIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE1LjY0MiAxNS42NDIiIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTguODgyLDcuODIxbDYuNTQxLTYuNTQxYzAuMjkzLTAuMjkzLDAuMjkzLTAuNzY4LDAtMS4wNjEgIGMtMC4yOTMtMC4yOTMtMC43NjgtMC4yOTMtMS4wNjEsMEw3LjgyMSw2Ljc2TDEuMjgsMC4yMmMtMC4yOTMtMC4yOTMtMC43NjgtMC4yOTMtMS4wNjEsMGMtMC4yOTMsMC4yOTMtMC4yOTMsMC43NjgsMCwxLjA2MSAgbDYuNTQxLDYuNTQxTDAuMjIsMTQuMzYyYy0wLjI5MywwLjI5My0wLjI5MywwLjc2OCwwLDEuMDYxYzAuMTQ3LDAuMTQ2LDAuMzM4LDAuMjIsMC41MywwLjIyczAuMzg0LTAuMDczLDAuNTMtMC4yMmw2LjU0MS02LjU0MSAgbDYuNTQxLDYuNTQxYzAuMTQ3LDAuMTQ2LDAuMzM4LDAuMjIsMC41MywwLjIyYzAuMTkyLDAsMC4zODQtMC4wNzMsMC41My0wLjIyYzAuMjkzLTAuMjkzLDAuMjkzLTAuNzY4LDAtMS4wNjFMOC44ODIsNy44MjF6IiBmaWxsPSIjZWIwMDAwIi8+Cjwvc3ZnPgo=);
}

/*==============================================
--> 5 PRELOADER 
================================================*/
body {
  overflow: hidden;
}

body.loaded {
  overflow: auto;
}

#preloader {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
}

#preloader .preloader_container {
  z-index: 7000;
  line-height: 0;
  width: 80px;
  height: 100px;
  margin: 100px auto;
  margin-top: calc(100vh / 2 - 50px);
}

.block {
  position: relative;
  box-sizing: border-box;
  float: left;
  margin: 0 10px 10px 0;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background: #47bac1;
}

.block:nth-child(4n+1) {
  animation: wave 2s ease .0s infinite;
}

.block:nth-child(4n+2) {
  animation: wave 2s ease .2s infinite;
}

.block:nth-child(4n+3) {
  animation: wave 2s ease .4s infinite;
}

.block:nth-child(4n+4) {
  animation: wave 2s ease .6s infinite;
  margin-right: 0;
}

@keyframes wave {
  0% {
    top: 0;
    opacity: 1;
  }
  50% {
    top: 30px;
    opacity: .2;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.loaded #preloader {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
}

/*=== 2.1 HEADER V1 ===*/
.header {
  background-color: #ffffff;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 999;
}

.navbar-sticky {
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
}

/*=== 2.1.1 TOPBAR ===*/
.topBar {
  width: 100%;
  display: block;
  min-height: 35px;
  height: auto;
  background-color: #252525;
}

.topBar ul li {
  padding-right: 5px;
  padding-left: 5px;
}

.topBar ul li a {
  padding: 0 3px;
  display: block;
  line-height: 35px;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #797979;
}

.topBar ul li a:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=0.8);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
}

.topBar ul.float-right li {
  padding: 0;
  float: left;
  position: relative;
}

.topBar ul.float-right li a {
  font-size: 12px;
  display: inline-block;
}

.topBar ul.float-right > li {
  margin: 0 0 0 10px;
}

.topBar ul.float-right > li.cart-dropdown {
  margin-left: 3px;
}

.topBar ul.float-right > li:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 6px;
  height: 24px;
  width: 1px;
  background: rgba(255, 255, 255, 0.2);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.top-right .account-login span small {
  color: rgba(255, 255, 255, 0.5);
  padding: 0 10px;
}

.top-right .account-login span a {
  color: #d9d9d9;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.top-right .account-login span a:hover {
  color: rgba(255, 255, 255, 0.5);
}

.top-right .searchBox {
  position: relative;
}

.top-right .searchBox a {
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 0 10px;
}

.top-right .searchBox a:hover {
  opacity: .5;
}

.top-right .searchBox a i {
  font-size: 16px;
  width: 20px;
}

.top-right .searchBox .dropdown-menu {
  padding: 10px 15px;
  border-radius: 0;
  right: 0;
  left: auto;
}

@media (max-width: 479px) {
  .top-right .searchBox .dropdown-menu {
    width: 280px;
    margin: 0 -65px 0 0;
  }
}

@media (min-width: 480px) {
  .top-right .searchBox .dropdown-menu {
    width: 350px;
    margin: 0;
  }
}

.top-right .searchBox .dropdown-menu li {
  line-height: 0;
  width: 100%;
}

.searchBox .input-group {
  width: 100%;
  position: relative;
}

.searchBox .input-group .form-control {
  box-shadow: none;
  border-radius: 0;
  height: 34px;
  line-height: 34px;
}

.searchBox .input-group .form-control:focus {
  border-color: #47bac1;
}

.searchBox .input-group .input-group-addon {
  border-radius: 0;
  background-color: #47bac1;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  border-color: #47bac1;
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  height: 34px;
}

.searchBox .input-group .input-group-addon:focus {
  outline: none;
}

.top-right .cart-dropdown a,
.cart-dropdown a {
  background-color: #47bac1;
  line-height: 35px;
  min-height: 36px;
  font-size: 12px;
  color: #ffffff;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 479px) {
  .top-right .cart-dropdown a,
  .cart-dropdown a {
    padding: 0 7px;
  }
}

@media (min-width: 480px) {
  .top-right .cart-dropdown a,
  .cart-dropdown a {
    padding: 0 10px;
  }
}

@media (min-width: 768px) {
  .top-right .cart-dropdown a,
  .cart-dropdown a {
    font-size: 13px;
    padding: 0 19px;
  }
}

.top-right .cart-dropdown a i,
.cart-dropdown a i {
  font-size: 18px;
  margin-right: 10px;
}

.top-right .cart-dropdown a:hover,
.cart-dropdown a:hover {
  background-color: #47bac1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.top-right .cart-dropdown a:focus,
.cart-dropdown a:focus {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=1);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

.top-right .cart-dropdown a:after,
.cart-dropdown a:after {
  border: none;
  display: none;
}

.top-right .cart-dropdown ul.dropdown-menu,
.cart-dropdown ul.dropdown-menu {
  background-color: #47bac1;
  border-radius: 0;
  margin: 0;
  box-shadow: none;
  border: none;
  z-index: 9999;
}

@media (max-width: 479px) {
  .top-right .cart-dropdown ul.dropdown-menu,
  .cart-dropdown ul.dropdown-menu {
    width: 280px;
  }
}

@media (min-width: 480px) {
  .top-right .cart-dropdown ul.dropdown-menu,
  .cart-dropdown ul.dropdown-menu {
    width: 300px;
  }
}

@media (min-width: 768px) {
  .top-right .cart-dropdown ul.dropdown-menu,
  .cart-dropdown ul.dropdown-menu {
    width: auto;
  }
}

.top-right .cart-dropdown ul.dropdown-menu li:first-child,
.cart-dropdown ul.dropdown-menu li:first-child {
  color: #ffffff;
  padding: 10px 0 8px 19px;
}

.top-right .cart-dropdown ul.dropdown-menu li,
.cart-dropdown ul.dropdown-menu li {
  width: 100%;
}

.top-right .cart-dropdown ul.dropdown-menu li a,
.cart-dropdown ul.dropdown-menu li a {
  line-height: 26px;
  display: block;
}

.top-right .cart-dropdown ul.dropdown-menu li a .media,
.cart-dropdown ul.dropdown-menu li a .media {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 12px 0;
}

.top-right .cart-dropdown ul.dropdown-menu li a .media .media-body h5,
.cart-dropdown ul.dropdown-menu li a .media .media-body h5 {
  color: #ffffff;
  line-height: 20px;
  letter-spacing: 1px;
}

.top-right .cart-dropdown ul.dropdown-menu li a .media .media-body h5 span,
.cart-dropdown ul.dropdown-menu li a .media .media-body h5 span {
  font-size: 18px;
}

.top-right .cart-dropdown ul.dropdown-menu li a:hover,
.cart-dropdown ul.dropdown-menu li a:hover {
  opacity: .8;
  background-color: transparent;
}

.top-right .cart-dropdown ul.dropdown-menu li .btn-group,
.cart-dropdown ul.dropdown-menu li .btn-group {
  padding: 14px 19px;
}

.top-right .cart-dropdown ul.dropdown-menu li .btn-group .btn,
.cart-dropdown ul.dropdown-menu li .btn-group .btn {
  border: none;
  height: 36px;
  padding: 0;
  line-height: 36px;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  border-radius: 4px !important;
  color: #252525;
  background-color: #f8f8f8;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.top-right .cart-dropdown ul.dropdown-menu li .btn-group button:first-child:hover,
.cart-dropdown ul.dropdown-menu li .btn-group button:first-child:hover {
  opacity: .8;
}

@media (max-width: 479px) {
  .top-right .cart-dropdown ul.dropdown-menu li .btn-group button:first-child,
  .cart-dropdown ul.dropdown-menu li .btn-group button:first-child {
    width: 135px;
  }
}

@media (min-width: 480px) {
  .top-right .cart-dropdown ul.dropdown-menu li .btn-group button:first-child,
  .cart-dropdown ul.dropdown-menu li .btn-group button:first-child {
    width: 149px;
  }
}

.top-right .cart-dropdown ul.dropdown-menu li .btn-group button:last-child,
.cart-dropdown ul.dropdown-menu li .btn-group button:last-child {
  margin-left: 8px;
}

@media (max-width: 479px) {
  .top-right .cart-dropdown ul.dropdown-menu li .btn-group button:last-child,
  .cart-dropdown ul.dropdown-menu li .btn-group button:last-child {
    width: 100px;
  }
}

@media (min-width: 480px) {
  .top-right .cart-dropdown ul.dropdown-menu li .btn-group button:last-child,
  .cart-dropdown ul.dropdown-menu li .btn-group button:last-child {
    width: 109px;
  }
}

.top-right .cart-dropdown ul.dropdown-menu li .btn-group button:last-child:hover,
.cart-dropdown ul.dropdown-menu li .btn-group button:last-child:hover {
  opacity: .8;
  color: #777777;
}

/*=== 2.1.2 NAVBAR ===*/
.navbar-brand {
  width: auto;
  height: auto;
  -webkit-transition: height 0.3s, width 0.3s;
  -moz-transition: height 0.3s, width 0.3s;
  -ms-transition: height 0.3s, width 0.3s;
  -o-transition: height 0.3s, width 0.3s;
  transition: height 0.3s, width 0.3s;
}

.navbar-brand img {
  height: 40px;
}

.navbar-default {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin-bottom: 0;
  padding: 10px 0;
  border: none;
}

@media (min-width: 768px) {
  .navbar-default {
    padding: 0;
  }
}

.navbar-default .container {
  position: relative;
}

.navbar-default .navbar-toggler {
  background-color: #47bac1;
  border-radius: 0;
  border: none;
  padding: 5px 8px;
  margin-right: 15px;
  margin-top: 0px;
  color: #ffffff;
  font-size: 22px;
  position: absolute;
  right: 0;
  z-index: 555;
  top: 12px;
}

.navbar-default .navbar-toggler:focus {
  border: none;
  outline: none;
}

.navbar-default .navbar-brand {
  display: inline-block;
  padding: 15px 15px;
}

@media (min-width: 480px) {
  .navbar-default .navbar-brand {
    padding: 15px;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-brand {
    position: absolute;
    z-index: 5;
    padding: 0;
  }
}

.navbar-default .navbar-collapse.show {
  overflow-y: auto;
  right: 0;
}

.navbar-default .navbar-collapse {
  width: 100%;
  top: 100%;
  background: #ffffff;
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse {
    position: relative;
    margin-top: 0px;
    box-shadow: none;
  }
}

@media (max-width: 479px) {
  .navbar-default .navbar-collapse {
    max-height: 250px;
  }
}

@media (min-width: 480px) {
  .navbar-default .navbar-collapse {
    max-height: 250px;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse {
    height: auto;
    padding: 0 0;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse .navbar-nav.ml-auto {
    margin: 0px 0;
    margin-right: -15px;
  }
}

.navbar-default .navbar-collapse li a.nav-link {
  font-size: 14px;
  color: #252525;
  text-transform: uppercase;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 10px 15px;
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse li a.nav-link {
    padding: 26px  8px;
    font-size: 12px;
    line-height: 1;
  }
}

@media (min-width: 992px) {
  .navbar-default .navbar-collapse li a.nav-link {
    letter-spacing: 1px;
    padding: 37px  15px;
    font-size: 14px;
  }
}

.navbar-default .navbar-collapse li a.nav-link:hover, .navbar-default .navbar-collapse li a.nav-link.focus, .navbar-default .navbar-collapse li a.nav-link:focus {
  color: #47bac1;
}

.navbar-default .navbar-collapse li a.nav-link.dropdown-toggle:after {
  border: none;
}

.navbar-default .navbar-collapse li.dropdown .dropdown-menu {
  box-shadow: none;
  margin-top: 0;
  border-radius: 0px;
}

.navbar-default .navbar-collapse li.dropdown .dropdown-menu li a {
  font-weight: 300;
  padding: 10px 25px;
  text-transform: capitalize;
  width: 100%;
  font-size: 14px;
  color: #252525;
  float: left;
}

.navbar-default .navbar-collapse li.dropdown .dropdown-menu li a:hover {
  color: #47bac1;
}

@media (max-width: 479px) {
  .navbar-default .navbar-collapse li.dropdown .dropdown-menu {
    position: relative;
    width: 100%;
    float: left;
    border: 0;
    display: block;
  }
}

@media (min-width: 480px) {
  .navbar-default .navbar-collapse li.dropdown .dropdown-menu {
    position: relative;
    width: 100%;
    float: left;
    border: 0;
    display: block;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse li.dropdown .dropdown-menu {
    position: absolute;
    float: left;
    border: 1px solid #ececec;
    display: none;
  }
}

.navbar-default .navbar-collapse li.active a {
  background-color: transparent;
  color: #47bac1;
}

.navbar-default .navbar-collapse li.active a:focus, .navbar-default .navbar-collapse li.active a:hover {
  background-color: transparent;
  color: #47bac1;
}

.navbar-default .navbar-collapse li.active.dropdown .dropdown-menu li a {
  color: #252525;
}

.navbar-default .navbar-collapse li.active.dropdown .dropdown-menu li a:hover {
  color: #47bac1;
}

.navbar-default .navbar-collapse li.dropdown.show a:hover, .navbar-default .navbar-collapse li.dropdown.show a.focus, .navbar-default .navbar-collapse li.dropdown.show a:focus {
  background-color: transparent;
  color: #47bac1;
}

.navbar-default .navbar-collapse li.dropdown.show .dropdown-menu {
  box-shadow: none;
  border-radius: 0;
  margin-top: 0px;
  min-width: 200px;
  display: block;
  border-radius: 0px;
}

.navbar-default .navbar-collapse li.dropdown.show .dropdown-menu li a {
  padding: 10px 25px;
  font-weight: 300;
  text-transform: capitalize;
  width: 100%;
}

.navbar-default .navbar-collapse li.dropdown.show .dropdown-menu li a {
  float: left;
}

@media (max-width: 479px) {
  .navbar-default .navbar-collapse li.dropdown.show .dropdown-menu li a {
    padding: 10px 12px 10px 0;
  }
}

@media (min-width: 480px) {
  .navbar-default .navbar-collapse li.dropdown.show .dropdown-menu li a {
    padding: 10px 12px 10px 0;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse li.dropdown.show .dropdown-menu li a {
    padding: 6px 12px 6px 30px;
    font-size: 11px;
  }
}

@media (min-width: 992px) {
  .navbar-default .navbar-collapse li.dropdown.show .dropdown-menu li a {
    padding: 10px 12px 10px 30px;
    font-size: 14px;
  }
}

.navbar-default .navbar-collapse li.dropdown.megaDropMenu {
  position: static;
}

.navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu {
  right: 15px;
}

@media (max-width: 479px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu {
    left: 0;
    padding: 0;
  }
}

@media (min-width: 480px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu {
    left: 0;
    padding: 0;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu {
    left: 0;
    right: 0;
    top: auto;
  }
}

.navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu > li {
  float: left;
}

.navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled {
  width: 100%;
  float: left;
}

@media (max-width: 479px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled {
    padding-bottom: 10px;
  }
}

@media (min-width: 480px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled {
    padding-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled {
    padding-bottom: 18px;
    padding-top: 18px;
  }
}

.navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  color: #252525;
  float: left;
}

@media (max-width: 479px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
    padding: 10px 12px 10px 0;
  }
}

@media (min-width: 480px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
    padding: 10px 12px 10px 0;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
    padding: 10px 12px 10px 8px;
  }
}

@media (min-width: 992px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li.listHeading, .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li:first-child {
    padding: 10px 12px 10px 20px;
  }
}

.navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a {
  padding: 10px 25px 10px 10px;
}

@media (min-width: 480px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a {
    padding: 10px 25px 10px 10px;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a {
    padding: 10px 0px 10px 8px;
  }
}

@media (min-width: 992px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu .list-unstyled li a {
    padding: 10px 20px;
  }
}

.navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu a.menu-photo {
  padding: 0 0 26px 0;
}

@media (max-width: 479px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu a.menu-photo img {
    width: 100%;
  }
}

@media (min-width: 480px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu a.menu-photo img {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu a.menu-photo img {
    width: 100%;
  }
}

@media (min-width: 1200px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu a.menu-photo img {
    width: auto;
  }
}

@media (min-width: 480px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu a.menu-photo {
    padding: 0 0 26px 0;
  }
}

@media (min-width: 768px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu .dropdown-menu a.menu-photo {
    padding: 26px 0 0 0;
  }
}

@media (min-width: 992px) {
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu > ul > li {
    border-right: 1px solid #f0f0f0;
  }
  .navbar-default .navbar-collapse li.dropdown.megaDropMenu > ul > li:last-child {
    border-right: 0px;
  }
}

.navbar-default .navbar-collapse li.dropdown .dropdown-menu li.active a {
  color: #47bac1;
}

.version1 .version2 .cart-dropdown {
  display: none;
}

.version1 .navbar-sticky .navbar-collapse {
  margin-right: 50px;
}

.version1 .navbar-sticky .version2 .cart-dropdown {
  display: block;
}

.navbar-default .navbar-nav > .show > a {
  background-color: transparent;
  color: #47bac1;
}

.navbar-default .navbar-nav > .show > a:focus, .navbar-default .navbar-nav > .show > a:hover {
  background-color: transparent;
  color: #47bac1;
}

body.bodyColor {
  background: #DADADA;
}

body.bodyColor.container {
  padding: 0;
}

@media (min-width: 992px) {
  .container {
    width: 970px !important;
  }
  body.bodyColor .header {
    max-width: 970px;
  }
  body.bodyColor .navbar-fixed-top {
    right: auto;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px !important;
  }
  body.bodyColor .header {
    max-width: 1170px;
  }
}

/*=== 2.2 HEADER V2 ===*/
.version2 .topBar ul.float-right li:nth-child(2)::before, .version3 .topBar ul.float-right li:nth-child(2)::before {
  left: -8px;
}

@media (max-width: 479px) {
  .version2 .top-right .searchBox .dropdown-menu, .version3 .top-right .searchBox .dropdown-menu {
    margin: 0 -110px 0 0;
  }
}

.headerV2 .topBar ul.pull-right li:last-child {
  margin-left: 0;
}

.version2 .cart-dropdown, .version3 .cart-dropdown {
  position: absolute;
  right: 85px;
  top: 21px;
}

@media (min-width: 768px) {
  .version2 .cart-dropdown, .version3 .cart-dropdown {
    right: 25px;
  }
}

@media (min-width: 992px) {
  .version2 .cart-dropdown, .version3 .cart-dropdown {
    top: 36px;
  }
}

.version2 .cart-dropdown .shop-cart, .version3 .cart-dropdown .shop-cart {
  color: #252525;
  background-color: transparent;
  width: 26px;
  height: 36px;
  line-height: 26px;
  display: block;
  text-align: center;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .version2 .cart-dropdown .shop-cart, .version3 .cart-dropdown .shop-cart {
    height: 54px;
  }
}

.version2 .cart-dropdown .shop-cart i, .version3 .cart-dropdown .shop-cart i {
  color: #252525;
}

.version2 .cart-dropdown .dropdown-menu-right, .version3 .cart-dropdown .dropdown-menu-right {
  right: -10px;
  left: auto;
  top: 97%;
}

.version2 .navbar-default.navbar-fixed-top .cart-dropdown, .version3 .navbar-default.navbar-fixed-top .cart-dropdown {
  top: 19px;
}

.version2 .navbar-default.navbar-fixed-top .cart-dropdown .shop-cart, .version3 .navbar-default.navbar-fixed-top .cart-dropdown .shop-cart {
  height: 38px;
}

@media (min-width: 768px) {
  .version2 .navbar-default.navbar-fixed-top .cart-dropdown .shop-cart, .version3 .navbar-default.navbar-fixed-top .cart-dropdown .shop-cart {
    height: 42px;
  }
}

.cart-dropdown .shop-cart .badge {
  background-color: #47bac1;
  position: absolute;
  top: -5px;
  right: -15px;
}

@media (min-width: 768px) {
  .nav-V2.navbar-default .navbar-collapse {
    margin-right: 50px;
  }
}

/*=== 2.3 HEADER V3 ===*/
.navTop {
  padding: 10px 0;
}

@media (min-width: 992px) {
  .navTop {
    padding: 30px 0;
  }
}

.navTop .navbar-brand {
  display: inline-block;
  float: left;
}

.navTop .navbar-brand {
  display: inline-block;
  padding: 5px 0 0 0px;
  width: 150px;
  height: auto;
  margin-right: 10px;
}

@media (max-width: 479px) {
  .navTop .navbar-brand {
    width: 110px;
  }
}

@media (min-width: 992px) {
  .navTop .navbar-brand {
    padding: 5px 0 0;
    width: auto;
    height: auto;
  }
}

.navTop .navbar-brand svg {
  width: 170px;
  height: 50px;
}

.navTop-middle {
  width: 40%;
  display: inline-block;
}

@media (max-width: 479px) {
  .navTop-middle {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .navTop-middle {
    width: 35%;
  }
}

@media (min-width: 992px) {
  .navTop-middle {
    width: 50%;
  }
}

.navTop-middle .filterArea {
  width: 30%;
  margin-bottom: 0;
  margin-right: 8px;
}

.navTop-middle .filterArea .sbToggle {
  background: none;
  right: 10px;
  top: 14px;
}

.navTop-middle .filterArea .sbToggle:after {
  content: "\f107";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #979797;
}

.navTop-middle .filterArea .sbSelector:link,
.navTop-middle .filterArea .sbSelector:visited,
.navTop-middle .filterArea .sbSelector:hover {
  color: #979797 !important;
}

.navTop-middle .filterArea .sbSelector {
  font-size: 13px;
}

.navTop-middle .filterArea .sbOptions a {
  color: #979797 !important;
  font-size: 13px;
}

.navTop-middle .filterArea .sbHolder, .navTop-middle .filterArea .sbSelector {
  width: 100% !important;
  padding-top: 5px;
  height: 45px !important;
}

.navTop-middle .filterArea .sbOptions {
  width: 101% !important;
  margin-top: 3px;
}

.navTop-middle .searchBox {
  display: table;
  width: 100%;
}

@media (min-width: 992px) {
  .navTop-middle .searchBox {
    display: table;
    width: 68%;
  }
}

.navTop-middle .searchBox .input-group .form-control {
  font-size: 13px;
  border-color: #ccc;
  background: transparent;
}

@media (min-width: 992px) {
  .navTop-middle .searchBox .input-group .form-control {
    border-left-color: transparent;
  }
}

@media (min-width: 992px) {
  .navTop-middle .searchBox .input-group .form-control:focus {
    border-color: #ccc;
    border-left-color: transparent;
  }
}

.navTop-middle .searchBox .input-group .form-control,
.navTop-middle .searchBox .input-group-addon,
.navTop-middle .searchBox .input-group-btn {
  height: 35px !important;
  opacity: 0;
  visibility: hidden;
  height: 35px !important;
}

@media (min-width: 768px) {
  .navTop-middle .searchBox .input-group .form-control,
  .navTop-middle .searchBox .input-group-addon,
  .navTop-middle .searchBox .input-group-btn {
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .navTop-middle .searchBox .input-group .form-control,
  .navTop-middle .searchBox .input-group-addon,
  .navTop-middle .searchBox .input-group-btn {
    height: 45px !important;
  }
}

.navTop-middle .searchBox .input-group .form-control.visibleIt,
.navTop-middle .searchBox .input-group-addon.visibleIt,
.navTop-middle .searchBox .input-group-btn.visibleIt {
  opacity: 1 !important;
  visibility: visible !important;
  background: #ffffff !important;
}

.navTop-middle .searchBox .input-group .input-group-addon {
  width: auto;
  font-size: 16px;
  opacity: 1;
  visibility: visible;
  padding: 0 10px;
}

@media (min-width: 992px) {
  .navTop-middle .searchBox .input-group .input-group-addon {
    font-size: 20px;
    padding: 0 20px;
  }
}

.navTop .cart-dropdown {
  display: inline-block;
  float: right;
}

.navTop .cart-dropdown > a {
  background-color: transparent;
  line-height: 25px;
  height: 35px;
  font-size: 13px;
  color: #252525;
  display: inline-block;
  padding: 0;
}

@media (min-width: 768px) {
  .navTop .cart-dropdown > a {
    display: table;
    position: relative;
    width: 140px;
  }
}

@media (min-width: 992px) {
  .navTop .cart-dropdown > a {
    width: 179px;
    line-height: 35px;
    height: 45px !important;
  }
}

.navTop .cart-dropdown > a:hover {
  background-color: transparent;
}

.navTop .cart-dropdown > a i {
  font-size: 20px;
  margin-right: 0px;
  padding: 0 10px;
  background-color: #47bac1;
  color: #ffffff;
  line-height: 35px;
  float: left;
}

@media (min-width: 768px) {
  .navTop .cart-dropdown > a i {
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .navTop .cart-dropdown > a i {
    line-height: 45px;
    font-size: 20px;
    padding: 0 20px;
  }
}

.navTop .cart-dropdown > a > span {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-left: none;
  padding: 3px 5px;
  height: 35px;
  line-height: 12px;
  text-align: left;
  display: table;
}

@media (min-width: 992px) {
  .navTop .cart-dropdown > a > span {
    padding: 3px 15px;
    height: 45px;
    line-height: 18px;
  }
}

.navTop .cart-dropdown > a > span span {
  display: inline-block;
}

.navTop .cart-dropdown > a .cart-total {
  font-size: 11px;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .navTop .cart-dropdown > a .cart-total {
    font-size: 13px;
  }
}

.navTop .cart-dropdown > a .cart-price {
  font-size: 10px;
}

@media (min-width: 992px) {
  .navTop .cart-dropdown > a .cart-price {
    font-size: 12px;
  }
}

.nav-V3.navbar-default {
  background-color: #f5f5f5;
  width: 100%;
}

@media (min-width: 768px) {
  .nav-V3.navbar-default {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    height: 50px;
  }
}

.nav-V3.navbar-default .navbar-toggler {
  top: 6px;
}

.nav-V3.navbar-default .navbar-collapse {
  position: unset;
}

@media (min-width: 768px) {
  .nav-V3.navbar-default .navbar-collapse {
    float: none;
    padding: 0;
    background: transparent;
  }
}

@media (min-width: 768px) {
  .nav-V3.navbar-default .navbar-collapse > ul > li > a {
    padding: 0 10px;
  }
}

@media (min-width: 992px) {
  .nav-V3.navbar-default .navbar-collapse > ul > li > a {
    padding: 0 15px;
    line-height: 50px;
  }
}

.nav-category {
  width: 50%;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  padding: 0 15px;
  margin-left: 15px;
  background-color: #47bac1;
}

@media (min-width: 768px) {
  .nav-category {
    width: 310px;
    margin-left: 0px;
  }
}

.nav-category > a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.nav-category > a:after {
  border: 0px;
}

.nav-category button {
  width: 15px;
  height: 14px;
  display: inline-block;
  float: right;
  padding: 17px 0 14px;
  border: none;
  background: transparent;
}

.nav-category button .icon-bar {
  width: 15px;
  height: 2px;
  margin-bottom: 3px;
  background-color: #ffffff;
  display: block;
}

.nav-category button:focus {
  outline: none;
}

@media (min-width: 1200px) {
  .nav-category > .dropdown-menu {
    display: block;
    height: 500px;
  }
}

.nav-category > .dropdown-menu > li:last-child {
  border-bottom: none;
}

.nav-category > .dropdown-menu > li:last-child > a {
  padding: 13px 20px;
}

.nav-category > .dropdown-menu > li:last-child > a:after {
  content: "";
}

.nav-category .dropdown-menu {
  top: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0;
  box-shadow: none;
}

.nav-category .dropdown-menu .dropdown-submenu > a:after {
  font-size: 18px;
  top: 15px;
  position: absolute;
  right: 20px;
  content: "\f105";
  display: inline-block;
  border: none;
  font: normal normal normal 18px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-category .dropdown-menu .dropdown-submenu.show .dropdown-menu {
  display: block;
  margin-left: -1px;
}

.nav-category .dropdown-menu li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.nav-category .dropdown-menu li a {
  display: block;
  padding: 0px 0;
  font-size: 12px;
  color: #252525;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  padding-left: 50px;
}

.nav-category .dropdown-menu li a:hover {
  background-color: transparent;
  color: #47bac1;
}

.nav-category .dropdown-menu li a:hover i {
  color: #47bac1;
}

.nav-category .dropdown-menu li a i {
  position: absolute;
  left: 18px;
  top: 14px;
  font-size: 18px;
  color: #979797;
}

.nav-category .dropdown-menu li a img {
  max-width: 100%;
  display: inline-block;
}

.nav-category .dropdown-menu li.active a {
  background-color: transparent;
  color: #47bac1;
}

.nav-category .dropdown-menu li:last-child {
  border-bottom: none;
}

.nav-category .dropdown-menu .dropdown-menu {
  left: 100%;
  top: 0;
}

@media (min-width: 1200px) {
  .navbar-sticky .nav-category > .dropdown-menu {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-sticky .nav-category:hover > .dropdown-menu {
    display: block;
  }
}

.navbar-transparent {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33000000', endColorstr='#00000000',GradientType=0 );
  position: absolute;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: none;
}

@media (min-width: 768px) {
  .navbar-transparent .navbar-collapse {
    background: transparent;
    border: none;
  }
  .navbar-transparent .navbar-collapse .navbar-nav.ml-auto {
    margin-right: 0px;
  }
}

.navbar-transparent .logo-pm {
  fill: #ffffff;
}

.navbar-transparent .navbar-nav .nav-item a.nav-link {
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .navbar-transparent .navbar-nav .nav-item a.nav-link {
    padding: 26px 8px;
    color: #ffffff;
    text-transform: capitalize;
  }
  .navbar-transparent .navbar-nav .nav-item.active a.nav-link {
    color: #47bac1;
  }
}

@media (min-width: 992px) {
  .navbar-transparent .navbar-nav .nav-item a.nav-link {
    padding: 37px 15px;
    text-transform: uppercase;
  }
}

.navbar-transparent .navbar-nav .nav-item a.nav-link.primary-btn {
  padding: 0 !important;
  height: 40px;
  width: 90px;
  border: 2px solid #47bac1;
  line-height: 38px;
  margin-top: 24px;
  display: inline-block;
  text-align: center;
}

@media (min-width: 768px) {
  .navbar-transparent .navbar-nav .nav-item a.nav-link.primary-btn {
    margin-top: 12px;
    width: 85px;
  }
}

@media (min-width: 992px) {
  .navbar-transparent .navbar-nav .nav-item a.nav-link.primary-btn {
    margin-top: 24px;
    width: 110px;
  }
}

.navbar-transparent .navbar-nav .nav-item:last-child a.nav-link {
  padding-right: 0px;
}

.navbar-transparent.navbar-sticky {
  background: #ffffff;
  position: fixed;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom: none;
}

.navbar-transparent.navbar-sticky .logo-pm {
  fill: #333;
}

.navbar-transparent.navbar-sticky .navbar-nav .nav-item a.nav-link {
  color: #252525;
}

.navbar-transparent.navbar-sticky .navbar-nav .nav-item.active a.nav-link {
  color: #47bac1;
}

/*===  BANNER ===*/
.fullscreenbanner-container {
  width: 100%;
  position: relative;
  padding: 0;
}

.fullscreenbanner-container .tp-bullets.preview4 {
  position: relative;
  left: 0 !important;
  bottom: 0 !important;
  padding: 9px 50px;
  width: 150px;
  height: 30px;
  margin: -15px auto 0px auto !important;
  display: none;
  background: #4dc8cf;
  background: -webkit-linear-gradient(legacy-direction(to bottom), #4dc8cf 0%, #4dc8cf 50%, #47bac1 51%, #47bac1 100%);
  background: linear-gradient(to bottom, #4dc8cf 0%, #4dc8cf 50%, #47bac1 51%, #47bac1 100%);
  text-align: center;
}

@media (min-width: 768px) {
  .fullscreenbanner-container .tp-bullets.preview4 {
    display: block;
  }
}

.fullscreenbanner-container .tp-bullets.preview4::before {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  left: -10px;
  top: 4px;
  z-index: -1;
  background: #4dc8cf;
  background: -webkit-linear-gradient(legacy-direction(135deg), #4dc8cf 0%, #4dc8cf 50%, #47bac1 51%, #47bac1 100%);
  background: linear-gradient(135deg, #4dc8cf 0%, #4dc8cf 50%, #47bac1 51%, #47bac1 100%);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fullscreenbanner-container .tp-bullets.preview4::after {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  right: -10px;
  top: 4px;
  z-index: -1;
  background: #4dc8cf;
  background: -webkit-linear-gradient(legacy-direction(135deg), #4dc8cf 0%, #4dc8cf 50%, #47bac1 51%, #47bac1 100%);
  background: linear-gradient(135deg, #4dc8cf 0%, #4dc8cf 50%, #47bac1 51%, #47bac1 100%);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fullscreenbanner-container .tp-bullets.preview4.hidebullets {
  opacity: 1;
}

.fullscreenbanner-container .tp-bullets.preview4 .bullet {
  background-color: rgba(255, 255, 255, 0.5) !important;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  box-shadow: none !important;
  width: 9px !important;
  height: 9px !important;
  border: 0 !important;
  display: inline-block;
  margin: 0 2px 2px 2px !important;
  -webkit-transition: background-color 0.2s, border-color 0.2s;
  -moz-transition: background-color 0.2s, border-color 0.2s;
  -ms-transition: background-color 0.2s, border-color 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s;
  transition: background-color 0.2s, border-color 0.2s;
  float: none !important;
}

.fullscreenbanner-container .tp-bullets.preview4 .bullet:hover, .fullscreenbanner-container .tp-bullets.preview4 .bullet.selected {
  box-shadow: none !important;
  background-color: #ffffff !important;
}

.fullscreenbanner-container .tparrows.preview4 .tp-arr-iwrapper {
  display: none !important;
}

.tp-bullets {
  z-index: 99;
}

@media (min-width: 768px) {
  .tparrows.preview4:hover:after {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  .tp-leftarrow.preview4:hover:after {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
}

.fullscreenbanner {
  width: 100%;
  position: relative;
}

.slider-captionV1 .tp-caption {
  white-space: normal !important;
  width: inherit;
}

@media (min-width: 768px) {
  .slider-captionV1 .tp-caption {
    width: 80%;
  }
}

@media (min-width: 992px) {
  .slider-captionV1 .tp-caption {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .slider-captionV1 .tp-caption {
    width: 55%;
  }
}

.captionCenterAlign {
  width: auto !important;
  text-align: center !important;
  margin: 0 auto !important;
}

/*=== BANNER V1 ===*/
.slider-captionV1 .rs-caption-1 {
  width: 50%;
}

@media (min-width: 768px) {
  .slider-captionV1 .rs-caption-1 {
    width: 60%;
  }
}

@media (min-width: 1200px) {
  .slider-captionV1 .rs-caption-1 {
    width: 55%;
  }
}

.bannerV1 .tp-caption.rs-caption-2 {
  text-transform: capitalize;
  color: #252525 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

@media (min-width: 480px) {
  .bannerV1 .tp-caption.rs-caption-2 {
    font-size: 16px !important;
    line-height: 22px !important;
    top: 35px !important;
  }
}

@media (min-width: 768px) {
  .bannerV1 .tp-caption.rs-caption-2 {
    font-size: 36px !important;
    line-height: 42px !important;
    top: 45px !important;
  }
}

@media (min-width: 992px) {
  .bannerV1 .tp-caption.rs-caption-2 {
    font-size: 46px !important;
    line-height: 42px !important;
    top: 75px !important;
  }
}

@media (min-width: 1200px) {
  .bannerV1 .tp-caption.rs-caption-2 {
    top: 110px !important;
  }
}

.bannerV1 .tp-caption.rs-caption-3 {
  text-transform: capitalize;
  color: #704664 !important;
  font-weight: 300;
  font-size: 12px !important;
  line-height: 18px !important;
}

@media (min-width: 480px) {
  .bannerV1 .tp-caption.rs-caption-3 {
    font-size: 14px !important;
    line-height: 20px !important;
    top: 65px !important;
  }
}

@media (min-width: 768px) {
  .bannerV1 .tp-caption.rs-caption-3 {
    font-size: 22px !important;
    line-height: 36px !important;
    top: 110px !important;
  }
}

@media (min-width: 992px) {
  .bannerV1 .tp-caption.rs-caption-3 {
    top: 135px !important;
  }
}

@media (min-width: 1200px) {
  .bannerV1 .tp-caption.rs-caption-3 {
    top: 180px !important;
  }
}

.bannerV1 .tp-caption.rs-caption-3 small {
  text-transform: none;
  color: #252525 !important;
  font-size: 10px !important;
  margin-top: 10px;
  display: block;
  max-width: 200px;
  line-height: 16px !important;
}

@media (min-width: 480px) {
  .bannerV1 .tp-caption.rs-caption-3 small {
    font-size: 12px !important;
    line-height: 18px !important;
  }
}

@media (min-width: 768px) {
  .bannerV1 .tp-caption.rs-caption-3 small {
    font-size: 13px !important;
    line-height: 18px !important;
    max-width: 350px;
  }
}

@media (min-width: 992px) {
  .bannerV1 .tp-caption.rs-caption-3 small {
    font-size: 14px !important;
    line-height: 22px !important;
    max-width: 490px;
  }
}

.tp-caption.rs-caption-4 .btn,
.bannerV2 .btn, .bannerV3 .btn {
  border: 3px solid;
  border-color: rgba(71, 186, 193, 0.5) !important;
  color: #252525 !important;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: auto;
  height: auto;
  line-height: 30px;
  text-align: center;
  border-radius: 0;
  padding: 0 10px !important;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 479px) {
  .tp-caption.rs-caption-4 .btn,
  .bannerV2 .btn, .bannerV3 .btn {
    display: none;
  }
}

@media (min-width: 768px) {
  .tp-caption.rs-caption-4 .btn,
  .bannerV2 .btn, .bannerV3 .btn {
    margin-top: 30px;
    font-size: 13px;
    width: 140px;
    height: 40px;
    line-height: 36px;
    display: inline-block;
    padding: 0 !important;
  }
}

@media (min-width: 992px) {
  .tp-caption.rs-caption-4 .btn,
  .bannerV2 .btn, .bannerV3 .btn {
    margin-top: 0px;
    font-size: 15px;
    width: 160px;
    height: 50px;
    letter-spacing: 2px;
    line-height: 46px;
  }
}

.tp-caption.rs-caption-4 .btn i,
.bannerV2 .btn i, .bannerV3 .btn i {
  color: #252525 !important;
  font-size: 14px;
  padding-left: 8px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tp-caption.rs-caption-4 .btn:hover,
.bannerV2 .btn:hover, .bannerV3 .btn:hover {
  background-color: #47bac1;
  color: #ffffff !important;
}

.tp-caption.rs-caption-4 .btn:hover i,
.bannerV2 .btn:hover i, .bannerV3 .btn:hover i {
  color: #ffffff !important;
}

.fullscreenbanner .tp-dottedoverlay.twoxtwo {
  background-image: none !important;
  z-index: 1;
}

.captionCenter .tp-caption.rs-caption-2 {
  text-transform: uppercase;
  color: #704664 !important;
  font-weight: 300;
}

@media (min-width: 480px) {
  .captionCenter .tp-caption.rs-caption-2 {
    font-size: 12px !important;
    line-height: 18px !important;
  }
}

@media (min-width: 768px) {
  .captionCenter .tp-caption.rs-caption-2 {
    font-size: 24px !important;
    line-height: 36px !important;
  }
}

.captionCenter .tp-caption.rs-caption-3 {
  text-transform: uppercase;
  color: #797979 !important;
}

@media (min-width: 480px) {
  .captionCenter .tp-caption.rs-caption-3 {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

@media (min-width: 768px) {
  .captionCenter .tp-caption.rs-caption-3 {
    font-size: 36px !important;
    line-height: 42px !important;
  }
}

/*=== BANNER V2 ===*/
.bannerV2 .slider-caption-info {
  background: #ffffff;
  padding: 25px;
  margin: 80px auto;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  -webkit-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 479px) {
  .bannerV2 .slider-caption-info {
    padding: 7px;
    width: 200px;
    height: 200px;
    margin: 70px auto;
  }
}

@media (min-width: 768px) {
  .bannerV2 .slider-caption-info {
    padding: 15px;
    width: 360px;
    height: 360px;
    margin: 90px auto;
  }
}

@media (min-width: 992px) {
  .bannerV2 .slider-caption-info {
    padding: 25px;
    width: 450px;
    height: 450px;
    margin: 130px auto;
  }
}

.bannerV2 .caption-info-inner {
  padding: 0;
  margin: 0 auto;
  border-radius: 50%;
  padding: 7px;
  width: 186px;
  height: 186px;
  border: 2px solid #47bac1;
}

@media (min-width: 480px) {
  .bannerV2 .caption-info-inner {
    padding: 20px;
    width: 270px;
    height: 270px;
  }
}

@media (min-width: 768px) {
  .bannerV2 .caption-info-inner {
    width: 330px;
    height: 330px;
  }
}

@media (min-width: 992px) {
  .bannerV2 .caption-info-inner {
    width: 400px;
    height: 400px;
  }
}

.bannerV2 {
  width: 100%;
  position: relative;
  padding: 0;
  background-color: #f5f5f5;
}

.bannerV2 .h2 {
  font-size: 25px;
  line-height: 25px;
  color: #252525;
  font-weight: 400;
  margin: 30px auto;
  text-transform: capitalize;
}

@media (max-width: 479px) {
  .bannerV2 .h2 {
    font-size: 20px;
    line-height: 24px;
    margin: 10px auto 7px;
  }
}

@media (min-width: 768px) {
  .bannerV2 .h2 {
    margin: 30px auto;
  }
}

@media (min-width: 992px) {
  .bannerV2 .h2 {
    font-size: 34px;
    line-height: 28px;
    margin: 50px auto 25px;
  }
}

.bannerV2 .border {
  border: none !important;
  width: 45px;
  height: 3px;
  background: #47bac1;
  margin: 0 auto;
  display: block;
}

.bannerV2 .para {
  font-size: 11px;
  line-height: 16px;
  color: #797979;
  margin: 10px;
  display: block;
  clear: both;
}

@media (min-width: 480px) {
  .bannerV2 .para {
    margin: 20px 10px;
  }
}

@media (min-width: 768px) {
  .bannerV2 .para {
    font-size: 14px;
    line-height: 18px;
    margin: 20px 25px;
  }
}

@media (min-width: 992px) {
  .bannerV2 .para {
    font-size: 14px;
    line-height: 22px;
    margin: 25px 30px;
  }
}

.bannerV2 .slider-button a.btn {
  display: none;
  margin-top: 15px;
  font-size: 14px;
  width: 146px;
  height: 40px;
  line-height: 40px;
  padding: 0 !important;
  border: none;
  background: #47bac1;
  color: #ffffff !important;
}

.bannerV2 .slider-button a.btn i {
  color: #ffffff !important;
}

@media (min-width: 768px) {
  .bannerV2 .slider-button a.btn {
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .bannerV2 .slider-button a.btn {
    margin-top: 30px;
    width: 167px;
    height: 50px;
    line-height: 50px;
  }
}

.bannerV2 .fullscreenbanner-container {
  width: 100%;
  overflow: hidden;
}

.bannerV2 .fullscreenbanner-container .fullscreenbanner-bg {
  background: #47bac1;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(50% - 50px);
  height: 100%;
  bottom: 0;
}

@media (min-width: 768px) {
  .bannerV2 .fullscreenbanner-container .fullscreenbanner-bg {
    width: calc(50% - 100px);
  }
}

@media (min-width: 992px) {
  .bannerV2 .fullscreenbanner-container .fullscreenbanner-bg {
    width: calc(50% - 150px);
  }
}

.bannerV2 .fullscreenbanner-container .fullscreenbanner-bg:after {
  position: absolute;
  content: '';
  border-left: 100px solid #47bac1;
  border-bottom: 410px solid transparent;
  right: -100px;
}

@media (min-width: 768px) {
  .bannerV2 .fullscreenbanner-container .fullscreenbanner-bg:after {
    border-left: 200px solid #47bac1;
    border-bottom: 613px solid transparent;
    right: -200px;
  }
}

@media (min-width: 992px) {
  .bannerV2 .fullscreenbanner-container .fullscreenbanner-bg:after {
    border-left: 300px solid #47bac1;
    border-bottom: 800px solid transparent;
    right: -300px;
  }
}

.bannerV2 .fullscreenbanner-container .slider-caption {
  position: relative;
  padding: 35px 0;
}

.bannerV2 .fullscreenbanner-container .slider-caption .caption {
  position: absolute;
  width: 23%;
}

@media (min-width: 768px) {
  .bannerV2 .fullscreenbanner-container .slider-caption .caption {
    width: 19%;
  }
}

.bannerV2 .fullscreenbanner-container .slider-caption .caption span.tag {
  background: #ffeb41;
  display: inline-block;
  font-size: 12px;
  color: #252525;
  padding: 3px 5px;
  position: absolute;
  right: -7%;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 700;
}

@media (min-width: 768px) {
  .bannerV2 .fullscreenbanner-container .slider-caption .caption span.tag {
    font-size: 15px;
    padding: 5px 10px;
  }
}

.bannerV2 .fullscreenbanner-container .slider-caption img {
  display: inline-block;
  max-width: 100%;
}

.bannerV2 .fullscreenbanner-container .slider-caption .caption1 {
  left: -7%;
}

@media (min-width: 768px) {
  .bannerV2 .fullscreenbanner-container .slider-caption .caption1 {
    left: -4%;
  }
}

@media (min-width: 992px) {
  .bannerV2 .fullscreenbanner-container .slider-caption .caption1 {
    left: -7%;
  }
}

.bannerV2 .fullscreenbanner-container .slider-caption .caption2 {
  left: -7%;
  bottom: 35px;
}

.bannerV2 .fullscreenbanner-container .slider-caption .caption3 {
  right: -7%;
}

.bannerV2 .fullscreenbanner-container .slider-caption .caption3 span.tag {
  right: unset;
  left: -30px;
}

@media (min-width: 768px) {
  .bannerV2 .fullscreenbanner-container .slider-caption .caption3 span.tag {
    right: auto;
    left: 0;
  }
}

.bannerV2 .fullscreenbanner-container .slider-caption .caption4 {
  text-align: right;
  right: -7%;
  bottom: 35px;
}

.bannerV2 .fullscreenbanner-container .slider-caption .caption4 span.tag {
  right: unset;
  left: -30px;
}

@media (min-width: 768px) {
  .bannerV2 .fullscreenbanner-container .slider-caption .caption4 span.tag {
    right: auto;
    left: 0;
  }
}

.from-bottom {
  opacity: 0;
  -webkit-transition: all 0.5s 0.3s ease-in-out;
  -o-transition: all 0.5s 0.3s ease-in-out;
  transition: all 0.5s 0.3s ease-in-out;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.from-bottom {
  -webkit-transform: translateY(45px);
  -moz-transform: translateY(45px);
  -ms-transform: translateY(45px);
  -o-transform: translateY(45px);
  transform: translateY(45px);
}

.current-sr-slide-visible .from-bottom {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.delay-1 {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.delay-2 {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.delay-3 {
  -webkit-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.delay-4 {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

/*===  BANNER V3 ===*/
.bannerV3 {
  padding: 0;
  background: #f0f0f0;
}

.bannerV3 .slide {
  height: 450px;
  background: #ffffff;
  -webkit-box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 25px 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .bannerV3 .slide {
    text-align: left;
    height: 500px;
  }
}

@media (min-width: 1200px) {
  .bannerV3 .slide {
    height: 550px;
    padding: 55px 50px;
  }
}

.bannerV3 .slide .slider-title {
  margin: 0 0 25px 0;
  border-bottom: 5px solid #e4e4e4;
}

@media (min-width: 480px) {
  .bannerV3 .slide .slider-title {
    margin: 0 0 10px 0;
  }
}

@media (min-width: 768px) {
  .bannerV3 .slide .slider-title {
    margin: 0 0 25px 0;
  }
}

@media (min-width: 1200px) {
  .bannerV3 .slide .slider-title {
    margin: 0 0 45px 0;
  }
}

.bannerV3 .slide .slider-title h3 {
  font-size: 20px;
  display: inline-block;
  margin: 0 auto 10px;
  text-transform: uppercase;
  font-weight: 700;
}

@media (min-width: 768px) {
  .bannerV3 .slide .slider-title h3 {
    margin: 0 0 10px;
  }
}

@media (min-width: 1200px) {
  .bannerV3 .slide .slider-title h3 {
    font-size: 30px;
  }
}

.bannerV3 .slide .slider-title h3 span {
  color: #47bac1;
}

.bannerV3 .slide .productCaption {
  position: relative;
  z-index: 9;
  width: 100%;
  text-align: center;
}

@media (min-width: 768px) {
  .bannerV3 .slide .productCaption {
    width: 60%;
    text-align: left;
  }
}

.bannerV3 .slide .productCaption h4 {
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: 700;
  text-transform: capitalize;
}

@media (min-width: 768px) {
  .bannerV3 .slide .productCaption h4 {
    margin-bottom: 30px;
  }
}

@media (min-width: 1200px) {
  .bannerV3 .slide .productCaption h4 {
    font-size: 24px;
  }
}

.bannerV3 .slide .productCaption span {
  display: inline-block;
}

.bannerV3 .slide .productCaption .offer-price {
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  color: #47bac1;
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .bannerV3 .slide .productCaption .offer-price {
    font-size: 24px;
  }
}

.bannerV3 .slide .productCaption .regular-price {
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  color: #c5c5c5;
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .bannerV3 .slide .productCaption .regular-price {
    font-size: 20px;
  }
}

.bannerV3 .slide .productCaption .offer {
  font-size: 16px;
  background-color: #47bac1;
  color: #ffffff;
  padding: 7px 10px;
}

@media (min-width: 1200px) {
  .bannerV3 .slide .productCaption .offer {
    font-size: 20px;
  }
}

.bannerV3 .slide .slider_timer {
  width: 100%;
  margin-bottom: 25px;
}

@media (min-width: 1200px) {
  .bannerV3 .slide .slider_timer {
    margin-bottom: 45px;
  }
}

.bannerV3 .slide .slider_timer .table-cell {
  width: 40px;
  margin-right: 8px;
  display: inline-block;
}

@media (min-width: 1200px) {
  .bannerV3 .slide .slider_timer .table-cell {
    width: 60px;
    margin-right: 15px;
  }
}

.bannerV3 .slide .slider_timer .table-cell:last-child {
  margin-right: 0;
}

.bannerV3 .slide .slider_timer .table-cell .tab-val {
  width: 100%;
  height: 40px;
  background: #f0f0f0;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
}

@media (min-width: 1200px) {
  .bannerV3 .slide .slider_timer .table-cell .tab-val {
    height: 60px;
    line-height: 60px;
    font-size: 30px;
  }
}

.bannerV3 .slide .slider_timer .table-cell .tab-metr {
  font-size: 14px;
  text-transform: capitalize;
}

@media (min-width: 1200px) {
  .bannerV3 .slide .slider_timer .table-cell .tab-metr {
    font-size: 12px;
  }
}

.bannerV3 .slide .btn {
  display: inline-block;
  clear: both;
  margin-top: 20px;
  border-width: 2px;
}

@media (min-width: 768px) {
  .bannerV3 .slide .btn {
    margin-top: 35px;
  }
  .bannerV3 .slide .btn i {
    margin-left: 10px;
  }
}

@media (min-width: 1200px) {
  .bannerV3 .slide .btn {
    margin-top: 55px;
  }
}

.slide-info {
  position: relative;
}

.slide-info .productImage {
  width: 25%;
  margin: 0 auto 20px;
}

@media (min-width: 480px) {
  .slide-info .productImage {
    width: 16%;
  }
}

@media (min-width: 768px) {
  .slide-info .productImage {
    width: 39%;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
  }
}

.slide-info .productImage img {
  display: inline-block;
  width: auto;
  max-width: 100%;
}

.bannerV3 .bg-image-box {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.bannerV3 .slide {
  margin: 30px 15px 30px;
}

@media (min-width: 768px) {
  .bannerV3 .slide {
    margin: 50px 50px 50px -50px;
  }
}

@media (min-width: 992px) {
  .bannerV3 .slide {
    margin: 80px 80px 80px -80px;
  }
}

/*===  BANNER V4 ===*/
.bannerV4 {
  width: 100%;
  float: right;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .bannerV4 {
    width: 835px;
  }
}

@media (min-width: 1200px) {
  .bannerV4 .fullscreenbanner {
    height: 470px !important;
  }
}

.bannerV4.fullscreenbanner-container .tp-bullets {
  display: none;
}

.bannerV4 .tparrows.default.preview4.tp-leftarrow {
  left: 20px !important;
}

.bannerV4 .tparrows.default.preview4.tp-rightarrow {
  right: 20px !important;
}

.slider-captionV4 .tp-caption.rs-caption-2,
.slider-captionV4 .tp-caption.rs-caption-3,
.slider-captionV4 .tp-caption.rs-caption-4 {
  white-space: normal !important;
  width: inherit;
}

@media (min-width: 768px) {
  .slider-captionV4 .tp-caption.rs-caption-2,
  .slider-captionV4 .tp-caption.rs-caption-3,
  .slider-captionV4 .tp-caption.rs-caption-4 {
    width: 80%;
  }
}

@media (min-width: 992px) {
  .slider-captionV4 .tp-caption.rs-caption-2,
  .slider-captionV4 .tp-caption.rs-caption-3,
  .slider-captionV4 .tp-caption.rs-caption-4 {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .slider-captionV4 .tp-caption.rs-caption-2,
  .slider-captionV4 .tp-caption.rs-caption-3,
  .slider-captionV4 .tp-caption.rs-caption-4 {
    width: 55%;
  }
}

.slider-captionV4 .tp-caption.rs-caption-2 {
  text-transform: uppercase;
  color: #252525 !important;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 20px !important;
}

@media (min-width: 480px) {
  .slider-captionV4 .tp-caption.rs-caption-2 {
    font-size: 24px !important;
    line-height: 30px !important;
  }
}

@media (min-width: 768px) {
  .slider-captionV4 .tp-caption.rs-caption-2 {
    font-size: 36px !important;
    line-height: 38px !important;
  }
}

.slider-captionV4 .tp-caption.rs-caption-2 small {
  color: #47bac1 !important;
  font-size: 10px !important;
  line-height: 16px !important;
}

@media (min-width: 480px) {
  .slider-captionV4 .tp-caption.rs-caption-2 small {
    font-size: 13px !important;
    line-height: 17px !important;
  }
}

@media (min-width: 768px) {
  .slider-captionV4 .tp-caption.rs-caption-2 small {
    font-size: 16px !important;
    line-height: 20px !important;
  }
}

.slider-captionV4 .tp-caption.rs-caption-3 {
  color: rgba(37, 37, 37, 0.7) !important;
  font-size: 12px !important;
  line-height: 16px !important;
  margin-top: 0 !important;
}

@media (min-width: 480px) {
  .slider-captionV4 .tp-caption.rs-caption-3 {
    font-size: 12px !important;
    line-height: 16px !important;
  }
}

@media (min-width: 768px) {
  .slider-captionV4 .tp-caption.rs-caption-3 {
    font-size: 16px !important;
    line-height: 20px !important;
    margin-top: -20px !important;
  }
}

@media (min-width: 992px) {
  .slider-captionV4 .tp-caption.rs-caption-3 {
    margin-top: -30px !important;
  }
}

.slider-captionV4 .tp-caption.rs-caption-4 {
  margin-top: 0 !important;
}

@media (min-width: 768px) {
  .slider-captionV4 .tp-caption.rs-caption-4 {
    margin-top: -20px !important;
  }
}

@media (min-width: 992px) {
  .slider-captionV4 .tp-caption.rs-caption-4 {
    margin-top: -40px !important;
  }
}

/*=== BANNER V5 ===*/
.bannerV5 .fullscreenbanner-container {
  width: unset;
  min-height: 400px;
}

@media (min-width: 768px) {
  .bannerV5 .fullscreenbanner-container {
    min-height: 600px;
  }
}

@media (min-width: 768px) {
  .bannerV5 .fullscreenbanner-container {
    min-height: 500px;
  }
}

@media (min-width: 992px) {
  .bannerV5 .fullscreenbanner-container {
    min-height: 600px;
  }
}

@media (min-width: 1200px) {
  .bannerV5 .fullscreenbanner-container {
    min-height: 800px;
  }
}

.bannerV5 .slider-caption {
  text-align: center;
}

@media (min-width: 768px) {
  .bannerV5 .slider-caption {
    text-align: left;
  }
}

@media (min-width: 992px) {
  .bannerV5 .slider-caption {
    margin-right: 30px;
  }
}

.bannerV5 .slider-caption h1 {
  padding-top: 50px;
  font-weight: 700;
  font-size: 30px;
  line-height: 1;
  color: #ffffff;
}

@media (min-width: 768px) {
  .bannerV5 .slider-caption h1 {
    font-size: 35px;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .bannerV5 .slider-caption h1 {
    font-size: 55px;
  }
}

.bannerV5 .slider-caption p {
  font-size: 13px;
  line-height: 26px;
  color: #ffffff;
  padding: 0px 0px 10px 0;
}

@media (min-width: 768px) {
  .bannerV5 .slider-caption p {
    font-size: 14px;
    padding: 0px 0px 10px 0;
  }
}

@media (min-width: 992px) {
  .bannerV5 .slider-caption p {
    font-size: 18px;
    padding: 10px 100px 20px 0;
  }
}

.bannerV5 .slider-caption .primary-btn {
  width: 146px;
  height: 40px;
  line-height: 36px;
  padding: 0;
  font-size: 13px;
  color: #ffffff;
  border: 2px solid #47bac1;
}

@media (min-width: 992px) {
  .bannerV5 .slider-caption .primary-btn {
    width: 170px;
    height: 50px;
    line-height: 46px;
    font-size: 14px;
  }
}

.bannerV5 .slider-caption .primary-btn i {
  padding-left: 8px;
}

.bannerV5 .promo-video-holder {
  display: none;
  border: 2px solid rgba(255, 255, 255, 0.5);
  padding: 15px;
  margin-top: 50px;
}

@media (min-width: 768px) {
  .bannerV5 .promo-video-holder {
    display: block;
  }
}

.bannerV5 .promo-video-holder .promo-video {
  float: none;
  padding-top: 0;
}

.bannerV5 .promo-video-holder a.video-link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bannerV5 .promo-video-holder a.video-link i.play-icon {
  position: relative;
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 30px;
  border-radius: 50%;
  display: inline-block;
  color: #ffffff;
  padding-left: 10px;
  text-align: center;
  background: #47bac1;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.top-version3 {
  margin-top: 32px;
}

/*===  SLIDER REVULATION BANNER V1 ===*/
.forcefullwidth_wrapper_tp_banner {
  margin-bottom: 15px !important;
}

.rev_slider_25_1 .rev-btn {
  transition: ease-in-out .3s !important;
}

.rev_slider_25_1 .custom.tparrows {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.25);
  width: 30px;
  height: 110px;
  position: absolute;
  display: block;
  z-index: 100;
}

.rev_slider_25_1 .custom.tparrows:before {
  font-family: "revicons";
  font-size: 20px;
  color: #fff;
  display: block;
  line-height: 110px;
  text-align: center;
}

.rev_slider_25_1 .custom.tparrows.tp-leftarrow {
  transform: matrix(1, 0, 0, 1, 0, -48) !important;
}

.rev_slider_25_1 .custom.tparrows.tp-rightarrow {
  transform: matrix(1, 0, 0, 1, -30, -48) !important;
}

.rev_slider_25_1 .custom.tparrows.tp-leftarrow:before {
  content: "\e824";
}

.rev_slider_25_1 .custom.tparrows.tp-rightarrow:before {
  content: "\e825";
}

.rev_slider_25_1 .hesperiden.tp-bullets:before {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  left: -10px;
  top: 4px;
  z-index: -1;
  background: #4dc8cf;
  background: linear-gradient(135deg, #4dc8cf 0%, #4dc8cf 50%, #47bac1 51%, #47bac1 100%);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.rev_slider_25_1 .hesperiden.tp-bullets:after {
  content: "";
  width: 22px;
  height: 22px;
  position: absolute;
  right: -10px;
  top: 4px;
  z-index: -1;
  background: #4dc8cf;
  background: linear-gradient(135deg, #4dc8cf 0%, #4dc8cf 50%, #47bac1 51%, #47bac1 100%);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.rev_slider_25_1 .hesperiden .tp-bullet {
  width: 9px;
  height: 9px;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: content-box;
  transform: translate(55px, -50%);
  top: 50% !important;
}

.rev_slider_25_1 .tp-bullets, .tp-tabs, .tp-thumbs {
  background: linear-gradient(to bottom, #5cc2c8 0%, #5cc2c8 50%, #47bac1 51%, #47bac1 100%);
  width: 150px !important;
  height: 30px !important;
  transform: matrix(1, 0, 0, 1, -75, -15) !important;
  opacity: 0;
}

@media (min-width: 768px) {
  .rev_slider_25_1 .tp-bullets, .tp-tabs, .tp-thumbs {
    opacity: 1;
  }
}

.rev_slider_25_1 .hesperiden .tp-bullet:hover,
.rev_slider_25_1 .hesperiden .tp-bullet.selected {
  background: #ffffff;
}

/*===  SLIDER REVULATION BANNER V4 ===*/
.rev_slider_26_1_wrapper {
  width: 100% !important;
  left: 0 !important;
  right: 0 !important;
}

.rev_slider_26_1 .rev-btn:hover {
  background-color: #704664 !important;
}

.rev_slider_26_1 .custom.tparrows {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.25);
  width: 30px;
  height: 110px;
  position: absolute;
  display: block;
  z-index: 100;
}

.rev_slider_26_1 .custom.tparrows:before {
  font-family: "revicons";
  font-size: 20px;
  color: #fff;
  display: block;
  line-height: 110px;
  text-align: center;
}

.rev_slider_26_1 .custom.tparrows.tp-leftarrow:before {
  content: "\e824";
}

.rev_slider_26_1 .custom.tparrows.tp-rightarrow:before {
  content: "\e825";
}

/*================= 3.1 HOME ==================*/
/*=== 3.1.1 WHITE SECTION ===*/
.page-header {
  margin: 0 0 17px 0;
  padding-bottom: 9px;
  border-bottom: 3px solid #e4e4e4;
}

.page-header h4 {
  margin-bottom: 0;
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 1px;
}

.masking-hover, .imageBox .productImage .productMasking, .whiteSection .featuredProducts .slide .productImage .productMasking, .mainContent .featuredProducts .slide .productImage .productMasking, .productsContent .productBox .productImage .productMasking, .whiteSection .featuredCollection .thumbnail .imageWrapper .masking, .mainContent .featuredCollection .thumbnail .imageWrapper .masking, .productListSingle .media .media-left .maskingImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(71, 186, 193, 0.9);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.content-center, .imageBox .productImage .productMasking ul, .whiteSection .featuredProducts .slide .productImage .productMasking ul, .mainContent .featuredProducts .slide .productImage .productMasking ul, .productsContent .productBox .productImage .productMasking ul {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.content-middle {
  position: absolute;
  top: 50%;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.content-top, .whiteSection .featuredCollection .thumbnail .caption, .mainContent .featuredCollection .thumbnail .caption {
  position: absolute;
  top: 0;
  left: 0;
}

.content-bottom, .masking-btn, .whiteSection .featuredCollection .thumbnail .imageWrapper .masking .viewBtn, .mainContent .featuredCollection .thumbnail .imageWrapper .masking .viewBtn, .productListSingle .media .media-left .maskingImage .viewBtn {
  position: absolute;
  bottom: 0;
  left: 0;
}

.thumbnail {
  border: none;
  border-radius: 0;
  padding: 0;
}

.thumbnail .caption {
  padding: 0;
}

.btn-wishlist.active {
  color: #f00 !important;
}

.btn-wishlist.active:hover {
  color: #f00;
}

.imageBox, .whiteSection .featuredProducts .slide, .mainContent .featuredProducts .slide, .productsContent .productBox {
  height: auto;
  display: block;
  margin-bottom: 40px;
}

.imageBox .productImage, .whiteSection .featuredProducts .slide .productImage, .mainContent .featuredProducts .slide .productImage, .productsContent .productBox .productImage {
  position: relative;
  margin-bottom: 20px;
  display: block;
  overflow: hidden;
}

.imageBox .productImage img, .whiteSection .featuredProducts .slide .productImage img, .mainContent .featuredProducts .slide .productImage img, .productsContent .productBox .productImage img {
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.imageBox .productImage .productMasking ul, .whiteSection .featuredProducts .slide .productImage .productMasking ul, .mainContent .featuredProducts .slide .productImage .productMasking ul, .productsContent .productBox .productImage .productMasking ul {
  width: 168px;
  height: 56px;
  background: #fff;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.imageBox .productImage .productMasking ul li, .whiteSection .featuredProducts .slide .productImage .productMasking ul li, .mainContent .featuredProducts .slide .productImage .productMasking ul li, .productsContent .productBox .productImage .productMasking ul li {
  position: relative;
  width: 33.333%;
}

.imageBox .productImage .productMasking ul li::after, .whiteSection .featuredProducts .slide .productImage .productMasking ul li::after, .mainContent .featuredProducts .slide .productImage .productMasking ul li::after, .productsContent .productBox .productImage .productMasking ul li::after {
  position: absolute;
  content: '';
  height: 24px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  top: 50%;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
}

.imageBox .productImage .productMasking ul li:last-child::after, .whiteSection .featuredProducts .slide .productImage .productMasking ul li:last-child::after, .mainContent .featuredProducts .slide .productImage .productMasking ul li:last-child::after, .productsContent .productBox .productImage .productMasking ul li:last-child::after {
  display: none;
}

.imageBox .productImage .productMasking ul li a, .whiteSection .featuredProducts .slide .productImage .productMasking ul li a, .mainContent .featuredProducts .slide .productImage .productMasking ul li a, .productsContent .productBox .productImage .productMasking ul li a {
  padding: 0;
  width: 100%;
  text-align: center;
  border: none;
  border-radius: 0px;
  background-color: transparent;
  font-size: 14px;
  color: #252525;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.imageBox .productImage .productMasking ul li a:hover, .whiteSection .featuredProducts .slide .productImage .productMasking ul li a:hover, .mainContent .featuredProducts .slide .productImage .productMasking ul li a:hover, .productsContent .productBox .productImage .productMasking ul li a:hover {
  color: #252525;
}

@media (min-width: 480px) {
  .imageBox .productImage, .whiteSection .featuredProducts .slide .productImage, .mainContent .featuredProducts .slide .productImage, .productsContent .productBox .productImage {
    width: 100%;
    height: auto;
  }
}

.imageBox:hover .productImage img, .whiteSection .featuredProducts .slide:hover .productImage img, .mainContent .featuredProducts .slide:hover .productImage img, .productsContent .productBox:hover .productImage img {
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.imageBox:hover .productMasking, .whiteSection .featuredProducts .slide:hover .productMasking, .mainContent .featuredProducts .slide:hover .productMasking, .productsContent .productBox:hover .productMasking {
  opacity: 1;
}

.imageBox:hover .productMasking ul, .whiteSection .featuredProducts .slide:hover .productMasking ul, .mainContent .featuredProducts .slide:hover .productMasking ul, .productsContent .productBox:hover .productMasking ul {
  -webkit-animation: move-bottom 0.3s linear;
  animation: move-bottom 0.3s linear;
}

.imageBox .productCaption, .whiteSection .featuredProducts .slide .productCaption, .mainContent .featuredProducts .slide .productCaption, .productsContent .productBox .productCaption {
  width: 100%;
  display: block;
  text-align: center;
}

@media (min-width: 768px) {
  .imageBox .productCaption, .whiteSection .featuredProducts .slide .productCaption, .mainContent .featuredProducts .slide .productCaption, .productsContent .productBox .productCaption {
    text-align: left;
  }
}

.imageBox .productCaption h4, .whiteSection .featuredProducts .slide .productCaption h4, .mainContent .featuredProducts .slide .productCaption h4, .productsContent .productBox .productCaption h4 {
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.imageBox .productCaption h4 a, .whiteSection .featuredProducts .slide .productCaption h4 a, .mainContent .featuredProducts .slide .productCaption h4 a, .productsContent .productBox .productCaption h4 a {
  color: #252525;
}

.imageBox .productCaption h3, .whiteSection .featuredProducts .slide .productCaption h3, .mainContent .featuredProducts .slide .productCaption h3, .productsContent .productBox .productCaption h3 {
  color: #a5a5a5;
  font-size: 20px;
  font-weight: 700;
}

.imageBox .productCaption h5, .whiteSection .featuredProducts .slide .productCaption h5, .mainContent .featuredProducts .slide .productCaption h5, .productsContent .productBox .productCaption h5 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.imageBox .productCaption h5 a, .whiteSection .featuredProducts .slide .productCaption h5 a, .mainContent .featuredProducts .slide .productCaption h5 a, .productsContent .productBox .productCaption h5 a {
  color: #252525;
}

.imageBox .productCaption .offer-price, .whiteSection .featuredProducts .slide .productCaption .offer-price, .mainContent .featuredProducts .slide .productCaption .offer-price, .productsContent .productBox .productCaption .offer-price {
  font-size: 24px;
  font-weight: 700;
  color: #47bac1;
  display: block;
  margin-bottom: 10px;
}

.imageBox .productCaption .regular-price, .whiteSection .featuredProducts .slide .productCaption .regular-price, .mainContent .featuredProducts .slide .productCaption .regular-price, .productsContent .productBox .productCaption .regular-price {
  font-size: 18px;
  color: #c5c5c5;
  display: block;
  margin-bottom: 25px;
}

.masking-btn, .whiteSection .featuredCollection .thumbnail .imageWrapper .masking .viewBtn, .mainContent .featuredCollection .thumbnail .imageWrapper .masking .viewBtn, .productListSingle .media .media-left .maskingImage .viewBtn {
  height: 50px;
  width: 165px;
  bottom: 35px;
  left: 30px;
  border: 3px solid #ffffff;
  font-weight: 700;
  text-transform: capitalize;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  box-shadow: 0;
  border-radius: 0;
  padding: 0;
  line-height: 45px;
  background-color: #ffffff;
  color: #252525;
}

.masking-btn:hover, .whiteSection .featuredCollection .thumbnail .imageWrapper .masking .viewBtn:hover, .mainContent .featuredCollection .thumbnail .imageWrapper .masking .viewBtn:hover, .productListSingle .media .media-left .maskingImage .viewBtn:hover {
  background-color: transparent;
  color: #ffffff !important;
}

.whiteSection, .mainContent {
  background-color: #ffffff;
}

@media (max-width: 479px) {
  .whiteSection, .mainContent {
    padding: 40px 0 40px 0;
  }
}

@media (min-width: 480px) {
  .whiteSection, .mainContent {
    padding: 90px 0 0 0;
  }
}

@media (min-width: 768px) {
  .whiteSection, .mainContent {
    padding: 90px 0 60px 0;
  }
}

.whiteSection .featuredCollection .thumbnail, .mainContent .featuredCollection .thumbnail {
  cursor: pointer;
  margin-bottom: 40px;
}

.whiteSection .featuredCollection .thumbnail .imageWrapper, .mainContent .featuredCollection .thumbnail .imageWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.whiteSection .featuredCollection .thumbnail .imageWrapper img, .mainContent .featuredCollection .thumbnail .imageWrapper img {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  width: 100%;
}

.whiteSection .featuredCollection .thumbnail:hover .imageWrapper .masking, .mainContent .featuredCollection .thumbnail:hover .imageWrapper .masking {
  opacity: 1;
}

.whiteSection .featuredCollection .thumbnail:hover .imageWrapper img, .mainContent .featuredCollection .thumbnail:hover .imageWrapper img {
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.whiteSection .featuredCollection .thumbnail:hover .imageWrapper .viewBtn, .mainContent .featuredCollection .thumbnail:hover .imageWrapper .viewBtn {
  -webkit-animation: move-bottom 0.3s linear;
  animation: move-bottom 0.3s linear;
}

.whiteSection .featuredCollection .thumbnail .caption, .mainContent .featuredCollection .thumbnail .caption {
  top: 30px;
  left: 30px;
  z-index: 55;
}

@media (min-width: 768px) {
  .whiteSection .featuredCollection .thumbnail .caption, .mainContent .featuredCollection .thumbnail .caption {
    top: 10px;
    left: 10px;
  }
}

@media (min-width: 992px) {
  .whiteSection .featuredCollection .thumbnail .caption, .mainContent .featuredCollection .thumbnail .caption {
    top: 30px;
    left: 30px;
  }
}

.whiteSection .featuredCollection .thumbnail .caption h3, .mainContent .featuredCollection .thumbnail .caption h3 {
  text-transform: capitalize;
  line-height: 26px;
  color: #252525;
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: 700;
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .whiteSection .featuredCollection .thumbnail .caption h3, .mainContent .featuredCollection .thumbnail .caption h3 {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (min-width: 992px) {
  .whiteSection .featuredCollection .thumbnail .caption h3, .mainContent .featuredCollection .thumbnail .caption h3 {
    font-size: 22px;
    line-height: 26px;
  }
}

.whiteSection .featuredCollection .thumbnail .caption small, .mainContent .featuredCollection .thumbnail .caption small {
  color: #797979;
  display: inline-block;
  font-size: 13px;
  line-height: 26px;
  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

.whiteSection .featuredCollection .thumbnail:hover .caption h3, .whiteSection .featuredCollection .thumbnail:hover .caption small, .mainContent .featuredCollection .thumbnail:hover .caption h3, .mainContent .featuredCollection .thumbnail:hover .caption small {
  color: #ffffff;
}

.whiteSection .featuredProducts, .mainContent .featuredProducts {
  margin-bottom: 30px;
}

.whiteSection .featuredProducts .slide .productImage:hover img, .mainContent .featuredProducts .slide .productImage:hover img {
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.whiteSection .latestArticles .thumbnail, .mainContent .latestArticles .thumbnail {
  margin-bottom: 30px;
  position: relative;
}

.whiteSection .latestArticles .thumbnail .date-holder, .mainContent .latestArticles .thumbnail .date-holder {
  position: absolute;
  top: 20px;
  width: 55px;
  height: 60px;
  background: #fff;
  text-align: center;
  left: 20px;
  padding: 0px;
}

.whiteSection .latestArticles .thumbnail .date-holder p, .mainContent .latestArticles .thumbnail .date-holder p {
  margin-top: 8px;
  font-size: 22px;
  line-height: 26px;
}

.whiteSection .latestArticles .thumbnail .date-holder span, .mainContent .latestArticles .thumbnail .date-holder span {
  color: #252525;
  margin-top: 0px;
  text-transform: uppercase;
}

.whiteSection .latestArticles .thumbnail h5, .mainContent .latestArticles .thumbnail h5 {
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
  color: #252525;
  margin-top: 10px;
}

.whiteSection .latestArticles .thumbnail h5 a, .mainContent .latestArticles .thumbnail h5 a {
  font-size: inherit;
  letter-spacing: inherit;
  color: #252525;
}

.whiteSection .latestArticles .thumbnail h5 span, .mainContent .latestArticles .thumbnail h5 span {
  text-transform: capitalize;
  color: #bcbcbc;
}

.whiteSection .latestArticles .thumbnail h5 span a, .mainContent .latestArticles .thumbnail h5 span a {
  color: #bcbcbc;
  text-decoration: underline !important;
}

.whiteSection .latestArticles .thumbnail span, .mainContent .latestArticles .thumbnail span {
  color: #797979;
  display: block;
  margin-bottom: 10px;
  margin-top: 5px;
}

.whiteSection .latestArticles .thumbnail span a, .mainContent .latestArticles .thumbnail span a {
  color: #797979;
  text-decoration: underline !important;
  -webkit-transition: color 0.3s ease;
  -moz-transition: color 0.3s ease;
  -ms-transition: color 0.3s ease;
  -o-transition: color 0.3s ease;
  transition: color 0.3s ease;
}

.whiteSection .latestArticles .thumbnail span a:hover, .mainContent .latestArticles .thumbnail span a:hover {
  color: #47bac1;
}

.whiteSection .latestArticles .thumbnail img, .mainContent .latestArticles .thumbnail img {
  width: 100%;
}

.whiteSection .latestArticles .thumbnail p, .mainContent .latestArticles .thumbnail p {
  margin-bottom: 0;
  color: #252525;
  line-height: 22px;
}

.margin-bottom {
  margin-bottom: 40px;
}

/*=== 3.1.2 LIGHT SECTION ===*/
.lightSection {
  background-color: #f0f0f0;
  height: 125px;
}

.lightSection .partnersLogoSlider .owl-nav {
  display: none;
}

.lightSection .partnersLogoSlider .slide {
  display: table;
  height: 125px;
  width: 100%;
}

.lightSection .partnersLogoSlider .slide .partnersLogo {
  display: table-cell;
  vertical-align: middle;
  text-align: center !important;
}

.lightSection .partnersLogoSlider .slide .partnersLogo img {
  width: auto;
  margin: 0 auto;
}

@media (min-width: 480px) {
  .lightSection .partnersLogoSlider .slide .partnersLogo {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .lightSection .partnersLogoSlider .slide .partnersLogo {
    width: 270px;
  }
}

@-webkit-keyframes move-top {
  0% {
    top: 10%;
  }
  100% {
    top: 0;
  }
}

@keyframes move-top {
  0% {
    top: 10%;
  }
  100% {
    top: 0;
  }
}

@-webkit-keyframes move-bottom {
  0% {
    bottom: 20%;
  }
  100% {
    bottom: 10%;
  }
}

@keyframes move-bottom {
  0% {
    bottom: 20%;
  }
  100% {
    bottom: 10%;
  }
}

/*================= 3.2 HOME V2 ==================*/
/*=== 3.2.1 ABOUT ===*/
.aboutSection {
  margin-bottom: 75px;
}

.aboutSection h2 {
  font-size: 26px;
  font-weight: 400;
  margin: 0 auto 50px;
  display: inline-block;
  position: relative;
}

.aboutSection h2:after {
  position: absolute;
  content: '';
  left: 50%;
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -25px;
  width: 70px;
  height: 2px;
  background: rgba(71, 186, 193, 0.5);
}

.aboutSection p {
  font-size: 16px;
  line-height: 26px;
  margin: 0;
  color: #797979;
}

@media (min-width: 992px) {
  .aboutSection p {
    margin: 0 0px 0;
  }
}

/*=== 3.2.2 FEATURE COLLECTION ===*/
.featuredCollection.version2 {
  margin-bottom: 80px;
}

.featuredCollection.version2 .slide {
  background: #eeeeee;
  height: 300px;
  position: relative;
  margin: 0 15px;
}

.featuredCollection.version2 .productImage {
  width: 50%;
  float: left;
  height: 300px;
  position: relative;
}

.featuredCollection.version2 .productImage img {
  display: inline-block;
  max-width: 100%;
  width: auto;
  position: absolute;
  bottom: 15px;
}

.featuredCollection.version2 .productImage.float-right img {
  right: 10px;
}

.featuredCollection.version2 .productCaption {
  width: 60%;
  position: absolute;
  bottom: 40px;
  right: 0;
  padding-right: 30px;
}

.featuredCollection.version2 .productCaption h3 {
  font-size: 26px;
  font-weight: 400;
  margin: 0 0 10px;
}

.featuredCollection.version2 .productCaption h3 a {
  color: #252525;
}

.featuredCollection.version2 .productCaption p {
  font-size: 14px;
  font-weight: 400;
  margin: 0 0 25px;
  color: #797979;
}

.featuredCollection.version2 .productCaption .btn-border {
  border-radius: 0;
  border: 2px solid #b1b1b1;
  padding: 0px 15px;
  color: #252525;
  font-weight: 700;
  line-height: 36px;
}

.featuredCollection.version2 .productCaption.float-left {
  left: 0;
  padding-left: 30px;
}

/*=== 3.2.3 FEATURE PRODUCT ===*/
.version2 .imageBox, .version2 .whiteSection .featuredProducts .slide, .whiteSection .featuredProducts .version2 .slide, .version2 .mainContent .featuredProducts .slide, .mainContent .featuredProducts .version2 .slide, .version2 .productsContent .productBox, .productsContent .version2 .productBox {
  margin-bottom: 40px;
}

.version2 .imageBox .productImage, .version2 .whiteSection .featuredProducts .slide .productImage, .whiteSection .featuredProducts .version2 .slide .productImage, .version2 .mainContent .featuredProducts .slide .productImage, .mainContent .featuredProducts .version2 .slide .productImage, .version2 .productsContent .productBox .productImage, .productsContent .version2 .productBox .productImage {
  margin-bottom: 15px;
}

.version2 .imageBox .productImage .productMasking, .version2 .whiteSection .featuredProducts .slide .productImage .productMasking, .whiteSection .featuredProducts .version2 .slide .productImage .productMasking, .version2 .mainContent .featuredProducts .slide .productImage .productMasking, .mainContent .featuredProducts .version2 .slide .productImage .productMasking, .version2 .productsContent .productBox .productImage .productMasking, .productsContent .version2 .productBox .productImage .productMasking {
  background-color: transparent !important;
}

.version2 .imageBox .productImage .productMasking ul, .version2 .whiteSection .featuredProducts .slide .productImage .productMasking ul, .whiteSection .featuredProducts .version2 .slide .productImage .productMasking ul, .version2 .mainContent .featuredProducts .slide .productImage .productMasking ul, .mainContent .featuredProducts .version2 .slide .productImage .productMasking ul, .version2 .productsContent .productBox .productImage .productMasking ul, .productsContent .version2 .productBox .productImage .productMasking ul {
  height: 100%;
  align-items: flex-end;
  background: transparent;
  margin-top: -30px;
}

.version2 .imageBox .productImage .productMasking ul li:after, .version2 .whiteSection .featuredProducts .slide .productImage .productMasking ul li:after, .whiteSection .featuredProducts .version2 .slide .productImage .productMasking ul li:after, .version2 .mainContent .featuredProducts .slide .productImage .productMasking ul li:after, .mainContent .featuredProducts .version2 .slide .productImage .productMasking ul li:after, .version2 .productsContent .productBox .productImage .productMasking ul li:after, .productsContent .version2 .productBox .productImage .productMasking ul li:after {
  display: none;
}

.version2 .imageBox .productImage .productMasking ul li a, .version2 .whiteSection .featuredProducts .slide .productImage .productMasking ul li a, .whiteSection .featuredProducts .version2 .slide .productImage .productMasking ul li a, .version2 .mainContent .featuredProducts .slide .productImage .productMasking ul li a, .mainContent .featuredProducts .version2 .slide .productImage .productMasking ul li a, .version2 .productsContent .productBox .productImage .productMasking ul li a, .productsContent .version2 .productBox .productImage .productMasking ul li a {
  line-height: 45px;
  height: 45px;
  width: 45px;
  border: none;
  background-color: #ffffff;
}

.version2 .imageBox .productImage .productMasking ul li a i, .version2 .whiteSection .featuredProducts .slide .productImage .productMasking ul li a i, .whiteSection .featuredProducts .version2 .slide .productImage .productMasking ul li a i, .version2 .mainContent .featuredProducts .slide .productImage .productMasking ul li a i, .mainContent .featuredProducts .version2 .slide .productImage .productMasking ul li a i, .version2 .productsContent .productBox .productImage .productMasking ul li a i, .productsContent .version2 .productBox .productImage .productMasking ul li a i {
  color: #252525;
}

.version2 .imageBox .productImage .productMasking ul li a:hover, .version2 .whiteSection .featuredProducts .slide .productImage .productMasking ul li a:hover, .whiteSection .featuredProducts .version2 .slide .productImage .productMasking ul li a:hover, .version2 .mainContent .featuredProducts .slide .productImage .productMasking ul li a:hover, .mainContent .featuredProducts .version2 .slide .productImage .productMasking ul li a:hover, .version2 .productsContent .productBox .productImage .productMasking ul li a:hover, .productsContent .version2 .productBox .productImage .productMasking ul li a:hover {
  background: #47bac1;
}

.version2 .imageBox .productImage .productMasking ul li a:hover i, .version2 .whiteSection .featuredProducts .slide .productImage .productMasking ul li a:hover i, .whiteSection .featuredProducts .version2 .slide .productImage .productMasking ul li a:hover i, .version2 .mainContent .featuredProducts .slide .productImage .productMasking ul li a:hover i, .mainContent .featuredProducts .version2 .slide .productImage .productMasking ul li a:hover i, .version2 .productsContent .productBox .productImage .productMasking ul li a:hover i, .productsContent .version2 .productBox .productImage .productMasking ul li a:hover i {
  color: #ffffff;
}

.version2 .imageBox .productCaption h5, .version2 .whiteSection .featuredProducts .slide .productCaption h5, .whiteSection .featuredProducts .version2 .slide .productCaption h5, .version2 .mainContent .featuredProducts .slide .productCaption h5, .mainContent .featuredProducts .version2 .slide .productCaption h5, .version2 .productsContent .productBox .productCaption h5, .productsContent .version2 .productBox .productCaption h5 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 5px;
}

.version2 .imageBox .productCaption h5 a, .version2 .whiteSection .featuredProducts .slide .productCaption h5 a, .whiteSection .featuredProducts .version2 .slide .productCaption h5 a, .version2 .mainContent .featuredProducts .slide .productCaption h5 a, .mainContent .featuredProducts .version2 .slide .productCaption h5 a, .version2 .productsContent .productBox .productCaption h5 a, .productsContent .version2 .productBox .productCaption h5 a {
  color: #252525;
}

.version2 .imageBox:hover .productMasking, .version2 .whiteSection .featuredProducts .slide:hover .productMasking, .whiteSection .featuredProducts .version2 .slide:hover .productMasking, .version2 .mainContent .featuredProducts .slide:hover .productMasking, .mainContent .featuredProducts .version2 .slide:hover .productMasking, .version2 .productsContent .productBox:hover .productMasking, .productsContent .version2 .productBox:hover .productMasking {
  opacity: 1;
}

.version2 .imageBox:hover .productMasking ul, .version2 .whiteSection .featuredProducts .slide:hover .productMasking ul, .whiteSection .featuredProducts .version2 .slide:hover .productMasking ul, .version2 .mainContent .featuredProducts .slide:hover .productMasking ul, .mainContent .featuredProducts .version2 .slide:hover .productMasking ul, .version2 .productsContent .productBox:hover .productMasking ul, .productsContent .version2 .productBox:hover .productMasking ul {
  -webkit-animation: itemMove 0.3s linear;
  animation: itemMove 0.3s linear;
}

/*=== 3.2.4 FEATURES ===*/
.box {
  background-color: #f5f5f5;
  padding: 50px 20px;
  margin-bottom: 35px;
}

.box i {
  font-size: 48px;
  color: #47bac1;
}

.box h4 {
  margin: 20px 0 10px;
  font-size: 22px;
  font-weight: 400;
}

@media (min-width: 768px) {
  .box h4 {
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  .box h4 {
    font-size: 22px;
  }
}

.box span {
  font-size: 14px;
  color: #979797;
}

/*=== 3.2.5 INSTAGRAM SECTION ===*/
.instagramSection {
  width: 100%;
}

.instagramSection .image-gallery {
  width: 33.33%;
  display: inline-block;
  float: left;
}

@media (min-width: 768px) {
  .instagramSection .image-gallery {
    width: 16.66%;
  }
}

.instagramSection .image-gallery img {
  width: 100%;
}

/*================= 3.3 HOME V3 ==================*/
/*=== 3.3.1 DEAL SECTION ===*/
.dealSlider {
  margin-bottom: 80px;
}

.dealSlider .imageBox, .dealSlider .whiteSection .featuredProducts .slide, .whiteSection .featuredProducts .dealSlider .slide, .dealSlider .mainContent .featuredProducts .slide, .mainContent .featuredProducts .dealSlider .slide, .dealSlider .productsContent .productBox, .productsContent .dealSlider .productBox {
  border: 1px solid #d5d5d5;
  margin: 1px;
}

.dealSlider .productDeal {
  padding: 20px 15px;
}

.dealSlider .productDeal h3 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  text-transform: capitalize;
  display: inline-block;
}

@media (min-width: 768px) {
  .dealSlider .productDeal h3 {
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .dealSlider .productDeal h3 {
    font-size: 20px;
  }
}

.dealSlider .productDeal h3 span {
  color: #47bac1;
  font-weight: 700;
}

.dealSlider .productDeal .rating {
  display: inline-block;
  float: right;
  color: #d5d5d5;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.dealSlider .productCaption h3 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.dealSlider .productCaption h3 a {
  color: #252525;
}

.dealSlider .productCaption .btn-border {
  padding-left: 25px;
  padding-right: 25px;
  border-color: #47bac1;
  margin-bottom: 50px;
  border: 2px solid;
  color: #47bac1;
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 700;
  line-height: 36px;
}

.dealSlider .productCaption .btn-border i {
  padding-left: 8px;
  color: #47bac1;
}

.dealSlider .imageBox:hover .productDeal .rating, .dealSlider .whiteSection .featuredProducts .slide:hover .productDeal .rating, .whiteSection .featuredProducts .dealSlider .slide:hover .productDeal .rating, .dealSlider .mainContent .featuredProducts .slide:hover .productDeal .rating, .mainContent .featuredProducts .dealSlider .slide:hover .productDeal .rating, .dealSlider .productsContent .productBox:hover .productDeal .rating, .productsContent .dealSlider .productBox:hover .productDeal .rating {
  color: #47bac1;
}

.dealSlider .imageBox:hover .productCaption .btn-border, .dealSlider .whiteSection .featuredProducts .slide:hover .productCaption .btn-border, .whiteSection .featuredProducts .dealSlider .slide:hover .productCaption .btn-border, .dealSlider .mainContent .featuredProducts .slide:hover .productCaption .btn-border, .mainContent .featuredProducts .dealSlider .slide:hover .productCaption .btn-border, .dealSlider .productsContent .productBox:hover .productCaption .btn-border, .productsContent .dealSlider .productBox:hover .productCaption .btn-border {
  background-color: #47bac1;
  color: #ffffff;
}

.dealSlider .imageBox:hover .productCaption .btn-border i, .dealSlider .whiteSection .featuredProducts .slide:hover .productCaption .btn-border i, .whiteSection .featuredProducts .dealSlider .slide:hover .productCaption .btn-border i, .dealSlider .mainContent .featuredProducts .slide:hover .productCaption .btn-border i, .mainContent .featuredProducts .dealSlider .slide:hover .productCaption .btn-border i, .dealSlider .productsContent .productBox:hover .productCaption .btn-border i, .productsContent .dealSlider .productBox:hover .productCaption .btn-border i {
  color: #ffffff;
}

.dealSlider .imageBox:hover .productImage img, .dealSlider .whiteSection .featuredProducts .slide:hover .productImage img, .whiteSection .featuredProducts .dealSlider .slide:hover .productImage img, .dealSlider .mainContent .featuredProducts .slide:hover .productImage img, .mainContent .featuredProducts .dealSlider .slide:hover .productImage img, .dealSlider .productsContent .productBox:hover .productImage img, .productsContent .dealSlider .productBox:hover .productImage img {
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

/*=== 3.3.2 FEATURE PRODUCT SECTION  ===*/
.version3.featuredProducts {
  margin-bottom: 40px;
}

.tabCommon .nav.nav-tabs {
  flex-direction: row;
  justify-content: center;
  border-bottom: 3px solid #ddd;
}

@media (min-width: 768px) {
  .tabCommon .nav.nav-tabs {
    justify-content: flex-start;
  }
}

.tabCommon .nav.nav-tabs li {
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
}

@media (min-width: 768px) {
  .tabCommon .nav.nav-tabs li {
    margin-left: 0px;
    margin-right: 20px;
  }
}

.tabCommon .nav.nav-tabs li a {
  color: #252525;
  display: block;
  padding: 10px 0;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
}

@media (min-width: 768px) {
  .tabCommon .nav.nav-tabs li a {
    font-size: 16px;
  }
}

.tabCommon .nav.nav-tabs li a.active:before {
  content: '';
  width: 100%;
  height: 3px;
  bottom: -3px;
  left: 0;
  position: absolute;
  background: #47bac1;
}

/*=== 3.3.3 FEATURES ===*/
.version2 .box {
  background-color: transparent;
  border: 3px solid #e4e4e4;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.version2 .box:hover {
  border: 3px solid #47bac1;
}

/*=== 3.3.4 TESTIMONIAL SECTION ===*/
.testimonialSection {
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .testimonialSection {
    margin-bottom: 0;
  }
}

.testimonialSection .testimonialSlider button.slick-arrow {
  top: 100px;
  right: 50px;
  z-index: 55;
}

.testimonialSection .testimonialSlider button.slick-arrow:after {
  right: -3px;
  top: -1px;
}

.testimonialSection .testimonialSlider button.slick-arrow.slick-prev {
  right: 75px;
}

.testimonialSection .testimonialSlider button.slick-arrow:before {
  color: #d5d5d5;
}

.testimonial-inner {
  background-color: #f5f5f5;
  padding: 15px;
  width: 100%;
  float: left;
}

@media (min-width: 768px) {
  .testimonial-inner {
    padding: 50px;
  }
}

.testimonial-inner .testimonialImage {
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  width: 30%;
  float: left;
  padding: 30px 0;
  margin-right: 15px;
}

@media (min-width: 768px) {
  .testimonial-inner .testimonialImage {
    margin-right: 45px;
  }
}

.testimonial-inner .testimonialImage img {
  max-width: 100%;
  display: inline-block;
  width: auto;
  -webkit-transition-delay: 2s;
  transition-delay: 2s;
}

@media (min-width: 992px) {
  .testimonial-inner .testimonialText {
    padding-top: 50px;
  }
}

.testimonial-inner .testimonialText .product-rating {
  color: #47bac1;
  display: inline-block;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .testimonial-inner .testimonialText .product-rating i {
    font-size: 16px;
  }
}

.testimonial-inner .testimonial-content {
  border-top: 5px solid #e4e4e4;
  padding-top: 10px;
  display: table-cell;
  vertical-align: top;
}

@media (min-width: 768px) {
  .testimonial-inner .testimonial-content {
    padding-top: 20px;
  }
}

.testimonial-inner .testimonial-content p {
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .testimonial-inner .testimonial-content p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 20px;
  }
}

.testimonial-inner .testimonial-content .author-img {
  width: 59px;
  height: 55px;
  border-radius: 50%;
  float: left;
  margin-right: 15px;
}

.testimonial-inner .testimonial-content cite {
  font-size: 12px;
  margin-top: 22px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
}

@media (min-width: 768px) {
  .testimonial-inner .testimonial-content cite {
    font-size: 14px;
    margin-top: 20px;
  }
}

/*=== 3.3.5 LIGHT SECTION ===*/
.lightSection {
  background-color: #f0f0f0;
  height: 125px;
}

.lightSection .partnersLogoSlider.version2 .owl-nav {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  right: 0;
}

/*================= 3.4 HOME V4 ==================*/
/*=== 3.4.1 FEATURE COLLECTION ===*/
.featuredCollection.version2.version3 {
  margin-bottom: 40px;
}

.featuredCollection.version2.version3 .slide {
  background: transparent;
  height: auto;
  margin: 0;
}

.featuredCollection.version2.version3 .productImage {
  width: 100%;
  float: none;
}

.featuredCollection.version2.version3 .productImage1 {
  background-image: url("../img/home/featured-collection/product-img4.jpg");
  background-repeat: no-repeat;
  background-position: left center;
}

.featuredCollection.version2.version3 .productImage2 {
  background-image: url("../img/home/featured-collection/product-img5.jpg");
  background-repeat: no-repeat;
  background-position: left center;
}

.featuredCollection.version2.version3 .productCaption p {
  color: #666666;
}

.featuredCollection.version2.version3 .btn-border {
  border: 2px solid;
  color: #252525;
  border-color: #252525;
  margin-bottom: 30px;
  font-size: 13px;
  line-height: 36px;
}

.featuredCollection.version2.version3 .productCaption {
  width: 50%;
  position: absolute;
  bottom: 40px;
  right: 0;
  padding-right: 30px;
}

/*=== 3.4.2 CATEGORY SECTION ===*/
.category-content {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid #47bac1;
  width: 100%;
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  .category-content {
    margin-bottom: 80px;
  }
}

.category-top {
  padding: 10px;
}

.category-top .category-menu {
  width: 34%;
  display: inline-block;
}

@media (min-width: 768px) {
  .category-top .category-menu {
    width: 31%;
  }
}

.category-top .category-menu h2 {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 10px 0 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #47bac1;
}

@media (min-width: 768px) {
  .category-top .category-menu h2 {
    font-size: 22px;
    padding: 15px 0 25px;
  }
}

.category-menu ul {
  padding: 10px 0;
}

.category-menu ul li {
  padding: 5px 0;
}

@media (min-width: 768px) {
  .category-menu ul li {
    padding: 10px 0;
  }
}

.category-menu ul li a {
  color: #252525;
}

.category-menu ul li:hover a {
  color: #47bac1;
}

.category-Slider {
  width: 63%;
}

@media (min-width: 768px) {
  .category-Slider {
    width: 67%;
  }
}

.category-Slider .categorySlider img {
  width: 100%;
}

.category-Slider .categorySlider .slick-arrow {
  background: none;
  width: 40px;
  height: 60px;
  display: block;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 20px;
  line-height: 60px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.category-Slider .categorySlider .slick-arrow:hover {
  background: rgba(0, 0, 0, 0.8);
}

.category-Slider .categorySlider button.slick-prev {
  left: 0px;
  z-index: 55;
}

.category-Slider .categorySlider button.slick-prev:before {
  content: "\f104";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 20px;
  line-height: 60px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.category-Slider .categorySlider button.slick-next {
  right: 0;
}

.category-Slider .categorySlider button.slick-next:before {
  content: "\f105";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 20px;
  line-height: 60px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.category-bottom {
  width: 100%;
  padding: 3px;
}

@media (min-width: 768px) {
  .category-bottom {
    padding: 5px;
  }
}

.category-bottom .imageBox, .category-bottom .whiteSection .featuredProducts .slide, .whiteSection .featuredProducts .category-bottom .slide, .category-bottom .mainContent .featuredProducts .slide, .mainContent .featuredProducts .category-bottom .slide, .category-bottom .productsContent .productBox, .productsContent .category-bottom .productBox {
  margin: 0 3px 10px;
  display: inline-block;
  width: 30.67%;
}

@media (min-width: 768px) {
  .category-bottom .imageBox, .category-bottom .whiteSection .featuredProducts .slide, .whiteSection .featuredProducts .category-bottom .slide, .category-bottom .mainContent .featuredProducts .slide, .mainContent .featuredProducts .category-bottom .slide, .category-bottom .productsContent .productBox, .productsContent .category-bottom .productBox {
    width: auto;
    margin: 0 5px 10px;
  }
}

.category-bottom .imageBox .productImage .productMasking ul, .category-bottom .whiteSection .featuredProducts .slide .productImage .productMasking ul, .whiteSection .featuredProducts .category-bottom .slide .productImage .productMasking ul, .category-bottom .mainContent .featuredProducts .slide .productImage .productMasking ul, .mainContent .featuredProducts .category-bottom .slide .productImage .productMasking ul, .category-bottom .productsContent .productBox .productImage .productMasking ul, .productsContent .category-bottom .productBox .productImage .productMasking ul {
  width: 96px;
  height: 26px;
  left: 54%;
}

.category-bottom .imageBox .productImage .productMasking ul li, .category-bottom .whiteSection .featuredProducts .slide .productImage .productMasking ul li, .whiteSection .featuredProducts .category-bottom .slide .productImage .productMasking ul li, .category-bottom .mainContent .featuredProducts .slide .productImage .productMasking ul li, .mainContent .featuredProducts .category-bottom .slide .productImage .productMasking ul li, .category-bottom .productsContent .productBox .productImage .productMasking ul li, .productsContent .category-bottom .productBox .productImage .productMasking ul li {
  padding: 0 2px;
}

.category-bottom .imageBox .productImage .productMasking ul li a, .category-bottom .whiteSection .featuredProducts .slide .productImage .productMasking ul li a, .whiteSection .featuredProducts .category-bottom .slide .productImage .productMasking ul li a, .category-bottom .mainContent .featuredProducts .slide .productImage .productMasking ul li a, .mainContent .featuredProducts .category-bottom .slide .productImage .productMasking ul li a, .category-bottom .productsContent .productBox .productImage .productMasking ul li a, .productsContent .category-bottom .productBox .productImage .productMasking ul li a {
  width: 26px;
  height: 26px;
  border: 2px solid rgba(255, 255, 255, 0.6);
  line-height: 22px;
}

.category-bottom .imageBox .productImage .productMasking ul li a i, .category-bottom .whiteSection .featuredProducts .slide .productImage .productMasking ul li a i, .whiteSection .featuredProducts .category-bottom .slide .productImage .productMasking ul li a i, .category-bottom .mainContent .featuredProducts .slide .productImage .productMasking ul li a i, .mainContent .featuredProducts .category-bottom .slide .productImage .productMasking ul li a i, .category-bottom .productsContent .productBox .productImage .productMasking ul li a i, .productsContent .category-bottom .productBox .productImage .productMasking ul li a i {
  font-size: 12px;
}

.category-bottom .imageBox .productCaption h3, .category-bottom .whiteSection .featuredProducts .slide .productCaption h3, .whiteSection .featuredProducts .category-bottom .slide .productCaption h3, .category-bottom .mainContent .featuredProducts .slide .productCaption h3, .mainContent .featuredProducts .category-bottom .slide .productCaption h3, .category-bottom .productsContent .productBox .productCaption h3, .productsContent .category-bottom .productBox .productCaption h3 {
  font-size: 18px;
}

.sticker {
  background: #47bac1;
  padding: 0 3px;
  font-size: 12px;
  color: #ffffff;
  font-weight: 700;
  position: absolute;
  top: 10px;
  left: 10px;
  text-transform: uppercase;
}

/*================= 3.4 HOME V5 ==================*/
.version5 .main-wrapper {
  overflow-x: hidden;
}

.home-v5 {
  padding: 0px !important;
}

.home-v5 .media h4, .home-v5 .card h4 {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 400;
}

@media (min-width: 992px) {
  .home-v5 .media h4, .home-v5 .card h4 {
    font-size: 22px;
    margin-bottom: 20px;
  }
}

.home-v5 .media p, .home-v5 .card p {
  color: #797979;
  font-size: 13px;
  line-height: 26px;
  margin: 0px;
}

@media (min-width: 992px) {
  .home-v5 .media p, .home-v5 .card p {
    font-size: 14px;
  }
}

.home-v5 .icon-box {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 56px;
  border-radius: 50%;
  color: #47bac1;
  text-align: center;
  font-size: 24px;
  border: 2px solid rgba(37, 37, 37, 0.1);
}

.home-v5 .promo-video-holder {
  border: 2px solid #ffffff;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 50px;
}

.home-v5 .promo-video {
  position: relative;
  width: 100%;
  float: none;
  overflow: hidden;
  max-height: 400px;
  padding-top: 100%;
}

.home-v5 .promo-video:before {
  background-color: rgba(10, 35, 59, 0);
}

@media (min-width: 768px) {
  .home-v5 .promo-video {
    max-height: 500px;
    width: 100%;
    float: left;
    position: relative;
    margin: 0px;
    padding-top: 80%;
  }
}

@media (min-width: 992px) {
  .home-v5 .promo-video {
    padding-top: 62.54%;
  }
}

.home-v5 .promo-video .video-button a {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.home-v5 .promo-video .video-button a iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.home-v5 .promo-video .video-button a i {
  position: relative;
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 30px;
  background: #47bac1;
  border-radius: 50%;
  display: inline-block;
  color: #ffffff;
  padding-left: 10px;
  text-align: center;
  border: 2px solid #47bac1;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}

.home-v5 .promo-video .video-button a:hover i {
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.home-v5 .sectionTitle h2 {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 15px;
}

.home-v5 .sectionTitle h2:after {
  display: none;
}

@media (min-width: 992px) {
  .home-v5 .sectionTitle h2 {
    font-size: 32px;
    line-height: 40px;
  }
}

.home-v5 .sectionTitle p {
  padding-bottom: 50px;
  font-size: 13px;
  line-height: 26px;
  margin: 0;
  color: #797979;
}

@media (min-width: 992px) {
  .home-v5 .sectionTitle p {
    font-size: 14px;
  }
}

.home-v5 .aboutSection {
  padding: 90px 0;
  margin: 0px;
}

.home-v5 .aboutSection.bg-image:before {
  background-color: rgba(255, 255, 255, 0.9);
}

.home-v5 .aboutSection a.primary-btn {
  margin-top: 15px;
  font-size: 13px;
  width: 148px;
  height: 40px;
  line-height: 40px;
  background: #47bac1;
  color: #ffffff;
  padding: 0;
}

@media (min-width: 992px) {
  .home-v5 .aboutSection a.primary-btn {
    width: 178px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}

.home-v5 .aboutSection a.primary-btn i {
  padding-left: 8px;
}

.home-v5 .featureSection {
  padding: 90px 0;
}

.home-v5 .featureSection .media .icon-box {
  margin-right: 20px;
}

@media (min-width: 768px) {
  .home-v5 .featureSection .media .icon-box {
    margin-right: 30px;
  }
}

.home-v5 .cta {
  height: 250px;
}

@media (min-width: 768px) {
  .home-v5 .cta {
    height: 350px;
  }
}

@media (min-width: 992px) {
  .home-v5 .cta {
    height: 450px;
  }
}

@media (min-width: 1200px) {
  .home-v5 .cta {
    height: 500px;
  }
}

.home-v5 .cta .video-holder {
  text-align: center;
  color: #ffffff;
}

.home-v5 .cta .video-holder h1 {
  color: #ffffff;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .home-v5 .cta .video-holder h1 {
    font-size: 32px;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .home-v5 .cta .video-holder h1 {
    font-size: 40px;
    letter-spacing: 2px;
    margin-bottom: 25px;
  }
}

.home-v5 .cta .video-holder p {
  color: inherit;
  font-size: 13px;
  line-height: 20px;
}

@media (min-width: 768px) {
  .home-v5 .cta .video-holder p {
    font-size: 14px;
    line-height: 26px;
  }
}

.home-v5 .cta .video-holder a {
  width: 50px;
  height: 50px;
  display: inline-block;
  line-height: 44px;
  font-size: 18px;
  border: 3px solid #ffffff;
  border-radius: 50%;
  color: #ffffff;
  margin-top: 8px;
}

@media (min-width: 768px) {
  .home-v5 .cta .video-holder a {
    line-height: 64px;
    font-size: 24px;
    width: 70px;
    height: 70px;
    margin-top: 15px;
  }
}

.home-v5 .cta .video-holder a i {
  padding-left: 5px;
}

.home-v5 .moreFeatures {
  padding: 90px 0;
}

.home-v5 .testimonial-section {
  padding: 90px 0;
}

.home-v5 .testimonial-section:before {
  background: rgba(245, 245, 245, 0.92);
}

.home-v5 .testimonial-section .card {
  background: transparent;
  border: none;
  border-radius: 0px;
}

.home-v5 .testimonial-section .card .image-box {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}

.home-v5 .testimonial-section .card .card-title {
  margin-bottom: 05px;
}

.home-v5 .testimonial-section .card small {
  color: #47bac1;
  display: inline-block;
  font-size: 13px;
}

.home-v5 .testimonial-section .card p {
  margin-top: 20px;
}

.home-v5 .accessories {
  padding: 90px 0;
}

.home-v5 .accessories .sectionTitle p {
  padding-bottom: 25px;
}

.home-v5 .accessories .featuredProducts {
  margin-bottom: 0px;
}

.home-v5 .accessories .featuredProducts .slide {
  margin-bottom: 0px;
  margin-top: 25px;
  text-align: center;
}

@media (min-width: 768px) {
  .home-v5 .accessories .featuredProducts .slide {
    text-align: left;
  }
}

.home-v5 .accessories .featuredProducts .slide .productCaption h4 {
  font-weight: 400;
}

.home-v5 .accessories .featuredProducts .slide h3 {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .home-v5 .accessories .featuredProducts .slide h3 {
    margin-bottom: 0px;
  }
}

.home-v5 .gallery {
  padding: 90px 0;
  background: #f5f5f8;
}

.home-v5 .gallery .productGallery {
  margin-bottom: 0px;
}

.home-v5 .gallery .productGallery .slide {
  margin-bottom: 0px;
}

.home-v5 .gallery .productGallery .slide .productImage {
  margin-bottom: 0px;
}

.home-v5 .gallery .productGallery .slide .productMasking a {
  color: #fff;
  font-size: 16px;
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  display: inline-block;
  text-align: center;
  line-height: 46px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.home-v5 .gallery .productGallery button.slick-arrow {
  top: 50%;
}

.home-v5 .gallery .productGallery button.slick-arrow.slick-prev {
  left: -25px;
  right: auto;
}

.home-v5 .gallery .productGallery button.slick-arrow.slick-prev:after {
  display: none;
}

.home-v5 .gallery .productGallery button.slick-arrow.slick-next {
  right: -25px;
}

.home-v5 .gallery .productGallery button.slick-arrow.slick-prev:before, .home-v5 .gallery .productGallery button.slick-arrow.slick-next:before {
  font-size: 16px;
}

.home-v5 .order {
  background: #47bac1;
  padding: 80px 0;
}

.home-v5 .order h2 {
  color: #ffffff;
  font-weight: 700;
}

.home-v5 .order p {
  padding-bottom: 30px;
  color: #ffffff;
}

.home-v5 .order .order-btn {
  height: 40px;
  width: 160px;
  font-size: 14px;
  line-height: 40px;
  color: #252525;
  background: #ffffff;
  padding: 0;
}

@media (min-width: 992px) {
  .home-v5 .order .order-btn {
    height: 50px;
    width: 178px;
    line-height: 50px;
  }
}

.home-v5 .order .order-btn i {
  padding-left: 8px;
  font-size: 14px;
}

/*=== 4.1 FOOTER V1 ===*/
/*=== 4.1.1 MAIN FOOTER ===*/
.footer {
  display: block;
  width: 100%;
  padding: 38px 0;
  background-color: #252525;
  border-top: 8px solid #47bac1;
}

@media (max-width: 479px) {
  .footer .footerLink {
    text-align: center;
    margin-bottom: 25px;
  }
}

@media (min-width: 480px) {
  .footer .footerLink {
    text-align: center;
    margin-bottom: 25px;
  }
}

@media (min-width: 768px) {
  .footer .footerLink {
    text-align: left;
    margin-bottom: 0;
  }
}

.footer .footerLink h5 {
  letter-spacing: 1px;
  color: #5f5f5f;
  text-transform: uppercase;
  margin-bottom: 13px;
}

.footer .footerLink ul li {
  color: #acacac;
  font-size: 12px;
  line-height: 20px;
  padding-left: 5px;
  padding-right: 5px;
}

.footer .footerLink ul li a {
  color: #acacac;
  font-size: 12px;
  line-height: 20px;
}

.footer .footerLink ul li a:hover {
  color: rgba(255, 255, 255, 0.5);
}

.footer .newsletter {
  display: block;
  text-align: center;
}

@media (min-width: 480px) {
  .footer .newsletter {
    float: none;
    margin: 0 auto;
    margin-bottom: 25px;
    width: 75%;
  }
}

@media (min-width: 768px) {
  .footer .newsletter {
    float: right;
    margin-bottom: 0;
    width: 90%;
  }
}

@media (min-width: 992px) {
  .footer .newsletter {
    width: 75%;
  }
}

.footer .newsletter h4 {
  color: #5f5f5f;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.footer .newsletter h3 {
  color: #acacac;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 24px;
}

.footer .newsletter p {
  color: #acacac;
}

.footer .newsletter .input-group {
  display: block;
  width: 100%;
}

.footer .newsletter .input-group .form-control, .footer .newsletter .input-group .input-group-addon {
  float: left;
  margin-right: 2%;
  border-radius: 0;
  border: none;
  background-color: #3b3b3b;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  font-size: 13px;
  color: #999999;
}

.footer .newsletter .input-group .form-control:focus, .footer .newsletter .input-group .input-group-addon:focus {
  box-shadow: none;
}

@media (max-width: 479px) {
  .footer .newsletter .input-group .form-control, .footer .newsletter .input-group .input-group-addon {
    width: 73%;
  }
}

@media (min-width: 480px) {
  .footer .newsletter .input-group .form-control, .footer .newsletter .input-group .input-group-addon {
    width: 73%;
  }
}

@media (min-width: 768px) {
  .footer .newsletter .input-group .form-control, .footer .newsletter .input-group .input-group-addon {
    width: 63%;
  }
}

@media (min-width: 992px) {
  .footer .newsletter .input-group .form-control, .footer .newsletter .input-group .input-group-addon {
    width: 65%;
    margin-right: 5%;
  }
}

.footer .newsletter .input-group .input-group-addon {
  display: inline-block;
  margin-right: 0;
  height: 40;
  padding: 0;
  text-align: center;
  line-height: 36px;
  border: 3px solid #47bac1;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 479px) {
  .footer .newsletter .input-group .input-group-addon {
    width: 25%;
  }
}

@media (min-width: 480px) {
  .footer .newsletter .input-group .input-group-addon {
    width: 25%;
  }
}

@media (min-width: 768px) {
  .footer .newsletter .input-group .input-group-addon {
    width: 35%;
  }
}

@media (min-width: 992px) {
  .footer .newsletter .input-group .input-group-addon {
    width: 27%;
  }
}

.footer .newsletter .input-group .input-group-addon i {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #47bac1;
}

@media (min-width: 480px) {
  .footer .newsletter .input-group .input-group-addon i {
    margin-left: 7px;
  }
}

@media (min-width: 768px) {
  .footer .newsletter .input-group .input-group-addon i {
    margin-left: 3px;
  }
}

@media (min-width: 992px) {
  .footer .newsletter .input-group .input-group-addon i {
    margin-left: 7px;
  }
}

.footer .newsletter .input-group .input-group-addon:hover {
  background-color: #47bac1;
  border-color: #47bac1;
  color: #ffffff;
}

.footer .newsletter .input-group .input-group-addon:hover i {
  color: #ffffff;
}

.footer .footerLink .list-inline {
  margin-top: 25px;
}

.footer .footerLink .list-inline li a {
  font-size: 17px;
}

/*=== 4.1.2 COPY RIGHT ===*/
.copyRight {
  display: block;
  width: 100%;
  min-height: 53px;
  background-color: #101010;
}

.copyRight p {
  color: #acacac;
  margin-bottom: 0;
  font-size: 13px;
  padding-top: 17px;
}

@media (max-width: 479px) {
  .copyRight p {
    text-align: center;
    font-size: 11px;
  }
}

@media (min-width: 480px) {
  .copyRight p {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .copyRight p {
    text-align: left;
  }
}

.copyRight p a {
  color: inherit;
}

@media (max-width: 479px) {
  .copyRight ul {
    text-align: center;
  }
}

@media (min-width: 480px) {
  .copyRight ul {
    text-align: center;
  }
}

@media (min-width: 768px) {
  .copyRight ul {
    text-align: right;
  }
}

.copyRight ul li {
  line-height: 53px;
  padding-left: 5px;
  padding-right: 5px;
}

.copyRight ul li a {
  display: block;
  line-height: 53px;
  font-size: 14px;
}

/*=== 4.2 FOOTER V2 ===*/
/*=== 4.2.1 MAIN FOOTER ===*/
.footer.version2 {
  background-color: #f5f5f5;
}

.footer.version2 .footerLink ul li {
  color: #252525;
}

.footer.version2 .footerLink ul li a {
  color: #252525;
}

.footer.version2 .footerLink ul li a:hover {
  color: #000;
}

.footer.version2 .footerLink .list-inline li a {
  color: #8d8d8d;
}

.footer.version2 .newsletter h3 {
  color: #252525;
}

.footer.version2 .newsletter p {
  color: #252525;
}

.footer.version2 .newsletter .input-group .form-control, .footer.version2 .newsletter .input-group .input-group-addon {
  background-color: #e9e9e9;
}

.footer.version2 .newsletter .input-group .input-group-addon {
  color: #252525;
}

.footer.version2 .newsletter .input-group .input-group-addon:hover {
  background-color: #47bac1;
  border-color: #47bac1;
  color: #ffffff;
}

.footer.version2 .newsletter .input-group .input-group-addon:hover i {
  color: #ffffff;
}

/*=== 4.2.2 COPY RIGHT ===*/
.copyRight.version2 {
  background-color: #ececec;
}

.copyRight.version2 p {
  color: #252525;
}

.copyRight.version2 p a {
  color: #252525;
}

.copyRight.version2 p a:hover {
  color: #47bac1;
}

/*=== 5. FORM ===*/
/*=== 5.1 FORM COMMON ===*/
.form-field .form-group .form-control, .form-body .panel .panel-body .form-group .form-control, .signUp .panel .panel-body .form-group .form-control, .lostPassword .panel .panel-body .form-group .form-control, .logIn .panel .panel-body .form-group .form-control, .commentsForm .form-group .form-control, #login .modal-dialog .modal-content .modal-body .form-group .form-control, #signup .modal-dialog .modal-content .modal-body .form-group .form-control, .stepsPage form .form-group .form-control, .profile .form-horizontal .form-group .form-control {
  border-radius: 0;
  background-color: #f0f0f0;
  border: none;
  height: 44px;
  line-height: 44px;
  box-shadow: none;
}

.form-field .form-group .form-control:focus, .form-body .panel .panel-body .form-group .form-control:focus, .signUp .panel .panel-body .form-group .form-control:focus, .lostPassword .panel .panel-body .form-group .form-control:focus, .logIn .panel .panel-body .form-group .form-control:focus, .commentsForm .form-group .form-control:focus, #login .modal-dialog .modal-content .modal-body .form-group .form-control:focus, #signup .modal-dialog .modal-content .modal-body .form-group .form-control:focus, .stepsPage form .form-group .form-control:focus, .profile .form-horizontal .form-group .form-control:focus {
  box-shadow: none;
}

.form-field, .form-body .panel .panel-body, .signUp .panel .panel-body, .lostPassword .panel .panel-body, .logIn .panel .panel-body, .commentsForm, #login .modal-dialog .modal-content .modal-body, #signup .modal-dialog .modal-content .modal-body {
  padding: 15px 30px;
}

.form-field .form-group, .form-body .panel .panel-body .form-group, .signUp .panel .panel-body .form-group, .lostPassword .panel .panel-body .form-group, .logIn .panel .panel-body .form-group, .commentsForm .form-group, #login .modal-dialog .modal-content .modal-body .form-group, #signup .modal-dialog .modal-content .modal-body .form-group {
  margin-bottom: 30px;
}

.form-field .form-group label, .form-body .panel .panel-body .form-group label, .signUp .panel .panel-body .form-group label, .lostPassword .panel .panel-body .form-group label, .logIn .panel .panel-body .form-group label, .commentsForm .form-group label, #login .modal-dialog .modal-content .modal-body .form-group label, #signup .modal-dialog .modal-content .modal-body .form-group label {
  font-size: 13px;
}

.form-field .checkbox, .form-body .panel .panel-body .checkbox, .signUp .panel .panel-body .checkbox, .lostPassword .panel .panel-body .checkbox, .logIn .panel .panel-body .checkbox, .commentsForm .checkbox, #login .modal-dialog .modal-content .modal-body .checkbox, #signup .modal-dialog .modal-content .modal-body .checkbox {
  margin: 0 0 30px 0;
}

.form-field .checkbox label, .form-body .panel .panel-body .checkbox label, .signUp .panel .panel-body .checkbox label, .lostPassword .panel .panel-body .checkbox label, .logIn .panel .panel-body .checkbox label, .commentsForm .checkbox label, #login .modal-dialog .modal-content .modal-body .checkbox label, #signup .modal-dialog .modal-content .modal-body .checkbox label {
  font-size: 13px;
}

.form-field .btn-primary, .form-body .panel .panel-body .btn-primary, .signUp .panel .panel-body .btn-primary, .lostPassword .panel .panel-body .btn-primary, .logIn .panel .panel-body .btn-primary, .commentsForm .btn-primary, #login .modal-dialog .modal-content .modal-body .btn-primary, #signup .modal-dialog .modal-content .modal-body .btn-primary {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form-field .btn-primary:hover, .form-body .panel .panel-body .btn-primary:hover, .signUp .panel .panel-body .btn-primary:hover, .lostPassword .panel .panel-body .btn-primary:hover, .logIn .panel .panel-body .btn-primary:hover, .commentsForm .btn-primary:hover, #login .modal-dialog .modal-content .modal-body .btn-primary:hover, #signup .modal-dialog .modal-content .modal-body .btn-primary:hover {
  opacity: .9;
}

.form-field .btn-link, .form-body .panel .panel-body .btn-link, .signUp .panel .panel-body .btn-link, .lostPassword .panel .panel-body .btn-link, .logIn .panel .panel-body .btn-link, .commentsForm .btn-link, #login .modal-dialog .modal-content .modal-body .btn-link, #signup .modal-dialog .modal-content .modal-body .btn-link {
  color: #47bac1;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.form-field .btn-link:hover, .form-body .panel .panel-body .btn-link:hover, .signUp .panel .panel-body .btn-link:hover, .lostPassword .panel .panel-body .btn-link:hover, .logIn .panel .panel-body .btn-link:hover, .commentsForm .btn-link:hover, #login .modal-dialog .modal-content .modal-body .btn-link:hover, #signup .modal-dialog .modal-content .modal-body .btn-link:hover {
  text-decoration: none;
  color: #4dc8cf;
}

.form-body .panel, .signUp .panel, .lostPassword .panel, .logIn .panel {
  border-radius: 0;
  border: none;
}

.form-body .panel .panel-heading, .signUp .panel .panel-heading, .lostPassword .panel .panel-heading, .logIn .panel .panel-heading {
  background-color: #47bac1;
  border-radius: 0;
  padding: 0 15px;
  height: 60px;
}

.form-body .panel .panel-heading h3, .signUp .panel .panel-heading h3, .lostPassword .panel .panel-heading h3, .logIn .panel .panel-heading h3 {
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 0;
  font-weight: 400;
  color: #ffffff;
  line-height: 60px;
}

.form-body .panel .panel-body, .signUp .panel .panel-body, .lostPassword .panel .panel-body, .logIn .panel .panel-body {
  border: 3px solid #f0f0f0;
  border-top: none;
}

.form-body .panel .panel-body .btn-link span, .signUp .panel .panel-body .btn-link span, .lostPassword .panel .panel-body .btn-link span, .logIn .panel .panel-body .btn-link span {
  color: #797979;
}

.form-body .panel .panel-body .form-check .form-check-label, .signUp .panel .panel-body .form-check .form-check-label, .lostPassword .panel .panel-body .form-check .form-check-label, .logIn .panel .panel-body .form-check .form-check-label {
  font-size: 13px;
  font-weight: 400;
}

.form-body .panel .panel-body .form-check .form-check-label:before, .signUp .panel .panel-body .form-check .form-check-label:before, .lostPassword .panel .panel-body .form-check .form-check-label:before, .logIn .panel .panel-body .form-check .form-check-label:before {
  margin-top: -4px;
  background: #f0f0f0;
  border: none;
}

.form-body .panel .panel-body .checkbox-custom:checked + .checkbox-custom-label:before, .signUp .panel .panel-body .checkbox-custom:checked + .checkbox-custom-label:before, .lostPassword .panel .panel-body .checkbox-custom:checked + .checkbox-custom-label:before, .logIn .panel .panel-body .checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\f00c";
  font-family: 'FontAwesome';
  color: #ffffff;
  background: #47bac1;
  border: 0px solid #47bac1;
}

/*=== 5.2 FORM SIGN UP ===*/
@media (min-width: 480px) {
  .signUp {
    padding: 45px 0 25px 0;
  }
}

@media (min-width: 480px) {
  .signUp {
    padding: 90px 0 70px 0;
  }
}

.signUp .btn-primary {
  letter-spacing: 2px;
  font-weight: 700;
}

/*=== 5.3 FORM LOST PASSWORD ===*/
.lostPassword .panel-body {
  padding: 50px 30px !important;
}

.lostPassword .help-block {
  color: #252525;
  font-size: 13px;
  line-height: 22px;
  margin: 0 0 20px 0;
}

.lostPassword .btn {
  letter-spacing: 2px;
  font-weight: 700;
}

@media (min-width: 480px) {
  .lostPassword {
    padding: 45px 0 25px 0;
  }
}

@media (min-width: 480px) {
  .lostPassword {
    padding: 90px 0 70px 0;
  }
}

/*=== 5.4 FORM LOG IN ===*/
.logIn .btn-default {
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
  letter-spacing: 1px;
}

.logIn .btn-default i {
  margin-right: 5px;
}

.logIn .btn-default:hover, .logIn .btn-default:active, .logIn .btn-default:focus {
  opacity: .9;
  color: #ffffff;
}

@media (max-width: 479px) {
  .logIn .btn-default {
    width: 100%;
    font-size: 11px;
  }
}

@media (min-width: 480px) {
  .logIn .btn-default {
    width: 48%;
    font-size: 11px;
  }
}

@media (min-width: 992px) {
  .logIn .btn-default {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .logIn .btn-default {
    font-size: 16px;
  }
}

.logIn .btn.btn-block {
  width: 100%;
}

.logIn .social-login {
  margin: 30px 0 20px;
  min-height: 45px;
}

.logIn .social-login .btn.btn-social {
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
  max-width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}

.logIn .btn-primary {
  color: #ffffff;
}

@media (min-width: 480px) {
  .logIn {
    padding: 45px 0 25px 0;
  }
}

@media (min-width: 768px) {
  .logIn {
    padding: 90px 0 70px 0;
  }
}

.logIn .checkbox input[type=checkbox],
.logIn .checkbox-inline input[type=checkbox],
.logIn .radio input[type=radio],
.logIn .radio-inline input[type=radio] {
  margin-left: -21px;
}

/*=== 5.5 BLOG SINGLE COMMENTS FORM ===*/
@media (min-width: 480px) {
  .commentsForm {
    padding: 0 0 90px 0;
  }
}

@media (min-width: 768px) {
  .commentsForm {
    padding: 0 0 30px 0;
  }
}

.commentsForm textarea.form-control {
  height: 150px !important;
}

@media (max-width: 479px) {
  .commentsForm .btn-primary {
    width: 100%;
  }
}

@media (min-width: 480px) {
  .commentsForm .btn-primary {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .commentsForm .btn-primary {
    width: 178px;
  }
}

/*=== 5.6 STEPS PAGE FORM ===*/
.stepsPage form {
  margin: 0 -15px;
}

.stepsPage form .form-group {
  margin-bottom: 0px;
}

.stepsPage form .form-group label {
  font-size: 13px;
  display: block;
  line-height: 1;
}

.stepsPage form .form-group .selectOptions {
  margin-bottom: 30px;
}

@media (min-width: 768px) {
  .stepsPage form .form-group .selectOptions {
    margin-bottom: 0;
  }
}

.stepsPage form .form-group .sbHolder {
  border: none;
}

.stepsPage form .form-group .sbSelector {
  background-color: #f0f0f0;
  position: relative;
}

.stepsPage form .form-group .sbSelector:after {
  content: '\f0d7';
  font-family: FontAwesome;
  position: absolute;
  right: 15px;
}

.stepsPage form .form-group textarea.form-control {
  height: 150px;
}

.stepsPage form .checkboxArea {
  float: left;
  margin: 13px 0 50px 0;
}

.stepsPage form .checkboxArea input[type=radio]:not(old) {
  margin: 0;
  padding: 0;
  font-size: 10px;
  opacity: 0;
}

.stepsPage form .checkboxArea input[type=radio]:not(old) + label {
  display: inline-block;
  margin-left: -20px;
  line-height: 20px;
  font-weight: 400;
  font-size: 16px;
}

.stepsPage form .checkboxArea input[type=radio]:not(old) + label span {
  background-color: #ffffff;
  border: 1px solid #d0d0d0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  display: inline-block;
  height: 20px;
  margin: 0 10px 0 0;
  vertical-align: middle;
  width: 20px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.stepsPage form .checkboxArea input[type=radio]:not(old):checked + label span {
  background-color: #47bac1;
  border: 1px solid #47bac1;
  border-radius: 50%;
  position: relative;
}

.stepsPage form .checkboxArea input[type=radio]:not(old):checked + label span:before {
  color: #ffffff;
  content: "\f00c";
  font-family: 'FontAwesome';
  display: block;
  font-size: 12px;
  font-weight: normal;
  height: 20px;
  line-height: 17px;
  text-align: center;
  width: 20px;
  position: absolute;
  left: -1px;
  top: 1px;
}

.stepsPage form .checkboxArea small {
  display: block;
  margin-left: 32px;
  line-height: 24px;
  font-size: 13px;
  color: #797979;
}

/*=== 6. MODAL ===*/
/*=== 6.1 LOGIN MODAL ===*/
#login .modal-dialog .modal-content, #signup .modal-dialog .modal-content, .quick-view .modal-dialog .modal-content, .quick-view-drone .modal-dialog .modal-content {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  box-shadow: none;
  border: none;
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-body, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-body, .singleProduct .media .media-body {
  overflow: visible;
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-body h3, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-body h3, .singleProduct .media .media-body h3 {
  font-weight: 700;
  margin-bottom: 7px;
  letter-spacing: 1px;
  font-size: 24px;
  color: #797979;
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-body h3, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-body h3, .singleProduct .media .media-body h3 {
  margin-bottom: 26px;
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-body p, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-body p, .singleProduct .media .media-body p {
  font-size: font-size3px;
  line-height: 24px;
  color: #797979;
  margin-bottom: 37px;
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-body .quick-drop, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-body .quick-drop, .singleProduct .media .media-body .quick-drop {
  margin: 0 12px 38px 0;
  display: inline-block;
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-body .btn-area, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-body .btn-area, .singleProduct .media .media-body .btn-area {
  display: block;
  width: 100%;
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-body .btn-area .btn-primary, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-body .btn-area .btn-primary, .singleProduct .media .media-body .btn-area .btn-primary {
  padding: 0 30px;
  font-weight: 400;
  width: 196px;
  height: 55px;
  line-height: 55px;
  font-size: 13px;
  text-align: left;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-body .btn-area .btn-primary i, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-body .btn-area .btn-primary i, .singleProduct .media .media-body .btn-area .btn-primary i {
  position: absolute;
  right: 20px;
  top: 16px;
  font-size: 22px;
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-body .btn-area .btn-primary:hover, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-body .btn-area .btn-primary:hover, .singleProduct .media .media-body .btn-area .btn-primary:hover {
  background: rgba(71, 186, 193, 0.8) !important;
}

#login .modal-dialog, #signup .modal-dialog {
  margin: 30px auto 0 auto;
}

#login .modal-dialog .modal-content .modal-header, #signup .modal-dialog .modal-content .modal-header {
  background-color: #47bac1;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

#login .modal-dialog .modal-content .modal-header .modal-title, #signup .modal-dialog .modal-content .modal-header .modal-title {
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
}

#login .modal-dialog .modal-content .modal-header .close, #signup .modal-dialog .modal-content .modal-header .close {
  opacity: 1;
  color: #ffffff;
  position: absolute;
  right: 0px;
  top: 0;
  font-size: 30px;
  cursor: pointer;
  padding: 0px 10px 5px;
  transform: translate(-15px, 15px);
}

#login .modal-dialog .modal-content .modal-header .close:hover, #signup .modal-dialog .modal-content .modal-header .close:hover, #login .modal-dialog .modal-content .modal-header .close:focus, #signup .modal-dialog .modal-content .modal-header .close:focus {
  opacity: .6;
  outline: none;
}

@media (max-width: 479px) {
  #login .modal-dialog, #signup .modal-dialog {
    width: 270px;
  }
}

@media (min-width: 480px) {
  #login .modal-dialog, #signup .modal-dialog {
    width: 430px;
  }
}

/*=== 6.2 PORDUCT QUICK VIEW MODAL ===*/
@media (max-width: 479px) {
  .quick-view .modal-dialog, .quick-view-drone .modal-dialog {
    max-width: 280px;
    margin: 0 auto;
  }
}

@media (min-width: 480px) {
  .quick-view .modal-dialog, .quick-view-drone .modal-dialog {
    max-width: 450px;
    margin: 0 auto;
  }
}

@media (min-width: 768px) {
  .quick-view .modal-dialog, .quick-view-drone .modal-dialog {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .quick-view .modal-dialog, .quick-view-drone .modal-dialog {
    max-width: 950px;
  }
}

@media (min-width: 1200px) {
  .quick-view .modal-dialog, .quick-view-drone .modal-dialog {
    max-width: 1030px;
  }
}

.quick-view .modal-dialog .modal-content .modal-body, .quick-view-drone .modal-dialog .modal-content .modal-body {
  padding: 30px;
}

.quick-view .modal-dialog .modal-content .modal-body .close, .quick-view-drone .modal-dialog .modal-content .modal-body .close {
  font-size: 25px;
  margin: 0;
  position: absolute;
  top: -5px;
  right: 0px;
  padding: 5px 10px;
  opacity: .2;
  font-weight: 300;
}

.quick-view .modal-dialog .modal-content .modal-body .close:hover, .quick-view .modal-dialog .modal-content .modal-body .close:focus, .quick-view-drone .modal-dialog .modal-content .modal-body .close:hover, .quick-view-drone .modal-dialog .modal-content .modal-body .close:focus {
  outline: none;
}

@media (min-width: 768px) {
  .quick-view .modal-dialog .modal-content .modal-body .close, .quick-view-drone .modal-dialog .modal-content .modal-body .close {
    font-size: 34px;
  }
}

@media (min-width: 992px) {
  .quick-view .modal-dialog .modal-content .modal-body .close, .quick-view-drone .modal-dialog .modal-content .modal-body .close {
    font-size: 55px;
  }
}

.quick-view .modal-dialog .modal-content .modal-body .media, .quick-view-drone .modal-dialog .modal-content .modal-body .media {
  margin-top: 0;
  margin-right: 20px;
}

@media (min-width: 992px) {
  .quick-view .modal-dialog .modal-content .modal-body .media, .quick-view-drone .modal-dialog .modal-content .modal-body .media {
    margin-right: 0;
  }
}

@media (min-width: 480px) {
  .quick-view .modal-dialog .modal-content .modal-body .media .media-left, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-left {
    width: 100%;
    padding-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .quick-view .modal-dialog .modal-content .modal-body .media .media-left, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-left {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .quick-view .modal-dialog .modal-content .modal-body .media .media-left, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-left {
    padding-bottom: 0;
    padding-right: 40px;
    width: auto;
  }
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-left img, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-left img {
  width: 100%;
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .quick-view .modal-dialog .modal-content .modal-body .media .media-left img, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-left img {
    padding-right: 40px;
    padding-bottom: 0;
  }
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-body h2, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-body h2 {
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 10px;
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-body h3, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-body h3 {
  color: #252525;
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-body .btn-area .btn-primary, .quick-view-drone .modal-dialog .modal-content .modal-body .media .media-body .btn-area .btn-primary {
  font-size: 14px;
}

.quick-view .modal-dialog .modal-content .modal-body .media .media-body .btn-area .btn-primary,
.singleProduct .media .media-body .btn-area .btn-primary {
  color: white;
}

/*=== 7. PAGE HEADER ===*/
/*=== 7.1 COMMON HEADER ===*/
@media (max-width: 479px) {
  .pageHeader .col-md-6, .pageHeader .col-sm-6 {
    width: 100%;
    padding-top: 10px;
  }
}

@media (min-width: 480px) {
  .pageHeader .col-md-6, .pageHeader .col-sm-6 {
    width: 50%;
    padding-top: 0;
  }
}

.pageHeader {
  height: 70px;
}

.pageHeader .page-title h2 {
  color: #797979;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 0;
}

@media (max-width: 479px) {
  .pageHeader .page-title h2 {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (min-width: 480px) {
  .pageHeader .page-title h2 {
    font-size: 12px;
    line-height: 70px;
  }
}

@media (min-width: 768px) {
  .pageHeader .page-title h2 {
    font-size: 24px;
  }
}

@media (min-width: 992px) {
  .pageHeader .page-title h2 {
    font-size: 30px;
  }
}

.pageHeader ol.breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  padding: 0;
  display: block;
}

@media (max-width: 479px) {
  .pageHeader ol.breadcrumb {
    float: left !important;
    min-height: 15px;
  }
}

@media (min-width: 480px) {
  .pageHeader ol.breadcrumb {
    float: right !important;
    min-height: 70px;
  }
}

.pageHeader ol.breadcrumb .active {
  color: #252525;
  text-transform: uppercase;
}

@media (min-width: 480px) {
  .pageHeader ol.breadcrumb .active {
    font-size: 9px;
  }
}

@media (min-width: 768px) {
  .pageHeader ol.breadcrumb .active {
    font-size: 12px;
  }
}

.pageHeader ol.breadcrumb li {
  display: inline-block;
}

.pageHeader ol.breadcrumb li + li::before {
  color: #ccc;
  content: "/\00a0";
}

@media (min-width: 480px) {
  .pageHeader ol.breadcrumb li + li::before {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .pageHeader ol.breadcrumb li + li::before {
    padding: 0 10px;
  }
}

.pageHeader ol.breadcrumb li a {
  text-transform: uppercase;
  color: #969696;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.pageHeader ol.breadcrumb li a:hover {
  opacity: .9;
}

@media (min-width: 480px) {
  .pageHeader ol.breadcrumb li a {
    font-size: 9px;
    line-height: 15px;
  }
}

@media (min-width: 480px) {
  .pageHeader ol.breadcrumb li a {
    font-size: 9px;
    line-height: 70px;
  }
}

@media (min-width: 768px) {
  .pageHeader ol.breadcrumb li a {
    font-size: 12px;
  }
}

/*=== 7.2 ABOUT US HEADER===*/
.aboutUsInfo .page-header {
  border: none;
  padding: 0;
  margin: 0;
}

.aboutUsInfo .page-header h3 {
  text-transform: capitalize;
  font-weight: 400;
}

@media (max-width: 479px) {
  .aboutUsInfo .page-header h3 {
    margin-bottom: 20px;
  }
}

@media (min-width: 480px) {
  .aboutUsInfo .page-header h3 {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .aboutUsInfo .page-header h3 {
    margin-bottom: 45px;
  }
}

/*=== 7.3 CART PAGE HEADER ===*/
.pageHeaderImage {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  height: auto;
  height: 200px;
  background-image: url(../img/products/page-header-image.jpg);
}

.pageHeaderImage .tableBlock {
  display: table;
  height: 200px;
  width: 100%;
}

.pageHeaderImage .tableBlock .tableInner {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  height: 73px;
}

.pageHeaderImage .tableBlock .tableInner .page-title h2 {
  color: #fffefe;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 15px;
  line-height: 1;
  font-size: 30px;
}

.pageHeaderImage .tableBlock .tableInner .page-title .breadcrumb {
  background-color: transparent;
  margin-bottom: 0;
  padding: 0;
  display: block;
}

.pageHeaderImage .tableBlock .tableInner .page-title .breadcrumb li {
  text-transform: uppercase;
  color: #e6e6e6;
  display: inline-block;
  padding: 0 10px;
  position: relative;
}

.pageHeaderImage .tableBlock .tableInner .page-title .breadcrumb li a {
  color: inherit;
}

.pageHeaderImage .tableBlock .tableInner .page-title .breadcrumb li:first-child:after {
  content: '/';
  position: absolute;
  top: 0;
  right: -4px;
}

/*=== 8. SIDEBARS ===*/
.sideBar .panel {
  border-radius: 0;
  border: none;
  margin-bottom: 20px;
}

.sideBar .panel .panel-heading {
  background-color: #47bac1;
  border-radius: 0;
  padding: 0 15px;
  height: 45px;
  line-height: 45px;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
}

.sideBar .panel .panel-body {
  border: 3px solid #f0f0f0;
  border-top: none;
  padding: 15px;
}

.sideBar .input-group {
  border: 3px solid #f0f0f0;
}

.sideBar .input-group .form-control,
.sideBar .input-group .input-group-addon {
  border: none;
}

.navbar-side-collapse .side-nav li a, .filterNormal .list-unstyled li a {
  font-size: 14px;
  color: #252525;
  line-height: 22px;
  padding: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: block;
}

.sub-list-item, .navbar-side-collapse .side-nav li .collapseItem li a, .sidebar-list li a {
  font-size: 13px !important;
  color: #888888;
  width: 100%;
  display: block;
  line-height: 20px;
  padding: 7px 0 7px 14px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.sub-list-item i, .navbar-side-collapse .side-nav li .collapseItem li a i, .sidebar-list li a i {
  right: auto;
  left: 0;
  line-height: 20px;
  position: absolute;
}

.sub-list-item:hover, .navbar-side-collapse .side-nav li .collapseItem li a:hover, .sidebar-list li a:hover {
  color: #47bac1;
}

.navbar-side-collapse {
  padding: 0;
  display: block !important;
}

.navbar-side-collapse .side-nav {
  width: 100%;
  margin: 0;
}

.navbar-side-collapse .side-nav li {
  width: 100%;
  margin-bottom: 15px;
}

.navbar-side-collapse .side-nav li a {
  background-color: transparent;
  position: relative;
}

.navbar-side-collapse .side-nav li a i {
  font-size: 12px;
  line-height: 22px;
  position: absolute;
  right: 0;
}

.navbar-side-collapse .side-nav li a:hover {
  color: #47bac1;
}

.navbar-side-collapse .side-nav li a[aria-expanded="true"] i:before {
  content: "" !important;
}

.navbar-side-collapse .side-nav li .collapseItem li {
  margin-bottom: 0 !important;
}

.filterNormal .list-unstyled {
  width: 100%;
}

.filterNormal .list-unstyled li {
  width: 100%;
}

.filterNormal .list-unstyled li a {
  padding: 10px 0;
}

.filterNormal .list-unstyled li a:hover {
  color: #47bac1;
}

.filterNormal .list-unstyled li a span {
  margin-left: 10px;
}

.priceRange .progress {
  margin: 20px 0 25px 0;
  height: 4px;
  background-color: #f0f0f0;
  position: relative;
  overflow: visible;
}

.priceRange .progress .progress-bar {
  background-color: #252525;
}

.priceRange .progress .bulet {
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 2px solid #252525;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  left: 0px;
  top: -5px;
  position: absolute;
}

.priceRange .progress .bulet.next {
  left: 60px;
}

.priceRange .btn-default {
  height: 30px;
  line-height: 30px;
  padding: 0;
  text-transform: uppercase;
  background-color: #252525;
  border-radius: 0;
  border: none;
  color: #ffffff;
  font-weight: 700;
}

@media (max-width: 479px) {
  .priceRange .btn-default {
    width: 82px;
    font-size: 14px;
  }
}

@media (min-width: 480px) {
  .priceRange .btn-default {
    width: 82px;
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .priceRange .btn-default {
    width: 50px;
    font-size: 10px;
  }
}

@media (min-width: 992px) {
  .priceRange .btn-default {
    width: 45px;
    font-size: 10px;
  }
}

@media (min-width: 1200px) {
  .priceRange .btn-default {
    width: 82px;
    font-size: 14px;
  }
}

.priceRange .priceLabel {
  float: right;
  line-height: 30px;
  color: #252525;
}

@media (min-width: 480px) {
  .priceRange .priceLabel {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .priceRange .priceLabel {
    font-size: 11px;
  }
}

@media (min-width: 992px) {
  .priceRange .priceLabel {
    font-size: 14px;
  }
}

.sidebar-list li a {
  position: relative;
}

.recentBlogPosts .media-heading {
  text-transform: capitalize;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
}

@media (min-width: 480px) {
  .recentBlogPosts .media-heading {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .recentBlogPosts .media-heading {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .recentBlogPosts .media-heading {
    font-size: 14px;
  }
}

.recentBlogPosts .media-heading a {
  color: #252525;
}

.recentBlogPosts p {
  color: #797979;
}

.recentBlogPosts p i {
  margin-right: 8px;
}

@media (min-width: 480px) {
  .recentBlogPosts p {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .recentBlogPosts p {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .recentBlogPosts p {
    font-size: 14px;
  }
}

.priceRange {
  position: relative;
}

.priceRange .price-slider-inner {
  display: block;
  margin: 15px 0 25px 0;
}

.priceRange .price-slider-inner .amount-wrapper {
  position: absolute;
  bottom: 25px;
  right: 20px;
  font-size: 14px;
  width: 120px;
  right: 15px;
}

.priceRange .price-slider-inner .amount-wrapper input {
  background-color: transparent;
  border: 0;
  width: 28%;
  font-size: 14px;
  color: #252525;
  font-weight: 700;
  text-align: right;
}

.priceRange .price-slider-inner .ui-widget-content {
  background: none;
  border: 0;
  background-color: #f0f0f0;
  height: 4px;
  clear: both;
  border-radius: 0;
  margin: 0 5px 0 9px;
}

.priceRange .price-slider-inner .ui-widget-content .ui-slider-range {
  background-color: #252525;
  border: none;
  border-radius: 0;
}

.priceRange .price-slider-inner .ui-widget-content .ui-slider-handle {
  border-radius: 50%;
  background: none;
  border: 3px solid #252525;
  background-color: #ffffff;
  top: -6px;
  width: 16px;
  height: 16px;
  outline: none;
}

/*=== 9. PRODUCTS ===*/
/*=== 9.1 PRODUCTS COMMON ===*/
@media (min-width: 768px) {
  .productsContent .productBox .productImage {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 992px) {
  .productsContent .productBox .productImage {
    width: 100%;
    height: auto;
  }
}

.filterArea {
  margin-bottom: 30px;
}

.filterArea .btn-group .btn-default {
  height: 35px;
  padding: 0;
  line-height: 33px;
  border-radius: 0;
  border-color: #d0d0d0;
  margin: 0 0 0 5px;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 400;
  color: #252525;
  box-shadow: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 479px) {
  .filterArea .btn-group .btn-default span {
    display: none;
  }
}

@media (min-width: 480px) {
  .filterArea .btn-group .btn-default span {
    padding-left: 8px;
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .filterArea .btn-group .btn-default i {
    margin-right: 6px;
  }
}

.filterArea .btn-group .btn-default:hover, .filterArea .btn-group .btn-default.active {
  background-color: #47bac1;
  color: #ffffff;
  border-color: #47bac1;
}

@media (max-width: 479px) {
  .filterArea .btn-group .btn-default {
    width: 40px;
  }
}

@media (min-width: 480px) {
  .filterArea .btn-group .btn-default {
    width: 88px;
  }
}

.productListSingle .media {
  margin-bottom: 50px;
}

.productListSingle .media .media-left {
  position: relative;
  padding-right: 0;
  overflow: hidden;
}

.productListSingle .media .media-left img {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.productListSingle .media .media-left .maskingImage .viewBtn {
  width: 175px;
  padding: 0;
  line-height: 55px;
}

@media (max-width: 479px) {
  .productListSingle .media .media-left {
    float: none !important;
    display: block;
    margin: 0 0 20px 0;
  }
  .productListSingle .media .media-left img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 480px) {
  .productListSingle .media .media-left {
    float: none !important;
    display: block;
    margin: 0 0 20px 0;
  }
  .productListSingle .media .media-left img {
    width: 100%;
    height: auto;
  }
}

@media (min-width: 768px) {
  .productListSingle .media .media-left {
    float: left !important;
    margin-right: 30px;
    display: inline-block;
    width: 226px;
  }
  .productListSingle .media .media-left img {
    width: 100%;
    height: auto;
  }
}

.productListSingle .media .media-body .media-heading a {
  font-size: 22px;
  letter-spacing: 1px;
  line-height: 26px;
  font-weight: 400;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: inherit;
  display: block;
}

.productListSingle .media .media-body p {
  font-size: 14px;
  color: #888888;
  line-height: 22px;
  margin-top: 5px;
  margin-bottom: 25px;
}

.productListSingle .media .media-body h3 {
  color: #c5c5c5;
  font-weight: 700;
  margin-bottom: 25px;
  font-size: 24px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  margin-right: 20px;
}

@media (min-width: 992px) {
  .productListSingle .media .media-body h3 {
    display: block;
    margin-right: 0;
  }
}

.productListSingle .media .media-body .btn-group .btn-default {
  border-radius: 0;
  width: 35px;
  height: 35px;
  line-height: 33px !important;
  padding: 0;
  text-align: center;
  margin-right: 7px;
  border: 1px solid #d0d0d0;
  background: transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.productListSingle .media .media-body .btn-group .btn-default i {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #888888;
}

.productListSingle .media .media-body .btn-group .btn-default:hover, .productListSingle .media .media-body .btn-group .btn-default:focus {
  background-color: #47bac1;
  border-color: #47bac1;
}

.productListSingle .media .media-body .btn-group .btn-default:hover i, .productListSingle .media .media-body .btn-group .btn-default:focus i {
  color: #ffffff;
}

.productListSingle .media:hover .pull-left img {
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.productListSingle .media:hover .maskingImage {
  opacity: 1;
}

.productListSingle .media:hover .maskingImage .viewBtn {
  -webkit-animation: itemMove 0.3s linear;
  animation: itemMove 0.3s linear;
}

.productListSingle .media:hover .media-heading,
.productListSingle .media:hover h3 {
  color: #47bac1;
}

/*=== 9.2 SINGLE PRODUCTS ===*/
.singleProduct .media {
  margin-bottom: 70px;
}

@media (max-width: 479px) {
  .singleProduct .media .productSlider {
    width: 100%;
    padding: 0 0 30px 0;
  }
}

@media (min-width: 480px) {
  .singleProduct .media .productSlider {
    width: 100%;
    padding: 0 0 30px 0;
  }
}

@media (min-width: 768px) {
  .singleProduct .media .productSlider {
    width: 100%;
    padding: 0 0 30px 0;
  }
}

@media (min-width: 992px) {
  .singleProduct .media .productSlider {
    width: 489px;
    padding: 0 30px 0 0;
  }
}

.singleProduct .media .productSlider #carousel {
  margin-bottom: 20px;
}

.singleProduct .media .productSlider #carousel .item img {
  width: 100%;
}

.singleProduct .media .productSlider #thumbcarousel .thumb {
  width: 23.35%;
  cursor: pointer;
  float: left;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 479px) {
  .singleProduct .media .productSlider #thumbcarousel .thumb {
    margin-right: 2.1%;
  }
}

@media (min-width: 480px) {
  .singleProduct .media .productSlider #thumbcarousel .thumb {
    margin-right: 2.1%;
  }
}

@media (min-width: 768px) {
  .singleProduct .media .productSlider #thumbcarousel .thumb {
    margin-right: 2.1%;
  }
}

@media (min-width: 992px) {
  .singleProduct .media .productSlider #thumbcarousel .thumb {
    margin-right: 10px;
  }
}

.singleProduct .media .productSlider #thumbcarousel .thumb img {
  width: 100%;
}

.singleProduct .media .productSlider #thumbcarousel .thumb:last-child {
  margin-right: 0;
}

.singleProduct .media .productSlider #thumbcarousel .thumb:hover {
  opacity: .8;
}

.singleProduct .media .productSlider #thumbcarousel .carousel-control {
  display: none;
}

@media (max-width: 479px) {
  .singleProduct .media .media-body {
    display: block;
    width: 100%;
  }
}

@media (min-width: 480px) {
  .singleProduct .media .media-body {
    display: block;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .singleProduct .media .media-body {
    display: table-cell;
  }
}

.singleProduct .media .media-body .list-inline {
  margin-bottom: 30px;
}

.singleProduct .media .media-body .list-inline li {
  margin-right: 30px;
}

.singleProduct .media .media-body .list-inline li a {
  color: #797979;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.singleProduct .media .media-body .list-inline li a i {
  margin-right: 8px;
}

.singleProduct .media .media-body .list-inline li a:hover {
  opacity: .8;
}

.singleProduct .media .media-body h2 {
  font-size: 32px;
  letter-spacing: 2px;
  margin-bottom: 15px;
}

.singleProduct .media .media-body > p {
  margin: 40px 0 45px;
}

.singleProduct .media .media-body .btn-area {
  margin-bottom: 40px;
}

.singleProduct .media .media-body .tabArea .nav-tabs li a.nav-link {
  text-transform: uppercase;
  border: none;
  line-height: 1;
  color: #797979;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.singleProduct .media .media-body .tabArea .nav-tabs li a.nav-link:hover {
  color: #252525;
  background-color: transparent;
}

@media (max-width: 479px) {
  .singleProduct .media .media-body .tabArea .nav-tabs li a.nav-link {
    font-size: 10px;
    padding: 10px;
  }
}

@media (min-width: 480px) {
  .singleProduct .media .media-body .tabArea .nav-tabs li a.nav-link {
    font-size: 13px;
    padding: 10px 15px;
  }
}

.singleProduct .media .media-body .tabArea .nav-tabs li a.nav-link:before {
  bottom: -1px;
  border-bottom: 1px solid #252525;
}

.singleProduct .media .media-body .tabArea .nav-tabs li a.nav-link.active {
  color: #252525;
}

.singleProduct .media .media-body .tabArea .tab-content {
  color: #797979;
}

.singleProduct .media .media-body .tabArea .tab-content p {
  color: #797979;
  font-size: 13px;
  line-height: 24px;
  margin-bottom: 16px;
}

.singleProduct .media .media-body .tabArea .tab-content .list-unstyled {
  padding-left: 15px;
}

.singleProduct .media .media-body .tabArea .tab-content .list-unstyled li {
  list-style-type: square;
  font-size: 13px;
  line-height: 24px;
}

@media (max-width: 479px) {
  .singleProduct .media .media-body .tabArea {
    width: 100%;
  }
}

@media (min-width: 480px) {
  .singleProduct .media .media-body .tabArea {
    width: 348px;
  }
}

@media (min-width: 768px) {
  .singleProduct .media .media-body .tabArea {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .singleProductTab .media .media-body .tabArea .nav-tabs a.nav-link {
    padding: 10px 50px !important;
  }
}

@media (min-width: 992px) {
  .singleProductTab .media .media-body .tabArea .nav-tabs a.nav-link {
    padding: 10px 80px !important;
  }
}

@media (min-width: 1200px) {
  .singleProductTab .media .media-body .tabArea .nav-tabs a.nav-link {
    padding: 10px 100px !important;
  }
}

/*=== 9.3 CART PRODUCTS ===*/
.cartListInner .updateArea .input-group .form-control, .cartListInner .updateArea .input-group .input-group-addon {
  border-radius: 0;
  box-shadow: none;
  height: 40px;
  background-color: #ffffff;
  border: 2px solid #d0d0d0;
}

.cartListInner .updateArea .input-group .input-group-addon {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
}

.cartListInner .row.totalAmountArea {
  margin: 0;
}

.cartListInner .updateArea, .cartListInner .totalAmountArea, .cartListInner .checkBtnArea {
  display: block;
  float: left;
  padding: 20px 30px;
  width: 100%;
  border-left: 5px solid #f0f0f0;
  border-right: 5px solid #f0f0f0;
  border-bottom: 3px solid #f0f0f0;
}

.cartListInner .table-responsive .table thead tr th, .orderBox .table-responsive .table thead tr th {
  background-color: #47bac1;
  padding: 0;
  height: 55px;
  vertical-align: middle;
  text-align: left;
  font-size: 16px;
  color: #ffffff;
  padding-left: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: none;
}

.cartListInner .table-responsive .table tbody tr td, .orderBox .table-responsive .table tbody tr td {
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: 700;
  border-bottom: 3px solid #f0f0f0;
  border-top: none;
  position: relative;
}

.cartListInner .table-responsive .table tbody tr td .close, .orderBox.myAddress .table-responsive .table tbody tr td .close {
  position: absolute;
  left: 0;
  float: left;
  font-size: 22px;
  margin-right: 30px;
  color: #47bac1;
  opacity: 1;
  line-height: 74px;
  font-weight: normal;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cartListInner .table-responsive .table tbody tr td .close:hover, .orderBox.myAddress .table-responsive .table tbody tr td .close:hover {
  opacity: .8;
}

@media (min-width: 768px) {
  .cartListInner .table-responsive .table tbody tr td .close, .orderBox.myAddress .table-responsive .table tbody tr td .close {
    margin-right: 5px;
  }
}

@media (min-width: 992px) {
  .cartListInner .table-responsive .table tbody tr td .close, .orderBox.myAddress .table-responsive .table tbody tr td .close {
    position: relative;
    margin-right: 30px;
  }
}

.cartListInner .table-responsive {
  margin-bottom: 0;
}

@media (min-width: 480px) {
  .cartListInner .table-responsive {
    overflow-x: auto;
  }
}

@media (min-width: 768px) {
  .cartListInner .table-responsive {
    overflow-x: visible;
  }
}

.cartListInner .table-responsive .table {
  margin-bottom: 0;
}

.cartListInner .table-responsive .table thead tr {
  border-left: 3px solid #47bac1;
  border-right: 3px solid #47bac1;
}

.cartListInner .table-responsive .table thead tr th {
  letter-spacing: 1px;
  border-top: none;
}

.cartListInner .table-responsive .table thead tr th:last-child {
  border-right: none;
}

@media (max-width: 479px) {
  .cartListInner .table-responsive .table thead tr th:first-child {
    display: none;
  }
}

@media (min-width: 480px) {
  .cartListInner .table-responsive .table thead tr th:first-child {
    display: none;
  }
}

@media (min-width: 768px) {
  .cartListInner .table-responsive .table thead tr th:first-child {
    display: table-cell;
  }
}

.cartListInner .table-responsive .table tbody tr {
  border-left: 3px solid #f0f0f0;
  border-right: 3px solid #f0f0f0;
}

.cartListInner .table-responsive .table tbody tr td {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 400;
}

@media (min-width: 480px) {
  .cartListInner .table-responsive .table tbody tr td {
    padding: 10px;
  }
}

@media (min-width: 768px) {
  .cartListInner .table-responsive .table tbody tr td {
    padding: 10px;
  }
}

@media (min-width: 768px) {
  .cartListInner .table-responsive .table tbody tr td {
    padding-left: 20px;
  }
}

.cartListInner .table-responsive .table tbody tr td .close {
  position: relative;
  left: 0;
  font-size: 22px;
}

.cartListInner .table-responsive .table tbody tr td .cartImage {
  display: inline-block;
  float: left;
}

.cartListInner .table-responsive .table tbody tr td:nth-child(3) {
  color: #888888;
}

.cartListInner .table-responsive .table tbody tr td input {
  width: 20px;
  height: 40px;
  text-align: center;
  color: #333333;
  font-weight: 700;
  border: 1px solid #e4e4e4;
  margin: 0 -3px;
  border-left: 0px;
  border-right: 0px;
}

@media (min-width: 768px) {
  .cartListInner .table-responsive .table tbody tr td input {
    width: 40px;
  }
}

@media (max-width: 479px) {
  .cartListInner .table-responsive .table tbody tr td:first-child {
    display: none;
  }
}

@media (min-width: 480px) {
  .cartListInner .table-responsive .table tbody tr td:first-child {
    display: none;
  }
}

@media (min-width: 768px) {
  .cartListInner .table-responsive .table tbody tr td:first-child {
    display: table-cell;
  }
}

.cartListInner .table-responsive .table tbody tr td.count-input a {
  color: #a5a5a5;
  font-size: 10px;
  padding: 4px 6px 0px;
  display: inline-block;
  border: 1px solid #e4e4e4;
  line-height: 34px;
  height: 40px;
}

@media (min-width: 768px) {
  .cartListInner .table-responsive .table tbody tr td.count-input a {
    padding: 4px 10px 0px;
  }
}

.cartListInner .updateArea .input-group {
  float: left;
}

@media (max-width: 479px) {
  .cartListInner .updateArea .input-group {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (min-width: 480px) {
  .cartListInner .updateArea .input-group {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .cartListInner .updateArea .input-group {
    width: 435px;
    margin-bottom: 0;
  }
}

.cartListInner .updateArea .input-group .form-control {
  margin-right: 10px;
  margin-right: 10px;
  border-color: #e4e4e4;
  font-size: 13px;
}

@media (max-width: 479px) {
  .cartListInner .updateArea .input-group .form-control {
    width: 50%;
  }
}

@media (min-width: 480px) {
  .cartListInner .updateArea .input-group .form-control {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .cartListInner .updateArea .input-group .form-control {
    width: 285px;
  }
}

.cartListInner .updateArea .input-group .input-group-addon {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cartListInner .updateArea .input-group .input-group-addon:hover {
  opacity: .8;
}

@media (max-width: 479px) {
  .cartListInner .updateArea .input-group .input-group-addon {
    width: 45%;
    font-size: 10px;
  }
}

@media (min-width: 480px) {
  .cartListInner .updateArea .input-group .input-group-addon {
    width: 35%;
  }
}

@media (min-width: 768px) {
  .cartListInner .updateArea .input-group .input-group-addon {
    width: 140px;
  }
}

.cartListInner .updateArea .btn {
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #252525;
  font-size: 13px;
  font-weight: 700;
}

.cartListInner .updateArea .btn.btn-primary-outlined:hover {
  color: #ffffff;
}

.cartListInner .totalAmountArea .list-unstyled li {
  font-weight: 400;
  text-align: right;
  margin: 10px 0;
  font-size: 15px;
}

.cartListInner .totalAmountArea .list-unstyled li span {
  width: 108px;
  display: inline-block;
  color: #888888;
}

.cartListInner .totalAmountArea .list-unstyled li .grandTotal {
  color: #252525;
}

.cartListInner .checkBtnArea {
  border-bottom-width: 5px;
}

.cartListInner .checkBtnArea .btn-primary {
  float: right;
  letter-spacing: 1px;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.cartListInner .checkBtnArea .btn-primary i {
  margin-left: 10px;
}

.cartListInner .checkBtnArea .btn-primary:hover {
  opacity: .8;
}

.SingleListStyle {
  padding-left: 0 !important;
}

.SingleListStyle li {
  list-style-type: none !important;
  font-size: 15px !important;
  color: #000 !important;
}

.SingleListStyle li span {
  color: #888 !important;
}

/*=== productSlider ===*/
.SingleProductItem {
  position: relative;
  transition: all 0.3s ease-in-out;
}

.SingleProductItem:hover .SingleProductImage img {
  transform: scale(1.2);
}

.SingleProductItem:hover .productMasking a,
.SingleProductItem:hover .MaskingIcon i,
.SingleProductItem:hover .product-content {
  opacity: 1;
}

.SingleProductItem:hover .video-icon a {
  border-color: #fff;
  color: #fff;
}

.SingleProductItem .SingleProductImage img {
  width: 100% !important;
  transition: all .3s;
}

.SingleProductItem .product-content {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(71, 186, 193, 0.9);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.SingleProductItem .productMasking a {
  color: #fff;
  font-size: 16px;
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  display: inline-block;
  text-align: center;
  line-height: 46px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.SingleProductItem .MaskingIcon i {
  color: #fff;
  font-size: 16px;
  width: 50px;
  height: 50px;
  border: 2px solid #fff;
  display: inline-block;
  text-align: center;
  line-height: 46px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.SingleProductItem .video-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.SingleProductItem .video-icon a {
  width: 50px;
  height: 50px;
  line-height: 48px;
  border: 2px solid #47bac1;
  border-radius: 50%;
  color: #47bac1;
  display: inline-block;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.share-all .all-link {
  visibility: hidden;
  opacity: 0;
  transition: .5s;
}

.share-all .all-link.active {
  visibility: visible;
  opacity: 1;
  margin-left: 25px;
}

.SingleSocialIcon {
  border: 1px solid #797979;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
}

.SingleSocialIcon:hover {
  background-color: #47bac1;
  border-color: #47bac1;
  color: #fff !important;
}

.SingleSocialIcon:last-child {
  margin-right: 0;
}

.SingleSocialIcon i {
  margin-right: 0 !important;
}

.ReviewsPost {
  font-size: 13px;
}

.quantityWidth.resizeWidth .sbHolder, .quantityWidth.resizeWidth .sbSelector, .quantityWidth.resizeWidth .sbOptions {
  width: 196px !important;
}

.single-quick-drop {
  margin: 0 0px 30px 0 !important;
}

.singleFormWidth.resizeWidth .sbHolder, .singleFormWidth.resizeWidth .sbSelector, .singleFormWidth.resizeWidth .sbOptions {
  width: 100% !important;
  background-color: #f0f0f0;
  border-color: transparent;
}

.singleFormWidth .sbOptions {
  background-color: #fff !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.singleFormWidth .sbToggle {
  z-index: 10;
}

.singleFormWidth .sbHolder, .singleFormWidth .sbSelector {
  height: 38px !important;
}

.SingleCartListInner.cartListInner .table-responsive .table tbody tr td input {
  width: 33px;
  color: #797979;
  border-color: #d0d0d0;
  margin: 0 -4px;
  font-weight: 400;
}

.SingleCartListInner.cartListInner .table-responsive .table tbody tr td.count-input a {
  padding: 4px 13px 0px;
  border: 1px solid #d0d0d0;
}

.SingleCartListInner.cartListInner .table-responsive .table tbody tr td:first-child {
  display: block;
}

.SingleCartListWrapper .cartListInner .table-responsive .table tbody tr td:first-child,
.SingleCartListWrapper .cartListInner .table-responsive .table thead tr th:first-child {
  display: table-cell;
}

.SingleCartSizing .cartListInner .table-responsive .table tbody tr td,
.SingleCartSizing .cartListInner .table-responsive .table thead tr th {
  text-align: center;
}

.SingleCartSizing .cartListInner .table-responsive .table tbody tr td {
  padding: 16px 0 !important;
}

.SingleCartSizing .cartListInner .table-responsive .table thead tr th {
  padding-left: 0 !important;
}

.SingleCartShipping .cartListInner .table-responsive .table tbody tr td,
.SingleCartShipping .cartListInner .table-responsive .table thead tr th {
  font-size: 14px;
}

@media (min-width: 768px) {
  .SingleCartShipping .cartListInner .table-responsive .table tbody tr td,
  .SingleCartShipping .cartListInner .table-responsive .table thead tr th {
    font-size: 16px;
  }
}

.SingleCartShipping .cartListInner .table-responsive .table tbody tr td {
  padding: 16px 0 16px 15px !important;
}

@media (min-width: 768px) {
  .SingleCartShipping .cartListInner .table-responsive .table tbody tr td {
    padding: 16px 0 16px 20px !important;
  }
}

.SingleCartShipping .cartListInner .table-responsive .table thead tr th {
  padding: 10px;
}

@media (min-width: 768px) {
  .SingleCartShipping .cartListInner .table-responsive .table thead tr th {
    padding: 20px;
  }
}

.single-custom-control .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #47bac1;
  box-shadow: none !important;
}

/*=== 10. GENERIC CONTENT ===*/
.genricContent h3 {
  margin-bottom: 30px;
  font-weight: 400;
}

.genricContent p {
  color: #797979;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 30px;
}

.genricContent h4 {
  color: #252525;
  font-weight: 400;
  margin-bottom: 20px;
}

/*=== 11. 404 PAGE ===*/
.input-field, .sideBar .input-group, .notFound .input-group, .commingSoon .blackSection .input-group {
  margin-bottom: 20px;
}

.input-field .form-control, .sideBar .input-group .form-control, .notFound .input-group .form-control, .commingSoon .blackSection .input-group .form-control {
  height: 49px;
  border-radius: 0;
  border-color: #bfbfbf;
  background: transparent;
  border-right: none;
  padding-left: 20px;
}

.input-field .form-control:focus, .sideBar .input-group .form-control:focus, .notFound .input-group .form-control:focus, .commingSoon .blackSection .input-group .form-control:focus {
  box-shadow: none;
}

.input-field .input-group-addon, .sideBar .input-group .input-group-addon, .notFound .input-group .input-group-addon, .commingSoon .blackSection .input-group .input-group-addon {
  background-color: transparent;
  border-radius: 0;
  border-color: #bfbfbf;
  font-size: 18px;
  color: #252525;
  padding: 6px 20px;
}

.notFound .pageLogo, .commingSoon .blackSection .pageLogo {
  margin-bottom: 80px;
  display: inline-block;
  float: none;
  margin-top: 0;
  padding: 0;
}

.notFound {
  text-align: center;
  padding-bottom: 90px;
}

.notFound h1 {
  color: #47bac1;
  text-shadow: 8px 8px 1px #dadada;
  margin-bottom: 70px;
  line-height: 1;
}

@media (max-width: 479px) {
  .notFound h1 {
    font-size: 150px;
  }
}

@media (min-width: 480px) {
  .notFound h1 {
    font-size: 200px;
  }
}

@media (min-width: 768px) {
  .notFound h1 {
    font-size: 300px;
  }
}

.notFound h2 {
  color: #333333;
  margin-bottom: 50px;
}

.notFound .input-group {
  margin-bottom: 50px;
}

.notFound .btn-default {
  height: 42px;
  background-color: #47bac1;
  font-size: 14px;
  color: #ffffff;
  text-transform: uppercase;
  border-radius: 0;
  padding: 0;
  line-height: 38px;
  border: 2px solid #47bac1;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.notFound .btn-default:hover {
  opacity: .9;
}

/*=== 12. BLOG ===*/
.blogPage .thumbnail {
  padding-bottom: 50px;
  margin-bottom: 50px;
  border-bottom: 3px solid #f0f0f0;
}

.blogPage .thumbnail img {
  margin-bottom: 50px;
  max-width: 100%;
}

.blogPage .thumbnail .carousel .carousel-inner .item img {
  margin-bottom: 0;
}

.blogPage .thumbnail .carousel .carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: auto;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
}

.blogPage .thumbnail .carousel .carousel-control.left {
  left: 50px;
}

.blogPage .thumbnail .carousel .carousel-control.right {
  right: 50px;
  left: auto;
}

.blogPage .thumbnail .carousel .carousel-control span {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 45px;
  height: 45px;
  background-color: #f0f0f0;
  opacity: 0.5;
  border-radius: 0;
  display: block;
  line-height: 45px;
  font-size: 15px;
  text-shadow: none;
  color: #252525;
  margin-top: -40px;
}

.blogPage .thumbnail .carousel .carousel-control span:hover {
  opacity: 1;
}

.blogPage .thumbnail .carousel .carousel-control.right,
.blogPage .thumbnail .carousel .carousel-control.left {
  background-image: none;
}

.blogPage .thumbnail .caption {
  padding-top: 0;
}

.blogPage .thumbnail .caption h5 {
  float: left;
  width: 100%;
  margin: 0 0 25px 0;
  text-transform: capitalize;
}

@media (min-width: 480px) {
  .blogPage .thumbnail .caption h5 {
    margin: 0 0 10px 0;
  }
}

@media (min-width: 768px) {
  .blogPage .thumbnail .caption h5 {
    margin: 30px 0 10px 0;
  }
}

.blogPage .thumbnail .caption .list-unstyled {
  width: 100%;
}

@media (max-width: 479px) {
  .blogPage .thumbnail .caption .list-unstyled {
    display: block;
    margin-bottom: 20px;
    float: left;
  }
}

@media (min-width: 480px) {
  .blogPage .thumbnail .caption .list-unstyled {
    display: block;
    margin-bottom: 20px;
    float: left;
  }
}

@media (min-width: 768px) {
  .blogPage .thumbnail .caption .list-unstyled {
    display: block;
    margin-bottom: 0;
  }
}

.blogPage .thumbnail .caption .list-unstyled li {
  color: #797979;
  font-size: 14px;
  line-height: 22px;
}

@media (min-width: 480px) {
  .blogPage .thumbnail .caption .list-unstyled li {
    display: inline-block;
    float: left;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .blogPage .thumbnail .caption .list-unstyled li {
    display: inline-block;
    float: left;
    width: 100%;
  }
}

.blogPage .thumbnail .caption .list-unstyled li a {
  color: #797979;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.blogPage .thumbnail .caption .list-unstyled li a:hover {
  opacity: .8;
}

.blogPage .thumbnail .caption .list-unstyled li a i {
  margin-right: 13px;
}

.blogPage .thumbnail .caption .list-unstyled li i {
  margin-right: 13px;
}

.blogPage .thumbnail .caption h3 {
  text-transform: capitalize;
  margin-bottom: 30px;
  font-weight: 700;
  line-height: 32px;
}

.blogPage .thumbnail .caption h3 a {
  color: #252525;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.blogPage .thumbnail .caption h3 a:hover {
  opacity: .8;
}

.blogPage .thumbnail .caption p {
  font-size: 14px;
  color: #797979;
  line-height: 22px;
}

.blogPage .thumbnail .caption .list-inline {
  margin: 0 0 20px 0;
  float: left;
}

.blogPage .thumbnail .caption .list-inline li {
  padding: 0 10px 0px 0;
  float: left;
}

@media (min-width: 480px) {
  .blogPage .thumbnail .caption .list-inline li {
    padding: 0 10px 10px 0;
  }
}

.blogPage .thumbnail .caption .list-inline li a {
  text-align: center;
  display: block;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.blogPage .thumbnail .caption .list-inline li a:hover {
  opacity: .9;
}

@media (max-width: 479px) {
  .blogPage .thumbnail .caption .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}

@media (min-width: 480px) {
  .blogPage .thumbnail .caption .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}

@media (min-width: 768px) {
  .blogPage .thumbnail .caption .list-inline li a {
    width: 27px;
    height: 27px;
    line-height: 27px;
  }
}

@media (min-width: 992px) {
  .blogPage .thumbnail .caption .list-inline li a {
    width: 27px;
    height: 27px;
    line-height: 27px;
  }
}

@media (min-width: 1200px) {
  .blogPage .thumbnail .caption .list-inline li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
}

.blogPage .thumbnail .caption .list-inline li:first-child a {
  background-color: #46639f;
}

.blogPage .thumbnail .caption .list-inline li:nth-child(2) a {
  background-color: #0084b4;
}

.blogPage .thumbnail .caption .list-inline li:nth-child(3) a {
  background-color: #c73b6f;
}

.blogPage .thumbnail .caption .list-inline li:last-child a {
  background-color: #36465d;
}

.blogPage .thumbnail .pager {
  background-color: #f0f0f0;
  padding: 20px;
  margin: 36px 0 60px 0;
}

.blogPage .thumbnail .pager li {
  display: inline;
}

.blogPage .thumbnail .pager li a {
  display: inline-block;
  text-align: center;
  border-radius: 0;
  background-color: #dedede;
  height: 45px;
  padding: 0;
  line-height: 45px;
  font-weight: 700;
  text-transform: uppercase;
  color: #252525;
  font-size: 15px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: none;
}

@media (max-width: 479px) {
  .blogPage .thumbnail .pager li a {
    width: 110px;
  }
}

@media (min-width: 480px) {
  .blogPage .thumbnail .pager li a {
    width: 136px;
  }
}

.blogPage .thumbnail .pager li a:hover {
  opacity: .8;
}

.blogPage .pagination {
  margin: 0 0 50px 0;
}

.blogPage .pagination li a {
  float: none;
  border-radius: 0;
  padding: 0;
  border: 3px solid #f0f0f0;
  display: inline-block;
  font-size: 18px;
  color: #252525;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin: 0 3px;
  text-align: center !important;
}

@media (max-width: 479px) {
  .blogPage .pagination li a {
    width: 35px;
    height: 35px;
    line-height: 30px;
  }
}

@media (min-width: 480px) {
  .blogPage .pagination li a {
    width: 45px;
    height: 45px;
    line-height: 40px;
  }
}

.blogPage .pagination li a span {
  font-size: 24px;
}

@media (max-width: 479px) {
  .blogPage .pagination li a span {
    line-height: 20px;
  }
}

@media (min-width: 480px) {
  .blogPage .pagination li a span {
    line-height: 30px;
  }
}

.blogPage .pagination li a:hover {
  color: #ffffff;
  background-color: #47bac1;
  border-color: #47bac1;
}

.blogPage .pagination li.active a, .blogPage .pagination li.active a:hover, .blogPage .pagination li.active a:focus {
  color: #ffffff;
  background-color: #47bac1;
  border-color: #47bac1;
}

.commentsForm h3, .commentsArea h3 {
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.commentsArea .media {
  margin: 0 0 50px 0;
}

.commentsArea .media a {
  padding-right: 20px;
}

.commentsArea .media .media-body .media {
  margin: 50px 0 0 0;
}

.commentsArea .media .media-body h4, .commentsArea .media .media-body p {
  text-transform: capitalize;
  font-size: 14px;
  margin: 0 0 14px 0;
  display: block;
}

.commentsArea .media .media-body h4 span, .commentsArea .media .media-body p span {
  color: #797979;
  font-weight: 400;
}

.commentsArea .media .media-body h4 span i, .commentsArea .media .media-body p span i {
  margin-right: 14px;
}

.commentsArea .media .media-body p {
  color: #797979;
  line-height: 22px;
}

.commentsArea .media .media-body .btn-link {
  padding: 0;
  color: #797979;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: capitalize;
  font-size: 14px;
}

.commentsArea .media .media-body .btn-link:hover {
  color: #47bac1;
  text-decoration: none;
}

.singleBlog .thumbnail {
  padding-bottom: 0;
}

/*=== 13. ABOUT US ===*/
.aboutUsInfo img {
  width: 100%;
  height: auto;
}

@media (max-width: 479px) {
  .aboutUsInfo img {
    margin-bottom: 20px;
  }
}

@media (min-width: 480px) {
  .aboutUsInfo img {
    margin-bottom: 20px;
  }
}

@media (min-width: 768px) {
  .aboutUsInfo img {
    margin-bottom: 0;
  }
}

.aboutUsInfo p {
  font-size: 14px;
  color: #797979;
  line-height: 22px;
}

.aboutUsInfo .lead {
  font-size: 15px;
  font-weight: 400;
  color: #252525;
}

.lists li {
  font-size: 14px;
  color: #797979;
  line-height: 30px;
  padding-left: 18px;
}

@media (min-width: 480px) {
  .lists {
    margin-bottom: 50px;
  }
}

@media (min-width: 768px) {
  .lists {
    margin-bottom: 0;
  }
}

.unorder-list li:before {
  content: "";
  width: 8px;
  height: 8px;
  position: relative;
  left: -10px;
  top: -1px;
  background: #47bac1;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
}

.darkSection h3, .aboutPeople h3, .setp5 .thanksContent h3 {
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 400;
  margin-bottom: 25px;
}

.darkSection .thumbnail .caption h5, .aboutPeople .thumbnail .caption h5 {
  color: #ffffff;
  text-transform: capitalize;
  font-size: 15px;
  margin-bottom: 16px;
}

.darkSection {
  background-image: url("../img/about-us/store-bg.jpg");
  background-attachment: fixed;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  color: #ffffff;
  padding: 100px 0;
}

.darkSection .thumbnail {
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  min-height: 180px;
  margin-bottom: 30px;
}

.darkSection .thumbnail .caption {
  padding: 0;
}

.darkSection .thumbnail .caption address {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 700;
  line-height: 22px;
}

@media (min-width: 480px) {
  .darkSection .thumbnail .caption address {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .darkSection .thumbnail .caption address {
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .darkSection .thumbnail .caption address {
    font-size: 10px;
  }
}

@media (min-width: 1200px) {
  .darkSection .thumbnail .caption address {
    font-size: 10px;
  }
}

.darkSection .btn-default {
  font-weight: 700;
  border: 2px solid rgba(255, 255, 255, 0.5);
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.darkSection .btn-default:hover {
  background-color: #47bac1;
  color: #ffffff;
  border-color: #47bac1;
}

@media (min-width: 480px) {
  .aboutPeople {
    padding-bottom: 90px;
  }
}

@media (min-width: 480px) {
  .aboutPeople {
    padding-bottom: 60px;
  }
}

.aboutPeople h3 {
  color: #252525;
}

.aboutPeople .thumbnail img {
  width: 100%;
  height: auto;
}

.aboutPeople .thumbnail .caption {
  padding-top: 18px;
}

.aboutPeople .thumbnail .caption h5 {
  color: #252525;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.aboutPeople .thumbnail .caption p {
  font-size: 13px;
}

/*=== 14. STEPS ===*/
/*=== 14.1 STEP # 01 ===*/
.stepsWrapper .innerWrapper {
  border: 3px solid #f0f0f0;
  display: block;
  padding: 30px 30px 0;
  width: 100%;
}

@media (min-width: 480px) {
  .stepsWrapper .innerWrapper.stepsPage {
    margin-bottom: 90px;
  }
}

@media (min-width: 768px) {
  .stepsWrapper .innerWrapper.stepsPage {
    margin-bottom: 30px;
  }
}

.stepsWrapper .innerWrapper.stepsPage .cartListInner {
  margin-bottom: 50px;
  display: block;
}

.stepsWrapper .innerWrapper .well {
  border-radius: 0;
  border: none;
  box-shadow: none;
  margin-bottom: 50px;
  background: #f0f0f0;
  padding: 20px 30px;
}

.stepsWrapper .innerWrapper .well .pager {
  margin: 0;
}

.stepsWrapper .innerWrapper .well .pager li a {
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.stepsWrapper .innerWrapper .well .pager li a:hover {
  opacity: 0.8;
}

.stepsWrapper .innerWrapper .well .pager li a i {
  font-size: 18px;
  padding-left: 5px;
}

.stepsWrapper .innerWrapper .well .pager li a.hideContent {
  display: none;
}

.stepsWrapper .innerWrapper .well .pager li.previous a {
  background-color: #dedede;
  color: #252525;
}

.stepsWrapper .innerWrapper .well .pager li.previous a:hover {
  background-color: #47bac1;
  color: #ffffff;
}

.stepsWrapper .innerWrapper .orderBox .table-responsive .table tbody tr td {
  text-transform: capitalize;
}

.stepsWrapper .innerWrapper .orderBox .table-responsive .table tbody tr td .checkboxArea {
  margin: 0;
  float: none;
}

.stepsWrapper .innerWrapper .step2.checkboxArea {
  margin-bottom: 25px;
}

.stepsWrapper .innerWrapper .step2.checkboxArea label {
  font-size: 13px;
  color: #797979;
}

.stepsWrapper .innerWrapper .checkboxArea.card-check {
  width: 100%;
}

.stepsWrapper .innerWrapper .checkboxArea.card-check .form-control {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  height: 44px;
}

.stepsWrapper .innerWrapper .checkboxArea.card-check span.step-drop {
  display: flex;
  justify-content: space-between;
}

.stepsWrapper .innerWrapper .checkboxArea.card-check .sbHolder {
  border: 1px solid #f0f0f0;
  background: #ffffff;
  max-width: 48%;
  height: 44px !important;
}

.stepsWrapper .innerWrapper .checkboxArea.card-check .sbHolder .sbSelector {
  line-height: 44px !important;
  background: transparent;
}

.stepsWrapper .innerWrapper .checkboxArea.card-check .input-group-addon {
  margin-left: -1px;
  z-index: 5;
  background: #ffffff;
}

.stepsWrapper .innerWrapper .checkboxArea.card-check ul.sbOptions {
  margin-top: 0px;
  z-index: 55;
}

.stepsWrapper .innerWrapper .checkboxArea.card-check ul {
  margin: 10px 0 0 30px;
}

.stepsWrapper .innerWrapper .checkboxArea.card-check ul li {
  margin-right: 5px;
}

.stepsWrapper .shipping-info {
  padding: 10px 0 20px;
}

.stepsWrapper .shipping-info h5 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}

.stepsWrapper .shipping-info address, .stepsWrapper .shipping-info p {
  color: #797979;
  line-height: 24px;
}

.stepsWrapper .summery-box {
  border: 3px solid #f0f0f0;
  padding: 40px 25px;
  margin-bottom: 50px;
}

.stepsWrapper .summery-box h4 {
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #252525;
}

.stepsWrapper .summery-box p {
  font-size: 13px;
  padding-bottom: 5px;
  color: #797979;
}

.stepsWrapper .summery-box li {
  font-size: 15px;
  padding-bottom: 20px;
  color: #797979;
}

.stepsWrapper .summery-box li:last-child {
  border-top: 3px solid #f0f0f0;
  color: #252525;
  padding: 20px 0 0;
}

.stepsWrapper .summery-box li:last-child .val {
  font-weight: 700;
}

.stepsWrapper .review-inner form {
  margin: 0px;
}

.stepsWrapper .review-inner .table-responsive .table thead tr th {
  border-right: none;
}

.stepsWrapper .review-inner .table-responsive .table tbody tr td input.quantity {
  border: none;
  background: transparent !important;
}

.stepsWrapper .order-confirm {
  padding: 60px 0 90px;
}

.stepsWrapper .order-confirm h2 {
  font-size: 30px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 15px;
}

.stepsWrapper .order-confirm span {
  display: block;
  font-size: 13px;
  color: #797979;
}

.stepsWrapper .order-confirm p {
  font-size: 15px;
  line-height: 30px;
  color: #797979;
  margin: 42px 0 50px;
}

.stepsWrapper .order-confirm .btn {
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 15px;
}

.progress-wizard .progress-wizard-step .progress-wizard-dot:after, .progress-wizard .progress-wizard-step.disabled .progress-wizard-dot:after {
  content: ' ';
  width: 16px;
  height: 16px;
  background: #ffffff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 7px;
}

.progress-wizard {
  padding: 0 15px 50px 15px;
}

.progress-wizard .progress-wizard-step {
  padding: 0;
  position: relative;
}

.progress-wizard .progress-wizard-step .progress-wizard-stepnum {
  color: #797979;
  padding-bottom: 5px;
}

@media (max-width: 479px) {
  .progress-wizard .progress-wizard-step .progress-wizard-stepnum {
    font-size: 12px;
    height: 55px;
  }
}

@media (min-width: 480px) {
  .progress-wizard .progress-wizard-step .progress-wizard-stepnum {
    font-size: 10px;
    height: 36px;
  }
}

@media (min-width: 768px) {
  .progress-wizard .progress-wizard-step .progress-wizard-stepnum {
    font-size: 12px;
    height: auto;
  }
}

@media (min-width: 992px) {
  .progress-wizard .progress-wizard-step .progress-wizard-stepnum {
    font-size: 16px;
  }
}

.progress-wizard .progress-wizard-step .progress-wizard-info {
  color: #999;
  font-size: 14px;
}

.progress-wizard .progress-wizard-step .progress-wizard-dot {
  position: absolute;
  width: 30px;
  height: 30px;
  display: block;
  background: #47bac1;
  left: 50%;
  margin-top: -17px;
  margin-left: -15px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

@media (min-width: 480px) {
  .progress-wizard .progress-wizard-step .progress-wizard-dot {
    top: 60px;
    margin-top: -14px;
  }
}

@media (min-width: 768px) {
  .progress-wizard .progress-wizard-step .progress-wizard-dot {
    top: 43px;
    margin-top: -10px;
  }
}

.progress-wizard .progress-wizard-step .progress {
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  height: 4px;
  box-shadow: none;
  background-color: #f0f0f0;
}

@media (min-width: 480px) {
  .progress-wizard .progress-wizard-step .progress {
    margin: 23px 0 0px;
  }
}

.progress-wizard .progress-wizard-step .progress .progress-bar {
  width: 0px;
  box-shadow: none;
  background: #47bac1;
}

.progress-wizard .progress-wizard-step.complete .progress .progress-bar {
  width: 100%;
}

.progress-wizard .progress-wizard-step.complete:first-child .progress {
  left: 0;
  width: 100%;
}

.progress-wizard .progress-wizard-step.complete:first-child .progress .progress-bar {
  width: 50%;
}

.progress-wizard .progress-wizard-step.complete:last-child .progress {
  width: 100%;
}

.progress-wizard .progress-wizard-step.complete:last-child .progress .progress-bar {
  width: 100%;
}

.progress-wizard .progress-wizard-step.complete.fullBar .progress .progress-bar {
  width: 100%;
}

.progress-wizard .progress-wizard-step.active .progress .progress-bar {
  width: 50%;
}

.progress-wizard .progress-wizard-step.active .progress-wizard-stepnum {
  color: #252525;
}

.progress-wizard .progress-wizard-step.disabled .progress-wizard-dot {
  background-color: #f0f0f0;
}

/*=== 14.2 STEP # 02 ===*/
.setp5 {
  padding-bottom: 90px;
}

.setp5 .thanksContent {
  border: 5px solid #f0f0f0;
  padding: 50px 30px;
}

.setp5 .thanksContent h2 {
  display: block;
  text-align: center;
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 55px;
}

@media (min-width: 480px) {
  .setp5 .thanksContent h2 {
    font-size: 26px;
  }
}

@media (min-width: 768px) {
  .setp5 .thanksContent h2 {
    font-size: 30px;
  }
}

.setp5 .thanksContent h2 small {
  display: block;
  margin-top: 14px;
  font-size: 14px;
}

.setp5 .thanksContent h3 {
  color: #252525;
}

.setp5 .thanksContent .thanksInner {
  padding: 30px;
  min-height: 214px;
  border: 3px solid #f0f0f0;
}

.setp5 .thanksContent .thanksInner .tableBlcok {
  display: table;
  height: 154px;
}

.setp5 .thanksContent .thanksInner .tableBlcok address {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  font-size: 15px;
  font-weight: 300;
  line-height: 30px;
}

.setp5 .thanksContent .thanksInner .tableBlcok address span {
  font-weight: 400;
}

.setp5 .thanksContent .thanksInner .tableBlcok address a {
  color: #252525;
  text-decoration: none;
}

.setp5 .thanksContent .thanksInner .well {
  background-color: #47bac1;
  border: none;
  float: right;
  border-radius: 0;
  margin-bottom: 0;
  text-align: center;
  height: 154px;
  padding: 50px 0 0 0;
}

@media (max-width: 479px) {
  .setp5 .thanksContent .thanksInner .well {
    width: 100%;
  }
}

@media (min-width: 480px) {
  .setp5 .thanksContent .thanksInner .well {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .setp5 .thanksContent .thanksInner .well {
    width: 250px;
  }
}

.setp5 .thanksContent .thanksInner .well h2 {
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0;
}

.setp5 .thanksContent .thanksInner .well h2 small {
  color: #ffffff;
  font-weight: 700;
  font-size: 15px;
  margin: 0 0 13px 0;
}

/*=== 15. USER PROFILE ===*/
/*=== 15.1 COMMON PART ===*/
.userProfile .btn-group a, .userProfile .innerWrapper .list-inline li .btn-lg {
  box-shadow: none;
  background-color: #f0f0f0;
  border: none;
  border-radius: 0;
}

.stepsWrapper .innerWrapper.stepsPage .panel, .userProfile .innerWrapper .panel {
  border-radius: 0;
  border: 3px solid #f0f0f0;
  box-shadow: none;
  margin-bottom: 30px;
}

.stepsWrapper .innerWrapper.stepsPage .panel .panel-heading, .userProfile .innerWrapper .panel .panel-heading {
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  border-radius: 0;
}

.stepsWrapper .innerWrapper.stepsPage .panel .panel-heading .panel-title, .userProfile .innerWrapper .panel .panel-heading .panel-title {
  font-size: 15px;
  margin-bottom: 0;
  font-weight: 700;
  text-transform: capitalize;
  color: #252525;
}

.stepsWrapper .innerWrapper.stepsPage .panel .panel-body address, .userProfile .innerWrapper .panel .panel-body address {
  font-size: 13px;
  line-height: 30px;
  margin-bottom: 0;
}

.stepsWrapper .innerWrapper.stepsPage .panel .panel-body address strong, .userProfile .innerWrapper .panel .panel-body address strong {
  font-size: 15px;
  font-weight: 400;
}

.stepsWrapper .innerWrapper.stepsPage .panel .panel-body address span, .userProfile .innerWrapper .panel .panel-body address span {
  font-size: 15px;
}

.stepsWrapper .innerWrapper.stepsPage .panel .panel-body address a, .userProfile .innerWrapper .panel .panel-body address a {
  font-size: 15px;
  color: #252525;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.stepsWrapper .innerWrapper.stepsPage .panel .panel-body address a:hover, .userProfile .innerWrapper .panel .panel-body address a:hover {
  opacity: .8;
}

.stepsWrapper .innerWrapper.stepsPage .panel .panel-body address p, .userProfile .innerWrapper .panel .panel-body address p {
  line-height: 24px;
  margin-bottom: 0;
  color: #252525;
}

.userProfile .btn-group {
  margin-bottom: 30px;
  display: block;
}

.userProfile .btn-group a {
  float: none;
  height: 45px;
  padding: 0 20px;
  line-height: 45px;
  color: #252525;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 479px) {
  .userProfile .btn-group a {
    font-size: 10px;
    padding: 0 10px;
    margin: 0 5px 5px 0;
  }
}

@media (min-width: 480px) {
  .userProfile .btn-group a {
    font-size: 10px;
    padding: 0 10px;
    margin: 0 5px 5px 0;
  }
}

@media (min-width: 768px) {
  .userProfile .btn-group a {
    font-size: 12px;
    padding: 0 15px;
    margin: 0 5px 0 0;
  }
}

@media (min-width: 992px) {
  .userProfile .btn-group a {
    font-size: 14px;
    padding: 0 20px;
  }
}

.userProfile .btn-group a i {
  margin-right: 10px;
}

.userProfile .btn-group a:hover, .userProfile .btn-group a.active {
  background-color: #47bac1;
  color: #ffffff;
}

.userProfile .innerWrapper {
  width: 100%;
  display: block;
  border: 3px solid #f0f0f0;
  padding: 30px;
}

@media (min-width: 480px) {
  .userProfile .innerWrapper {
    margin-bottom: 90px;
  }
}

@media (min-width: 768px) {
  .userProfile .innerWrapper {
    margin-bottom: 30px;
  }
}

.userProfile .innerWrapper .alert {
  border: none;
  border-radius: 0;
  font-size: 15px;
  color: #252525;
  padding: 25px 30px;
  margin-bottom: 40px;
}

.userProfile .innerWrapper .alert .close {
  padding: 5px 12px;
  transform: translateY(-10px);
}

.userProfile .innerWrapper h3 {
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.userProfile .innerWrapper h3 span {
  color: #47bac1;
}

.userProfile .innerWrapper p {
  font-size: 14px;
  color: #888888;
  line-height: 22px;
  margin-bottom: 45px;
}

.userProfile .innerWrapper .list-inline {
  display: block;
  margin-bottom: 50px;
}

@media (max-width: 479px) {
  .userProfile .innerWrapper .list-inline li {
    padding: 0;
    display: block;
    margin-bottom: 10px;
  }
}

@media (min-width: 480px) {
  .userProfile .innerWrapper .list-inline li {
    padding: 0;
    display: block;
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .userProfile .innerWrapper .list-inline li {
    padding: 0 10px 0 0;
    display: inline-block;
    margin: 0;
  }
}

@media (min-width: 992px) {
  .userProfile .innerWrapper .list-inline li {
    padding: 0 20px 0 0;
  }
}

.userProfile .innerWrapper .list-inline li .btn-lg {
  color: #797979;
  font-weight: 700;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 479px) {
  .userProfile .innerWrapper .list-inline li .btn-lg {
    width: 100%;
    padding: 47px 0;
    font-size: 16px;
  }
}

@media (min-width: 480px) {
  .userProfile .innerWrapper .list-inline li .btn-lg {
    width: 100%;
    padding: 47px 0;
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .userProfile .innerWrapper .list-inline li .btn-lg {
    width: 120px;
    padding: 31px 0;
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .userProfile .innerWrapper .list-inline li .btn-lg {
    width: 156px;
    padding: 47px 0;
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .userProfile .innerWrapper .list-inline li .btn-lg {
    width: 196px;
    padding: 67px 0;
    font-size: 18px;
  }
}

.userProfile .innerWrapper .list-inline li .btn-lg i {
  display: block;
  margin-bottom: 20px;
}

.userProfile .innerWrapper .list-inline li .btn-lg:hover {
  background-color: #47bac1;
  color: #ffffff;
}

.userProfile .innerWrapper .list-inline li:last-child {
  padding: 0;
}

.userProfile .innerWrapper.singleOrder .orderBox {
  margin-bottom: 0;
}

.userProfile .innerWrapper.singleOrder .btn-group {
  margin-bottom: 30px;
  float: left;
}

.userProfile .innerWrapper.singleOrder .btn-group .btn-default {
  border-radius: 0;
  border: none;
  background-color: #47bac1;
  padding: 0 20px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.userProfile .innerWrapper.singleOrder .btn-group .btn-default:hover {
  opacity: .8;
}

@media (max-width: 479px) {
  .userProfile .innerWrapper.singleOrder .btn-group .btn-default {
    display: block;
    width: 100%;
    margin: 0 0 15px 0;
  }
}

@media (min-width: 480px) {
  .userProfile .innerWrapper.singleOrder .btn-group .btn-default {
    display: inline-block;
    width: auto;
    margin-right: 0px;
  }
}

@media (min-width: 768px) {
  .userProfile .innerWrapper.singleOrder .btn-group .btn-default {
    margin-right: 5px;
  }
}

.userProfile .innerWrapper.singleOrder .panel-title {
  height: 50px;
  line-height: 50px;
  padding-left: 40px;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.userProfile .innerWrapper.singleOrder .panel-body {
  padding: 30px 40px;
}

.orderBox {
  display: block;
  margin-bottom: 50px;
}

.orderBox h4 {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 700;
}

.orderBox h2 {
  font-size: 30px;
  margin-bottom: 25px;
  text-transform: capitalize;
}

.orderBox .table-responsive {
  margin-bottom: 0;
}

@media (min-width: 480px) {
  .orderBox .table-responsive {
    overflow-x: auto;
  }
}

@media (min-width: 768px) {
  .orderBox .table-responsive {
    overflow-x: visible;
  }
}

.orderBox .table-responsive .table {
  margin-bottom: 0;
}

.orderBox .table-responsive .table thead tr {
  border-left: 3px solid #47bac1;
  border-right: 3px solid #47bac1;
}

.orderBox .table-responsive .table thead tr th {
  border-width: 0px;
}

@media (max-width: 479px) {
  .orderBox .table-responsive .table thead tr th {
    padding: 10px;
    text-align: center;
    font-size: 12px;
  }
}

@media (min-width: 480px) {
  .orderBox .table-responsive .table thead tr th {
    padding: 10px;
    text-align: center;
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .orderBox .table-responsive .table thead tr th {
    padding: 10px 10px 10px 20px;
    text-align: left;
    font-size: 15px;
  }
}

.orderBox .table-responsive .table tbody tr {
  border-left: 3px solid #f0f0f0;
  border-right: 3px solid #f0f0f0;
}

.orderBox .table-responsive .table tbody tr td {
  text-transform: capitalize;
  padding-left: 5px;
  font-weight: 400;
}

@media (min-width: 768px) {
  .orderBox .table-responsive .table tbody tr td {
    padding-left: 20px;
  }
}

.orderBox .table-responsive .table tbody tr td .btn-sm {
  font-size: 13px;
  padding: 0px;
  width: 80px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
}

.orderBox .table-responsive .table tbody tr td .btn-sm:hover {
  background-color: #47bac1;
  color: #ffffff;
  border-color: #47bac1;
}

.orderBox .table-responsive .table tbody tr td:last-child {
  text-align: right;
  padding-right: 8px;
}

@media (min-width: 768px) {
  .orderBox .table-responsive .table tbody tr td:last-child {
    padding-right: 20px;
  }
}

.orderBox .table-responsive .table tbody tr td:first-child {
  color: #47bac1;
}

.orderBox .table-responsive .table tbody tr td:first-child .fa {
  color: #252525 !important;
}

.orderBox .table-responsive .table tbody tr td span.badge {
  font-size: 11px;
  border-radius: 0px;
  padding: 5px 10px;
}

.orderBox .table-responsive .table tbody tr td span.badge.badge-primary {
  background: #337ab7;
}

.orderBox.myAddress {
  margin-bottom: 10px;
}

.orderBox.myAddress .table-responsive .table thead tr th {
  font-size: 16px;
}

.orderBox.myAddress .table-responsive .table tbody tr {
  border: none;
}

.orderBox.myAddress .table-responsive .table tbody tr td {
  font-size: 13px;
}

.orderBox.myAddress .table-responsive .table tbody tr td:first-child {
  color: #252525;
}

.orderBox.myAddress .table-responsive .table tbody tr td .btn-default {
  padding: 13px 14px;
  border: none;
  background: transparent;
  color: #252525;
}

.orderBox.myAddress .table-responsive .table tbody tr:last-child td {
  border: none;
}

@media (max-width: 479px) {
  .orderBox.myAddress.wishList .table-responsive .table thead tr th:first-child {
    display: none;
  }
}

@media (min-width: 480px) {
  .orderBox.myAddress.wishList .table-responsive .table thead tr th:first-child {
    display: none;
  }
}

@media (min-width: 768px) {
  .orderBox.myAddress.wishList .table-responsive .table thead tr th:first-child {
    display: table-cell;
  }
}

.orderBox.myAddress.wishList .table-responsive .table tbody tr td {
  font-size: 16px;
}

.orderBox.myAddress.wishList .table-responsive .table tbody tr td:nth-child(4) {
  color: #47bac1;
}

.orderBox.myAddress.wishList .table-responsive .table tbody tr td .btn-sm {
  width: auto;
  padding: 0 20px;
}

@media (max-width: 479px) {
  .orderBox.myAddress.wishList .table-responsive .table tbody tr td:first-child {
    display: none;
  }
}

@media (min-width: 480px) {
  .orderBox.myAddress.wishList .table-responsive .table tbody tr td:first-child {
    display: none;
  }
}

@media (min-width: 768px) {
  .orderBox.myAddress.wishList .table-responsive .table tbody tr td:first-child {
    display: table-cell;
  }
}

/*=== 15.2 PROFILE ===*/
.profile .thumbnail .caption .btn-primary, .profile .form-horizontal .form-group .btn-primary {
  height: 45px;
  font-weight: 700;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 1px;
  padding: 0 15px;
  border-radius: 0;
  background-color: #47bac1;
  border: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.profile .thumbnail .caption .btn-primary:hover, .profile .form-horizontal .form-group .btn-primary:hover {
  opacity: .8;
  border: 0px !important;
}

.profile .orderBox {
  margin-bottom: 25px;
}

.profile .thumbnail img {
  width: 100%;
}

.profile .thumbnail .caption .btn-primary {
  margin-top: 20px;
  margin-bottom: 40px;
  line-height: 45px;
}

@media (min-width: 480px) {
  .profile .form-horizontal .form-group {
    margin-bottom: 0px;
  }
}

@media (min-width: 768px) {
  .profile .form-horizontal .form-group {
    margin-bottom: 0px;
  }
}

.profile .form-horizontal .form-group .control-label {
  font-size: 13px;
  padding-top: 0;
  font-weight: 700;
  margin-top: 3px;
}

@media (min-width: 768px) {
  .profile .form-horizontal .form-group .control-label {
    padding-left: 30px;
    text-align: right;
  }
}

@media (min-width: 992px) {
  .profile .form-horizontal .form-group .control-label {
    padding-left: 0px;
    margin-top: 10px;
  }
}

/*=== 16. COMMING SOON ===*/
.commingSoon {
  text-align: center;
  background: url(../img/comming-soon/comming-soon-bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: #282828;
}

.commingSoon .blackSection {
  padding: 90px 0 20px;
}

.commingSoon .blackSection .pageLogo {
  margin-bottom: 70px;
}

.commingSoon .blackSection h1 {
  color: #ffffff;
  text-transform: capitalize;
}

.commingSoon .blackSection h1 small {
  display: block;
  font-size: 15px;
  line-height: 26px;
  margin: 17px 0 50px 0;
}

.commingSoon .blackSection .input-group {
  margin-bottom: 50px;
}

.commingSoon .blackSection .input-group .form-control {
  background-color: transparent;
  color: #ffffff;
}

.commingSoon .blackSection .input-group a i {
  color: #ffffff;
}

.commingSoon .blackSection .list-inline li a {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.5);
  padding: 0 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.commingSoon .blackSection .list-inline li a:hover {
  color: white;
}

.count-down {
  padding: 20px 0 70px 0;
}

.count-down #simple_timer {
  width: 100%;
  margin: 0 auto;
  color: #ffffff;
  text-align: center;
}

.count-down #simple_timer .table-cell {
  display: inline-block;
}

@media (min-width: 480px) {
  .count-down #simple_timer .table-cell {
    margin: 0 10px;
  }
}

@media (min-width: 768px) {
  .count-down #simple_timer .table-cell {
    margin: 0 10px;
  }
}

@media (min-width: 992px) {
  .count-down #simple_timer .table-cell {
    margin: 0 10px;
  }
}

@media (min-width: 1200px) {
  .count-down #simple_timer .table-cell {
    margin: 0 12px;
  }
}

.count-down #simple_timer .table-cell .tab-val {
  font-weight: 700;
  display: block;
  margin-bottom: 0px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: #47bac1;
}

@media (min-width: 480px) {
  .count-down #simple_timer .table-cell .tab-val {
    width: 65px;
    height: 65px;
    line-height: 65px;
    font-size: 25px;
  }
}

@media (min-width: 768px) {
  .count-down #simple_timer .table-cell .tab-val {
    width: 85px;
    height: 85px;
    line-height: 85px;
    font-size: 35px;
  }
}

@media (min-width: 992px) {
  .count-down #simple_timer .table-cell .tab-val {
    width: 85px;
    height: 85px;
    line-height: 85px;
    font-size: 35px;
  }
}

@media (min-width: 1200px) {
  .count-down #simple_timer .table-cell .tab-val {
    width: 109px;
    height: 109px;
    line-height: 109px;
    font-size: 45px;
  }
}

.count-down #simple_timer .table-cell .tab-metr {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .count-down #simple_timer .table-cell .tab-metr {
    font-size: 15px;
  }
}

/* ELEMENTS */
/*================INDEX================

=======================================*/
/* ================================================
--> Element Single block style
================================================== */
.element_single .card.single-block {
  margin-bottom: 30px;
  border: none;
  border-radius: 0px;
  background-color: #f0f0f0;
}

.element_single .card.single-block .card-header {
  padding: 20px;
  border-bottom: none;
  border-radius: 0px;
  background-color: #f0f0f0;
  position: relative;
}

@media (min-width: 992px) {
  .element_single .card.single-block .card-header {
    padding: 20px 25px;
  }
}

.element_single .card.single-block .card-header h3 {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  max-width: 60%;
  font-style: normal;
}

@media (min-width: 768px) {
  .element_single .card.single-block .card-header h3 {
    font-size: 25px;
    max-width: 100%;
  }
}

.element_single .card.single-block .card-header h3 a {
  color: #797979;
  background: #ffffff;
  padding: 10px 10px;
  font-size: 14px;
  border-radius: 4px;
  right: 20px;
  position: absolute;
  top: 50%;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (min-width: 768px) {
  .element_single .card.single-block .card-header h3 a {
    float: right;
    right: 0px;
    position: relative;
    -moz-transform: translateY(0%);
    -o-transform: translateY(0%);
    -ms-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

.element_single .card.single-block .card-block {
  padding: 0;
}

.element_single .card.single-block .card-block .code_container {
  margin-right: 0;
  margin-left: 0;
  border-top: 1px solid rgba(2, 2, 2, 0.09);
  padding: 10px 20px;
}

.element_single .card.single-block .card-block .code_container h5 {
  font-family: 'Lato', sans-serif;
  color: #797979;
  font-size: 18px;
  line-height: 60px;
  text-transform: unset;
}

.element_single .card.single-block .card-block .code_container p {
  color: #252525;
}

.element_single .card.single-block .card-block .code_container ul {
  padding: 10px 15px 20px;
}

.element_single .card.single-block .card-block .code_container ul li {
  color: #252525;
  padding: 5px 15px;
  font-size: 15px;
  line-height: 26px;
}

.element_single .card.single-block .card-block .code_container ul li:before {
  content: "\f10c";
  /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  font-size: 14px;
  color: #797979;
  margin-left: -24px;
  /* same as padding-left set on li */
  width: 24px;
  /* same as padding-left set on li */
}

.element_single .card.single-block .card-block .code_container .s {
  color: #e6db74;
}

.element_single .card.single-block .card-block .code_container .nt {
  color: #f92672;
}

.element_single .card.single-block .card-block .code_container .na {
  color: #a6e22e;
}

.element_single .card.single-block pre {
  margin: 10px 0px;
  background-color: #282C34;
  color: #ABB2BF;
}

.element_single .card.single-block pre code {
  color: #ABB2BF;
  font-size: 14px;
}

.element_single .display-single_element {
  margin-bottom: 80px;
}

.element_single .display-single_element > p {
  font-size: 15px;
  margin-bottom: 15px;
}

.element_single .dropdown-menu {
  -webkit-transform-origin: top;
  transform-origin: top;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  background-color: #ffffff;
  margin-top: 0px;
  padding: 5px 20px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s ease-in-out;
  transition: transform 0.3s, opacity 0.3s ease-in-out, -webkit-transform 0.3s;
}

@media (min-width: 768px) {
  .element_single .dropdown-menu {
    display: none;
  }
}

@media (min-width: 768px) {
  .element_single .show .dropdown-menu {
    display: block;
  }
}

.element_single .progress {
  margin: 10px 0;
}

.element_single .bd-example {
  padding: 1.5rem;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  border-width: .2rem;
}

.element_single .scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: .5rem;
  overflow: auto;
}

/* ================================================
--> Elements collapse style
================================================== */
.card.card-accordion {
  margin: 10px 0;
}

.card.card-accordion .card-header {
  padding: 0px;
  border-radius: 0px;
  border: none;
}

.card.card-accordion .card-header h5 {
  position: relative;
}

.card.card-accordion .card-header h5 a:after {
  top: 50%;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.card.card-accordion .card-header a {
  display: block;
  padding: 15px 20px;
  line-height: 24px;
  background-color: #47bac1;
  color: #ffffff;
  border: 1px solid #47bac1;
  border-radius: 2px;
}

.card.card-accordion .card-header a[data-toggle=collapse]:after {
  font-family: FontAwesome;
  font-style: normal;
  speak: none;
  display: inline-block;
  content: '\f106';
  float: right;
  line-height: 1;
  width: 22px;
  font-size: 20px;
  text-align: center;
  position: absolute;
  right: 15px;
}

.card.card-accordion .card-header a.collapsed {
  line-height: 24px;
  color: #252525;
  background-color: #f0f0f0;
  border: 1px solid #e5e5e5;
}

.card.card-accordion .card-header a[data-toggle=collapse].collapsed:after {
  font-family: FontAwesome;
  font-style: normal;
  speak: none;
  display: inline-block;
  content: '\f107';
}

.card.card-accordion .card-block {
  border: 1px solid #e5e5e5;
  border-top: 0px;
  padding: 30px 20px;
  font-size: 15px;
  line-height: 26px;
}

.icon-left.card.card-accordion .card-header {
  padding: 0px;
  border-radius: 0px;
  border: none;
}

.icon-left.card.card-accordion .card-header a {
  display: block;
  padding: 15px 20px;
  line-height: 24px;
  padding-left: 50px;
  border-radius: 2px;
}

.icon-left.card.card-accordion .card-header.header-inverse a {
  background-color: #ffffff;
  color: #47bac1;
  border: 1px solid #47bac1;
}

.icon-left.card.card-accordion .card-header a[data-toggle=collapse]:after {
  font-family: FontAwesome;
  font-style: normal;
  display: inline-block;
  content: '\f106';
  line-height: 1;
  width: 22px;
  margin-right: 10px;
  font-size: 20px;
  text-align: center;
  position: absolute;
  left: 15px;
}

.icon-left.card.card-accordion .card-header a.collapsed {
  line-height: 24px;
  color: #252525;
  background-color: #f0f0f0;
  border: 1px solid #e5e5e5;
}

.icon-left.card.card-accordion .card-header a[data-toggle=collapse].collapsed:after {
  font-family: FontAwesome;
  font-style: normal;
  speak: none;
  display: inline-block;
  content: '\f107';
}

/*================================================
--> Element Buttons
==================================================*/
#buttons .display-single_element .btn {
  margin: 0px 5px 10px 0;
}

.btn {
  border: none;
  border-radius: 0px;
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
  line-height: 40px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
  color: #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (min-width: 992px) {
  .btn {
    font-size: 14px;
  }
}

.btn.focus, .btn:focus, .btn.active, .btn:active, .btn:hover {
  box-shadow: none;
}

.btn.btn-rounded {
  border-radius: 40px;
}

.btn i {
  font-size: inherit;
}

.btn-primary {
  background-color: #47bac1;
  border: 1px solid #47bac1;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.active, .btn-primary:active {
  background-color: #47bac1 !important;
  border: 1px solid #47bac1 !important;
}

.btn-secondary {
  background-color: #f0f0f0;
  color: #252525;
  border: 1px solid #dedede;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary.active, .btn-secondary:active {
  background-color: #f0f0f0;
  color: #252525;
  border: 1px solid #dedede;
}

.btn-success {
  background-color: #49c32c;
  border: 1px solid #49c32c;
}

.btn-success:hover, .btn-success:focus, .btn-success.active, .btn-success:active {
  background-color: #49c32c;
  border: 1px solid #49c32c;
}

.btn-info {
  background-color: #439fdb;
  border: 1px solid #439fdb;
}

.btn-info:hover, .btn-info:focus, .btn-info.active, .btn-info:active {
  background-color: #439fdb;
  border: 1px solid #439fdb;
}

.btn-warning {
  background-color: #eabf38;
  border: 1px solid #eabf38;
}

.btn-warning:hover, .btn-warning:focus, .btn-warning.active, .btn-warning:active {
  background-color: #eabf38;
  border: 1px solid #eabf38;
}

.btn-danger {
  background-color: #f55c5d;
  border: 1px solid #f55c5d;
}

.btn-danger:hover, .btn-danger:focus, .btn-danger.active, .btn-danger:active {
  background-color: #f55c5d;
  border: 1px solid #f55c5d;
}

.btn-inverse {
  background-color: #323f41;
  border: 1px solid #323f41;
}

.btn-inverse:hover, .btn-inverse:focus, .btn-inverse.active, .btn-inverse:active {
  background-color: #323f41;
  border: 1px solid #323f41;
}

.btn-primary-outlined {
  background-color: transparent;
  color: #47bac1;
  border: 1px solid #47bac1;
}

.btn-primary-outlined:hover, .btn-primary-outlined:focus, .btn-primary-outlined.active, .btn-primary-outlined:active {
  background-color: #47bac1;
  color: #ffffff;
}

.btn-secondary-outlined {
  background-color: transparent;
  color: #252525;
  border: 1px solid #dedede;
}

.btn-secondary-outlined:hover, .btn-secondary-outlined:focus, .btn-secondary-outlined.active, .btn-secondary-outlined:active {
  background-color: #f0f0f0;
  color: #252525;
}

.btn-success-outlined {
  background-color: transparent;
  color: #49c32c;
  border: 1px solid #49c32c;
}

.btn-success-outlined:hover, .btn-success-outlined:focus, .btn-success-outlined.active, .btn-success-outlined:active {
  background-color: #49c32c;
  color: #ffffff;
}

.btn-info-outlined {
  background-color: transparent;
  color: #439fdb;
  border: 1px solid #439fdb;
}

.btn-info-outlined:hover, .btn-info-outlined:focus, .btn-info-outlined.active, .btn-info-outlined:active {
  background-color: #439fdb;
  color: #ffffff;
}

.btn-warning-outlined {
  background-color: transparent;
  color: #eabf38;
  border: 1px solid #eabf38;
}

.btn-warning-outlined:hover, .btn-warning-outlined:focus, .btn-warning-outlined.active, .btn-warning-outlined:active {
  background-color: #eabf38;
  color: #ffffff;
}

.btn-danger-outlined {
  background-color: transparent;
  color: #f55c5d;
  border: 1px solid #f55c5d;
}

.btn-danger-outlined:hover, .btn-danger-outlined:focus, .btn-danger-outlined.active, .btn-danger-outlined:active {
  background-color: #f55c5d;
  color: #ffffff;
}

.btn-inverse-outlined {
  background-color: transparent;
  color: #323f41;
  border: 1px solid #323f41;
}

.btn-inverse-outlined:hover, .btn-inverse-outlined:focus, .btn-inverse-outlined.active, .btn-inverse-outlined:active {
  background-color: #323f41;
  color: #ffffff;
}

/*================================================
--> Button sizes
==================================================*/
.btn.btn-sm {
  padding: 0px 10px;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
}

.btn.btn-mid {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
}

.btn.btn-lg {
  height: 50px;
  line-height: 50px;
  padding: 0 35px;
}

.btn.btn-default {
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .btn.btn-default {
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
  }
}

.btn.btn-icon i {
  margin-right: 5px;
}

/*================================================
--> Social Buttons
==================================================*/
.btn-facebook {
  background-color: #3b5999;
}

.btn-twitter {
  background-color: #55acee;
}

.btn-social {
  font-size: 16px;
  width: 35px;
  height: 35px;
  text-align: center;
  padding: 0;
  border-radius: 0;
  margin: 0 auto;
  line-height: 32px;
  display: inline-block;
}

.btn-social i {
  margin-right: 0px;
}

.btn-social.btn-cicle {
  border-radius: 50%;
}

.btn-social.rounded {
  border-radius: 4px;
}

.btn-social.titled {
  text-transform: capitalize;
  width: auto;
  height: 35px;
  border-radius: 0px;
  padding: 0 20px;
  line-height: 35px;
}

.btn-social.titled i {
  margin-right: 5px;
}

.btn-social.facebook {
  background-color: #3b5999;
}

.btn-social.twitter {
  background-color: #55acee;
}

.btn-social.pinterest {
  background-color: #bd081c;
}

.btn-social.skype {
  background-color: #00aff0;
}

.btn-social.linkedin {
  background-color: #0077b5;
}

.btn-social.google-plus {
  background-color: #dd4b39;
}

.btn-social.youtube {
  background-color: #cc181e;
}

.btn-social.btn-social-o:hover {
  background-color: transparent;
  box-shadow: none;
}

.btn-social.btn-social-o:hover.facebook {
  color: #3b5999;
}

.btn-social.btn-social-o:hover.twitter {
  color: #55acee;
}

.btn-social.btn-social-o:hover.pinterest {
  color: #bd081c;
}

.btn-social.btn-social-o:hover.skype {
  color: #00aff0;
}

.btn-social.btn-social-o:hover.linkedin {
  color: #0077b5;
}

.btn-social.btn-social-o:hover.google-plus {
  color: #dd4b39;
}

.btn-social.btn-social-o:hover.youtube {
  color: #cc181e;
}

.btn-social.outlined {
  background-color: #ffffff;
}

.btn-social.outlined.facebook {
  color: #3b5999;
  border: 1px solid;
  border-color: #3b5999;
}

.btn-social.outlined.facebook:hover {
  background-color: #3b5999;
  color: #ffffff;
}

.btn-social.outlined.twitter {
  color: #55acee;
  border: 1px solid;
  border-color: #55acee;
}

.btn-social.outlined.twitter:hover {
  background-color: #55acee;
  color: #ffffff;
}

.btn-social.outlined.pinterest {
  color: #bd081c;
  border: 1px solid;
  border-color: #bd081c;
}

.btn-social.outlined.pinterest:hover {
  background-color: #bd081c;
  color: #ffffff;
}

.btn-social.outlined.skype {
  color: #00aff0;
  border: 1px solid;
  border-color: #00aff0;
}

.btn-social.outlined.skype:hover {
  background-color: #00aff0;
  color: #ffffff;
}

.btn-social.outlined.linkedin {
  color: #0077b5;
  border: 1px solid;
  border-color: #0077b5;
}

.btn-social.outlined.linkedin:hover {
  background-color: #0077b5;
  color: #ffffff;
}

.btn-social.outlined.google-plus {
  color: #dd4b39;
  border: 1px solid;
  border-color: #dd4b39;
}

.btn-social.outlined.google-plus:hover {
  background-color: #dd4b39;
  color: #ffffff;
}

.btn-social.outlined.youtube {
  color: #cc181e;
  border: 1px solid;
  border-color: #cc181e;
}

.btn-social.outlined.youtube:hover {
  background-color: #cc181e;
  color: #ffffff;
}

.socialLink a {
  height: 45px;
  width: 45px;
  position: relative;
  display: inline-block;
  font-size: 16px;
  background: transparent;
  color: #797979;
  line-height: 44px;
  border-radius: 50%;
  border: 1px solid rgba(132, 132, 132, 0.5);
}

.socialLink a:focus, .socialLink a:hover, .socialLink a.active, .socialLink a:active {
  background-color: #47bac1;
  border: 1px solid #47bac1;
  color: #ffffff;
  opacity: 1;
}

@media (min-width: 768px) {
  .socialLink a {
    height: 30px;
    width: 30px;
    font-size: 14px;
    line-height: 30px;
  }
}

@media (min-width: 992px) {
  .socialLink a {
    height: 45px;
    width: 45px;
    font-size: 16px;
    line-height: 44px;
  }
}

.footer-dark .socialLink a {
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.7);
  opacity: 0.7;
}

.footer-dark .socialLink a:focus, .footer-dark .socialLink a:hover, .footer-dark .socialLink a.active, .footer-dark .socialLink a:active {
  background-color: #47bac1;
  border: 1px solid #47bac1;
  color: #ffffff;
  opacity: 1;
}

.custom-btn {
  width: 100%;
}

@media (min-width: 768px) {
  .custom-btn {
    width: auto;
  }
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

/*================================================
--> Element button_group
==================================================*/
#button-group .btn-group {
  margin-bottom: 10px;
}

#button-group .btn-social {
  margin-bottom: 10px;
  margin-right: 5px;
}

.btn-group {
  margin-right: 10px;
}

.btn-group .btn-secondary:before {
  content: '';
  position: absolute;
  right: 0;
  height: 100%;
}

.btn-group .btn-primary:before {
  content: '';
  position: absolute;
  right: 0;
  height: 100%;
  border-right: 1px solid #6fbecb;
}

.btn-group .btn-success:before {
  content: '';
  position: absolute;
  right: 0;
  height: 100%;
  border-right: 1px solid #41b226;
}

.btn-group .btn-info:before {
  content: '';
  position: absolute;
  right: 0;
  height: 100%;
  border-right: 1px solid #3b90c8;
}

.btn-group .btn-warning:before {
  content: '';
  position: absolute;
  right: 0;
  height: 100%;
  border-right: 1px solid #d4ad31;
}

.btn-group .btn-danger:before {
  content: '';
  position: absolute;
  right: 0;
  height: 100%;
  border-right: 1px solid #df5354;
}

.btn-group .btn-inverse:before {
  content: '';
  position: absolute;
  right: 0;
  height: 100%;
  border-right: 1px solid #222222;
}

.btn-group .btn.btn-secondary:before,
.btn-group .btn:last-child:before {
  border-right: 0px solid rgba(20, 20, 20, 0.2);
}

/*================================================
--> Element Divider
==================================================*/
.divider-dotted {
  border-top-style: dotted;
}

.divider-dashed {
  border-top-style: dashed;
}

.divider-solid-dbl {
  height: 4px;
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.divider-dotted-dbl {
  height: 4px;
  border-top: 1px dotted;
  border-bottom: 1px dotted;
}

.divider-dashed-dbl {
  height: 4px;
  border-top: 1px dashed;
  border-bottom: 1px dashed;
}

.divider-gradient-gray {
  background-image: linear-gradient(to right, transparent, #ccc, transparent);
  height: 1px;
  border: none;
}

.w25 {
  width: 25% !important;
}

.w50 {
  width: 50% !important;
}

.w75 {
  width: 75% !important;
}

.divider {
  position: relative;
  border-top-width: 1px;
}

.border-solid {
  border-top-style: solid;
}

.divider-center {
  text-align: center;
}

.divider-center .divider-icon {
  left: auto;
  right: auto;
  margin-left: -20px;
}

.divider-right {
  text-align: right;
}

.divider-right .divider-icon {
  left: auto;
  margin-left: -40px;
}

.divider-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  top: -20px;
  display: inline-block;
  line-height: 40px;
  font-size: 20px;
  color: #d0d0d0;
  text-align: center;
  background: #ffffff;
}

.divider-gradient {
  height: 1px;
  border: none;
}

/* ================================================
--> Elements Alert style
================================================== */
.alert {
  padding: 20px;
  margin-bottom: 20px;
  border: 0px solid transparent;
  border-radius: 0;
  font-size: 15px;
}

@media (min-width: 768px) {
  .alert {
    font-size: 18px;
  }
}

.alert p {
  color: #797979;
}

.alert a.alert-link {
  color: #252525;
  font-weight: 500;
}

.alert-success {
  background-color: rgba(73, 195, 44, 0.25);
  border-color: #49c32c;
  color: #49c32c;
}

.alert-info {
  background-color: rgba(67, 159, 219, 0.25);
  border-color: #439fdb;
  color: #439fdb;
}

.alert-warning {
  background-color: rgba(234, 191, 56, 0.25);
  border-color: #eabf38;
  color: #eabf38;
}

.alert-danger {
  background-color: rgba(245, 92, 93, 0.25);
  border-color: #f55c5d;
  color: #f55c5d;
}

.iconic-alert .alert {
  padding: 10px 20px;
}

.iconic-alert i {
  height: 40px;
  width: 40px;
  display: inline-block;
  text-align: center;
  color: #ffffff;
  font-size: 15px;
  border-radius: 50%;
  line-height: 40px;
  margin-right: 10px;
}

.iconic-alert .alert-success i {
  background: #49c32c;
}

.iconic-alert .alert-info i {
  background: #439fdb;
}

.iconic-alert .alert-warning i {
  background: #eabf38;
}

.iconic-alert .alert-danger i {
  background: #f55c5d;
}

.alert-dismissible .close {
  position: relative;
  right: -1.25rem;
  padding: 18px 20px;
  color: inherit;
  font-size: 25px;
  opacity: 0.8;
}

/* ================================================
--> Elements Badge style
================================================== */
.display-single_element.element_badge p {
  margin-bottom: 10px;
  font-size: 15px;
  margin-top: 0px;
}

.display-single_element.element_badge h1, .display-single_element.element_badge h2, .display-single_element.element_badge h3, .display-single_element.element_badge h4, .display-single_element.element_badge h5 {
  margin: 0 0 15px;
}

.display-single_element.element_badge h1 {
  display: none;
}

@media (min-width: 768px) {
  .display-single_element.element_badge h1 {
    display: block;
  }
}

.display-single_element.element_badge .btn {
  text-transform: capitalize;
  margin-right: 15px;
}

.btn .badge {
  margin-left: 5px;
}

.badge {
  padding: 5px;
  font-weight: 400;
  font-size: 12px;
}

.badge-default {
  background-color: #323f41;
  color: #ffffff !important;
}

.badge-primary {
  background-color: #47bac1;
  color: #ffffff !important;
}

.badge-success {
  background-color: #49c32c;
  color: #ffffff !important;
}

.badge-info {
  background-color: #439fdb;
  color: #ffffff !important;
}

.badge-warning {
  background-color: #eabf38;
  color: #ffffff !important;
}

.badge-danger {
  background-color: #f55c5d;
  color: #ffffff !important;
}

/* ================================================
--> Elements icon style
================================================== */
.icon-v1, .icon-v2, .icon-v3, .icon-v4 {
  position: relative;
  display: inline-block;
  text-align: center;
  transition: all .2s ease-in-out;
  color: #6c6c6c;
}

.icon-xs {
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
}

.icon-sm {
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
}

.icon-v2 {
  border-style: solid;
  border: solid 1px #eee;
}

.icon-v2.icon-xs {
  width: 30px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
}

.icon-v2.icon-sm {
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
}

.icon-v3 {
  background-color: #eee;
}

.icon-v3.icon-xs {
  width: 30px;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
}

.icon-v3.icon-sm {
  width: 40px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
}

.icon-v4 {
  padding: 4px;
  background: transparent !important;
  border: solid 1px #eee;
  transition: all .1s ease-in-out;
}

.icon-v4.icon-xs {
  width: 40px;
  height: 40px;
  font-size: 12px;
  line-height: 30px;
}

.icon-v4.icon-sm {
  width: 50px;
  height: 50px;
  font-size: 14px;
  line-height: 40px;
}

.icon-v4 .icon-v4-inner {
  position: relative;
  display: block;
  overflow: hidden;
  background-color: #eee;
}

/* ================================================
--> Elements input_group style
================================================== */
.input-group .form-check-label {
  margin-bottom: 0px;
}

.input-group .input-group-addon input[type=checkbox], .input-group .input-group-addon input[type=radio] {
  margin-top: 6px;
}

.input-group .form-check {
  margin-bottom: 0px;
}

.input-group .form-check .checkbox-custom + .checkbox-custom-label:before, .input-group .form-check .radio-custom + .radio-custom-label:before {
  margin-right: 0px;
}

label {
  font-size: 15px;
}

.input-group-addon {
  border-radius: 0px;
  background: #f0f0f0;
  padding-left: 17px;
  padding-right: 17px;
  border: 1px solid #e5e5e5;
  font-size: 16px;
  color: #797979;
}

input.form-control {
  font-size: 15px;
  color: #797979;
}

.cr-addon .input-group {
  margin-bottom: 30px;
}

/*================================================
--> Element Heading
==================================================*/
[class*="heading-"] {
  position: relative;
}

.heading:before, .heading:after {
  content: "";
  position: absolute;
  left: auto;
  right: 0;
  width: 100%;
  height: 0;
  border-top-width: 1px;
  border-color: inherit;
}

.heading.heading-v1:before {
  top: 48%;
  border-top-style: solid;
}

.heading.heading-v1:after {
  top: 58%;
  border-top-style: solid;
}

.heading-v1-title {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  padding-right: 18px;
  background-color: inherit;
  z-index: 2;
}

.text-center .heading-v1-title {
  padding-right: 18px;
  padding-left: 18px;
}

.text-right .heading-v1-title {
  padding-right: 0px;
  padding-left: 18px;
}

/*==================================
card
====================================*/
#cards_element .card {
  margin-bottom: 30px;
}

#cards_element .card .display-single_element p {
  margin-bottom: -10px;
}

.card {
  border: 0px;
  border-radius: 0px;
  overflow: hidden;
}

.card .card_img {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.card .card-img {
  border-radius: 0px;
}

.card .card-title {
  margin: 0px;
  padding: 15px 0 10px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.card .card-title:hover {
  color: #47bac1;
}

.card .card-block {
  padding: 0;
}

.card .card-block p.card-text {
  padding: 15px 0px;
}

.card.img-mid .card_img {
  padding: 20px 0 20px;
}

.card.img-mid .card-title {
  padding-top: 0px;
}

.card.img-mid .card-block {
  padding: 0;
}

.card.img-mid .card-block p.card-text {
  padding: 0px 0px 15px;
}

.card.img-bottom .card_img {
  padding-top: 25px;
}

.card.img-bottom .card-title {
  padding-top: 0px !important;
}

.card .card-img-overlay p.card-text {
  color: #ffffff;
}

.card.card-inverse:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(20, 20, 20, 0.7);
}

.card ul.list-inline li {
  margin-right: 10px;
}

.card ul.list-inline li:last-child {
  margin-right: 0px;
}

.card ul.list-inline li i {
  margin-right: 5px;
}

.card.text-white .card-title, .card.text-white .card-text {
  color: #ffffff;
}

.card.card-body {
  border: 1px solid #f0f0f0;
}

.card .card-ribbon {
  width: 200px;
  padding: 2px 5px;
  position: absolute;
  text-align: center;
  font-size: 12px;
  z-index: 1;
}

.card .card-ribbon.card-ribbon-top {
  top: 20px;
}

.card .card-ribbon.card-ribbon-top.card-ribbon-left {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.card .card-ribbon.card-ribbon-top.card-ribbon-right {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.card .card-ribbon.card-ribbon-bottom {
  bottom: 20px;
}

.card .card-ribbon.card-ribbon-bottom.card-ribbon-left {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.card .card-ribbon.card-ribbon-bottom.card-ribbon-right {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.card .card-ribbon.card-ribbon-left {
  left: -70px;
}

.card .card-ribbon.card-ribbon-right {
  right: -70px;
}

/* ================================================
--> Elements countdown style
================================================== */
.count-down {
  position: relative;
}

.count-down h2 {
  font-size: 40px;
  margin-bottom: 30px;
}

.count-down .simple_timer {
  width: 100%;
  margin: 0 auto;
  color: #222222;
}

.count-down .table-cell {
  margin: 15px 15px 0 0;
  display: inline-block !important;
  text-align: center;
}

.count-down .table-cell .tab-val {
  width: 100px;
  height: 100px;
  line-height: 110px;
  font-size: 40px;
  display: inline-block !important;
  background: #f8f8f8;
  border: 1px solid #e5e5e5;
  font-weight: 500;
}

.count-down .table-cell .tab-metr {
  display: none;
}

.count-down.titled .table-cell {
  position: relative;
  height: 130px;
}

.count-down.titled .table-cell .tab-val {
  width: 130px;
  height: 130px;
  background-color: transparent;
}

.count-down.titled .table-cell .tab-metr {
  display: block !important;
  margin-top: -50px;
  line-height: 22px;
  font-size: 16px;
  text-transform: capitalize;
  color: #252525;
}

.count-down.circled .tab-val {
  border-radius: 50%;
}

.count-down.colored-bg {
  padding: 80px 50px 80px;
}

.count-down.colored-bg .tab-val {
  width: 130px;
  height: 130px;
  background-color: #ffffff !important;
  border: none;
}

.count-down.bg-image {
  position: relative;
  padding: 80px 50px 80px;
}

.count-down.bg-image .tab-val {
  width: 130px;
  height: 130px;
  background-color: #ffffff !important;
  border: none;
}

.count-down.bg-image.shadow:before {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(53, 63, 65, 0.9);
}

.fun-text {
  position: relative;
  display: block;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .fun-text {
    display: inline-block;
    margin-right: 0px;
  }
}

.fun-text:last-child {
  margin-right: 0px;
}

@media (min-width: 768px) {
  .fun-text {
    margin-right: 80px;
  }
}

.fun-text span {
  font-size: 50px;
  color: #252525;
  font-weight: 400;
  padding: 20px 0;
  display: inline-block;
}

.fun-text p {
  font-size: 16px;
  line-height: 22px;
  color: #252525;
}

.bg-primary .fun-text span, .bg-primary .fun-text p {
  color: #ffffff;
}

/* ================================================
--> Elements form style
================================================== */
.form-group {
  margin-bottom: 0px;
}

.input-group-addon {
  border-color: #f0f0f0;
  display: flex;
  align-items: center;
}

.form-control {
  height: auto;
  background-clip: border-box !important;
}

select.form-control:not([size]):not([multiple]) {
  height: unset;
}

input.form-control, textarea.form-control, select.form-control {
  border-radius: 0px;
  font-size: 14px;
  padding: 13px 20px;
  line-height: 24px;
  border: 1px solid #f0f0f0;
  background-color: #f0f0f0;
  margin-bottom: 30px;
  font-weight: 300;
}

input.form-control::-webkit-input-placeholder, textarea.form-control::-webkit-input-placeholder, select.form-control::-webkit-input-placeholder {
  color: #797979;
}

input.form-control:-moz-placeholder, textarea.form-control:-moz-placeholder, select.form-control:-moz-placeholder {
  color: #797979;
}

input.form-control::-moz-placeholder, textarea.form-control::-moz-placeholder, select.form-control::-moz-placeholder {
  color: #797979;
}

input.form-control:-ms-input-placeholder, textarea.form-control:-ms-input-placeholder, select.form-control:-ms-input-placeholder {
  color: #797979;
}

input.form-control.focus, input.form-control:focus, input.form-control.active, input.form-control:active, textarea.form-control.focus, textarea.form-control:focus, textarea.form-control.active, textarea.form-control:active, select.form-control.focus, select.form-control:focus, select.form-control.active, select.form-control:active {
  background-color: #ffffff;
  border-color: #f0f0f0;
  font-weight: 300;
  -webkit-box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.05);
}

input.form-control.is-valid.focus, input.form-control.is-valid:focus, input.form-control.is-valid.active, input.form-control.is-valid:active, input.form-control.is-invalid.focus, input.form-control.is-invalid:focus, input.form-control.is-invalid.active, input.form-control.is-invalid:active {
  background-color: #ffffff;
  border-color: default;
  font-weight: 300;
  box-shadow: default;
}

.text_input .form-group {
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .text_input .form-group {
    margin-bottom: 20px;
  }
}

.text_input .form-group label {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #252525;
}

@media (min-width: 992px) {
  .text_input .form-group label {
    font-size: 16px;
  }
}

.text_input input.form-control, .text_input textarea.form-control, .text_input select.form-control {
  margin-bottom: 0px;
}

.form-group .helpText {
  position: absolute;
  top: 18px;
  right: 25px;
}

.selectOptions {
  margin-bottom: 0px;
}

.selectOptions .sbHolder {
  background-color: transparent;
  border: none;
  margin: 0;
  border-radius: 0;
  border: 1px solid #e4e4e4;
}

.selectOptions .sbHolder, .selectOptions .sbSelector {
  width: 100% !important;
  height: 50px !important;
  line-height: 50px;
  background: #f0f0f0;
  border: none;
}

.selectOptions .sbOptions {
  width: 100% !important;
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  right: 0 !important;
  border-radius: 0;
  z-index: 100;
}

.selectOptions .sbOptions a {
  color: #797979;
  font-size: 13px;
  padding: 5px 15px;
  text-transform: uppercase;
}

.selectOptions .sbOptions a:hover {
  color: #47bac1;
  background-color: transparent;
}

.selectOptions .sbOptions li:first-child a {
  padding-top: 10px;
}

.selectOptions .sbOptions li:last-child a {
  padding-bottom: 10px;
}

.selectOptions .sbOptions .sbToggle:hover, .selectOptions .sbOptions .sbToggle:focus, .selectOptions .sbOptions .sbToggle:active {
  color: inherit;
}

.selectOptions .sbHolder > a.sbToggle:after {
  position: absolute;
  content: '\f078';
  font-family: 'FontAwesome';
  top: 0px;
  right: 10px;
  font-size: 10px;
}

.selectOptions .sbHolder > a.sbToggle.sbToggleOpen:after {
  content: '\f077';
}

.form-check-input {
  margin-left: 4px;
  margin-top: 6px;
  padding: 5px;
  opacity: 0;
}

.form-check-label {
  padding-left: 0px;
  margin-bottom: 25px;
  font-size: 15px;
  cursor: default;
  color: #252525;
}

.checkbox-custom + .checkbox-custom-label:before, .radio-custom + .radio-custom-label:before {
  content: '';
  background: #ffffff;
  border: 1px solid #dedede;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  padding: 0;
  margin-right: 10px;
  text-align: center;
  line-height: 20px;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\f00c";
  font-family: 'FontAwesome';
  color: #ffffff;
  background: #47bac1;
  border: 0px solid #47bac1;
}

.radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
  content: "\f111";
  font-size: 9px;
  font-family: 'FontAwesome';
  color: #ffffff;
  background: #47bac1;
  border: 0px solid #47bac1;
}

span.or {
  font-size: 15px;
  padding: 15px 0;
  display: block;
  color: #222222;
}

.checkbox-custom:checked + .checkbox-custom-label.default-checked:before,
.radio-custom:checked + .radio-custom-label.default-checked:before {
  background-color: #e5e5e5 !important;
  color: #797979 !important;
}

.checkbox-custom:checked + .checkbox-custom-label.disable-checked:before,
.radio-custom:checked + .radio-custom-label.disable-checked:before {
  background: #f2f2f2 !important;
  color: rgba(121, 121, 121, 0.5) !important;
}

h3.form_title {
  display: block;
  font-size: 25px;
  padding: 25px 30px;
  text-transform: capitalize;
  background: #f0f0f0;
}

form.register {
  border: 1px solid #e5e5e5;
  border-top: 0px;
  padding: 25px 30px;
}

form.register .form-check label.checkbox-custom-label {
  color: #252525;
  margin-bottom: 0px;
}

form.register .form-check label.checkbox-custom-label:before {
  width: 16px;
  height: 16px;
  line-height: 16px;
}

form.register .form-check .checkbox-custom:checked + .checkbox-custom-label:before {
  font-size: 12px;
}

form.register .btn-link {
  color: #439fdb;
  text-decoration: none;
  font-size: 15px;
  display: inline-block;
}

form.register span.or {
  position: relative;
  font-weight: 700;
}

form.register span.or:before {
  content: '';
  position: absolute;
  left: 0;
  width: 47%;
  background: #e5e5e5;
  height: 1px;
  top: 50%;
}

form.register span.or:after {
  content: '';
  position: absolute;
  right: 0;
  width: 47%;
  background: #e5e5e5;
  height: 1px;
  top: 50%;
}

form.register .btn {
  font-weight: 700;
  margin-bottom: 20px;
}

form.register .btn.btn-facebook, form.register .btn.btn-twitter {
  margin-top: 20px;
  margin-bottom: 10px;
  text-transform: unset;
}

p.form-footer {
  margin: 0;
  text-align: center;
  padding: 20px 0;
  border: 1px solid #e5e5e5;
  border-top: 0px;
  font-size: 15px;
}

.input-icon {
  position: relative;
}

.input-icon .input-group-addon {
  background-color: transparent;
  border: 0px solid transparent;
  font-size: 16px;
  color: #797979;
  position: absolute;
  top: 0;
  padding: 0px;
  z-index: 55;
  margin: 17px 0px;
  left: 15px;
}

.input-icon input {
  padding-left: 40px;
}

.input-icon.col-md-12 .input-group-addon {
  left: 27px;
}

.form-inline {
  justify-content: space-between;
}

.form-inline .input-group {
  width: 38%;
  position: relative;
}

.form-inline .uname, .form-inline .email, .form-inline .password {
  position: relative;
}

/* ================================================
--> Elements Progressbar style
================================================== */
.progress {
  border-radius: 0px;
}

.progress.bordered {
  height: 20px;
  align-items: center;
  background: transparent;
  padding: 0 5px;
  line-height: 10px;
  text-align: center;
  border: 1px solid #e5e5e5;
  border-radius: 0px;
}

.progress.bordered .progress-bar {
  background-color: #47bac1;
  border-radius: 0px;
  height: 10px;
}

.progressText {
  font-size: 15px;
}

.progress-bar {
  background-color: #47bac1;
  text-align: left;
  border-radius: 0px;
}

.tooltip {
  position: relative;
  float: right;
}

.tooltip > .tooltip-inner {
  background-color: transparent;
  padding: 8px 15px;
  color: #797979;
  font-size: 15px;
}

.tooltip > .tooltip-inner:before {
  display: none;
}

.progress-bar-primary {
  background-color: #47bac1;
}

.progress-bar-success {
  background-color: #49c32c;
}

.progress-bar-info {
  background-color: #439fdb;
}

.progress-bar-warning {
  background-color: #eabf38;
}

.progress-bar-danger {
  background-color: #f55c5d;
}

.circle1 {
  margin-top: 30px;
  position: relative;
}

.circle1 strong {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  font-size: 30px;
  margin-top: -20px;
  color: #252525;
}

.circle1 span.text {
  display: block;
  color: #252525;
  font-size: 16px;
  margin-top: 15px;
  text-transform: uppercase;
}

/* ================================================
--> Elements Nav style
================================================== */
ul.nav .nav-item .nav-link {
  font-size: 14px;
  line-height: 26px;
  text-transform: uppercase;
}

ul.nav .nav-item.active .nav-link {
  color: #47bac1;
}

ul.nav-tabs .nav-item .nav-link {
  border-radius: 0px;
  padding: 10px 20px;
  background-color: #f0f0f0;
  margin-right: 0px;
  border: 1px solid #e5e5e5;
  border-bottom: 0px;
  color: #252525;
}

ul.nav-tabs .nav-item .nav-link.active {
  border-top-color: #47bac1;
  border-top-width: 2px;
  background-color: #ffffff;
}

ul.nav-tabs .nav-item:last-child .nav-link {
  margin-right: 0px;
}

ul.nav-tabs.no-bg .nav-item .nav-link {
  background-color: transparent;
  border: 1px solid transparent;
}

ul.nav-tabs.no-bg .nav-item .nav-link.active {
  border-color: #47bac1;
  background-color: #47bac1;
  color: #ffffff;
}

ul.nav-tabs.nav-pills .nav-item .nav-link.active {
  border-color: #47bac1;
  background-color: #47bac1;
  color: #ffffff;
}

/* MODAL CUSTOMS STYLES */
@media (min-width: 992px) {
  .modal .modal-lg {
    max-width: 870px !important;
  }
}

.modal.show .modal-dialog {
  -moz-transform: translate(0, 5%);
  -o-transform: translate(0, 5%);
  -ms-transform: translate(0, 5%);
  -webkit-transform: translate(0, 5%);
  transform: translate(0, 5%);
}

.modal-content {
  -webkit-box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
  border: none;
}

.modal-content .modal-item {
  padding: 15px;
}

@media (min-width: 992px) {
  .modal-content .modal-item {
    padding: 30px;
  }
}

button.close {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  font-size: 16px;
  -webkit-appearance: none;
  position: absolute;
  right: 15px;
  z-index: 55;
}

button.close:hover, button.close.active, button.close:focus {
  outline: none;
  box-shadow: none;
}

.card_img {
  position: relative;
  overflow: hidden;
}

.quick_view {
  color: #ffffff !important;
}

.quick_view:visited, .quick_view:hover {
  color: #ffffff;
}

.quick-view-container {
  background: rgba(10, 10, 10, 0.85);
}

.quick-view-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  max-width: 980px;
  max-height: 400px;
}

.quick-view-carousel {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
}

@media (min-width: 768px) {
  .quick-view-carousel {
    top: 0;
    width: 57%;
  }
}

.fancybox-slide .fancybox-image-wrap {
  top: 10px;
}

@media (min-width: 768px) {
  .fancybox-slide .fancybox-image-wrap {
    top: 0;
  }
}

.quick-view-carousel .fancybox-stage {
  bottom: 30px;
}

.quick-view-aside {
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 50%;
  left: auto;
  width: 100%;
  padding: 0px 0 0px 0;
  background: #fff;
  color: #777;
}

@media (min-width: 768px) {
  .quick-view-aside {
    width: 43%;
    bottom: 0;
  }
}

.quick-view-aside .project_specification {
  padding: 10px 30px;
  color: #ffffff;
  position: relative;
}

@media (min-width: 768px) {
  .quick-view-aside .project_specification {
    padding: 40px 30px;
  }
}

.quick-view-aside .project_specification h2 {
  margin-bottom: 10px;
  margin-top: 0px;
  font-size: 22px;
}

@media (min-width: 768px) {
  .quick-view-aside .project_specification h2 {
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .quick-view-aside .project_specification h2 {
    margin-bottom: 30px;
    font-size: 30px;
  }
}

.quick-view-aside .project_specification .row {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .quick-view-aside .project_specification .row {
    margin-top: 15px;
  }
}

.quick-view-aside::before, .quick-view-aside::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.quick-view-aside::before {
  width: 8px;
  background: #f4f4f4;
}

.quick-view-aside::after {
  width: 0px;
  background: #e9e9e9;
}

@media (min-width: 768px) {
  .quick-view-aside::after {
    width: 1px;
  }
}

.quick-view-aside > div {
  height: 100%;
  overflow: auto;
  padding: 0 0px 0 0px;
  text-align: center;
}

@media (min-width: 768px) {
  .quick-view-aside > div {
    padding: 0 0px 0 10px;
  }
}

.quick-view-aside > div .bg-image {
  height: 100%;
}

.quick-view-close {
  position: absolute;
  top: 0px;
  right: 0;
  width: 44px;
  height: 44px;
  background: #F0F0F0;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  font-family: Arial;
  font-size: 14px;
  color: #222;
  text-indent: -99999px;
  transition: all .2s;
}

.quick-view-close:hover {
  background: #e4e4e4;
}

.quick-view-close::before,
.quick-view-close::after {
  content: '';
  position: absolute;
  top: 12px;
  left: 22px;
  width: 1px;
  height: 18px;
  background-color: #222;
}

.quick-view-close:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.quick-view-close:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.quick-view-bullets {
  list-style: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  z-index: 99999;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.quick-view-bullets li {
  display: inline-block;
  vertical-align: top;
}

.quick-view-bullets li a {
  display: block;
  height: 30px;
  width: 20px;
  position: relative;
}

.quick-view-bullets li a span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 99px;
  text-indent: -99999px;
  overflow: hidden;
  background: #d4d2d2;
}

.quick-view-bullets li.active a span {
  background: #47bac1;
}

/*
	// End of examples
*/
/* ================================================
--> Elements tabs style
================================================== */
.icon_tabs {
  margin-top: 25px;
  border: none;
  display: block;
}

@media (min-width: 768px) {
  .icon_tabs {
    justify-content: center;
    display: flex;
  }
}

.icon_tabs .nav-item {
  border: none;
  display: block;
  text-align: left !important;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .icon_tabs .nav-item {
    margin-bottom: 0px;
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .icon_tabs .nav-item {
    display: inline-block;
    margin: 0 5px;
  }
}

.icon_tabs .nav-item a.nav-link {
  -webkit-box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.08);
  border: none;
  border-radius: 0px;
  background: #fff;
  position: relative;
  color: #252525;
  white-space: nowrap;
  padding: 25px;
}

@media (min-width: 768px) {
  .icon_tabs .nav-item a.nav-link {
    padding: 20px 0px;
  }
}

@media (min-width: 992px) {
  .icon_tabs .nav-item a.nav-link {
    padding: 25px 0px;
  }
}

@media (min-width: 1200px) {
  .icon_tabs .nav-item a.nav-link {
    padding: 40px 0px;
  }
}

.icon_tabs .nav-item a.nav-link:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-bottom: 4px solid #47bac1;
}

.icon_tabs .nav-item a.nav-link i {
  font-size: 26px;
  color: #848484;
  float: left;
  line-height: 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  .icon_tabs .nav-item a.nav-link i {
    float: unset;
    font-size: 30px;
    padding-bottom: 14px;
  }
}

@media (min-width: 992px) {
  .icon_tabs .nav-item a.nav-link i {
    float: unset;
    font-size: 40px;
    padding-bottom: 20px;
  }
}

.icon_tabs .nav-item a.nav-link h3 {
  text-transform: capitalize;
  display: inline-block;
  margin-left: 15px;
  font-size: 18px;
}

@media (min-width: 768px) {
  .icon_tabs .nav-item a.nav-link h3 {
    display: block;
    margin-left: 0px;
    font-size: 15px;
  }
}

@media (min-width: 992px) {
  .icon_tabs .nav-item a.nav-link h3 {
    font-size: 18px;
  }
}

.icon_tabs .nav-item a.nav-link:hover:before, .icon_tabs .nav-item a.nav-link.active:before {
  opacity: 1;
}

.icon_tabs .nav-item a.nav-link:hover .fa, .icon_tabs .nav-item a.nav-link.active .fa {
  color: #47bac1;
}

.nav-tabs.bar-tabs {
  flex-direction: row;
}

.nav-tabs.bar-tabs .nav-item {
  margin-bottom: 0px;
}

.nav-tabs.bar-tabs .nav-link {
  border-radius: 0px;
  position: relative;
  display: inline-block;
  padding: 10px 25px;
  padding-bottom: 15px !important;
  background-color: transparent !important;
  margin-right: 0px;
  color: #252525;
  border: none !important;
}

.nav-tabs.bar-tabs .nav-link.active h5 {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #47bac1;
}

.nav-tabs.bar-tabs .nav-link.active:before {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  border-bottom: 3px solid #47bac1;
  width: 100%;
}

.tab-content .active {
  padding: 25px 0;
}

@media (min-width: 768px) {
  .nav-tabs .nav-item {
    margin-bottom: -1px;
  }
}

.tab-content.tab-bordered .active {
  border: 1px solid #e5e5e5;
  border-top: 0px solid transparent;
  padding: 25px;
}

.nav-unbordered.nav-tabs {
  border-bottom: 0px;
}

.nav-unbordered.nav-tabs .nav-item {
  padding: 10px 25px 10px 0;
}

.nav-unbordered.nav-tabs .nav-item .nav-link {
  border: 0px;
  padding: 0px;
  font-size: 18px;
  background-color: transparent;
  text-transform: capitalize;
  position: relative;
  display: inline-block;
}

.nav-unbordered.nav-tabs .nav-item .nav-link.active {
  color: #7bd1df;
  background-color: transparent;
}

.nav-unbordered.nav-tabs .nav-item .nav-link.active:before {
  content: '';
  position: absolute;
  width: 100%;
  border-bottom: 2px solid #7bd1df;
  bottom: -8px;
}

.nav.nav-tabs {
  flex-direction: column;
}

@media (min-width: 480px) {
  .nav.nav-tabs {
    flex-direction: row;
    justify-content: space-between;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .nav.nav-tabs {
    flex-direction: unset;
  }
}

.nav-stacked {
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.nav-tabs.nav-stacked {
  margin-right: 0;
  border-bottom: 0;
  border-right: none;
}

@media (min-width: 768px) {
  .nav-tabs.nav-stacked {
    border-right: 1px solid #e4e4e4;
    padding-right: 0;
  }
}

.nav-tabs.nav-stacked .nav-item {
  margin: 0 !important;
  float: none !important;
  display: block !important;
}

.nav-tabs.nav-stacked .nav-item .nav-link {
  padding: 10px 15px;
  margin-right: 0px;
  border-right: 0px;
}

.nav-tabs.nav-stacked .nav-item .nav-link i {
  padding-right: 10px;
}

.nav-tabs.nav-stacked .nav-item .nav-link.active {
  border-top-color: #e4e4e4;
  border-top-width: 1px;
  bottom: 0;
}

@media (min-width: 768px) {
  .nav-tabs.nav-stacked .nav-item .nav-link.active {
    margin-right: -1px;
    border-right-color: #fff !important;
  }
}

/* ================================================
--> Elements video style
================================================== */
.promo-video {
  position: relative;
  width: 100%;
  float: none;
  overflow: hidden;
  max-height: 400px;
  padding-top: 100%;
}

.promo-video:before {
  background-color: rgba(10, 35, 59, 0);
}

@media (min-width: 768px) {
  .promo-video {
    max-height: 500px;
    width: 100%;
    float: left;
    position: relative;
    margin: 0px;
    padding-top: 80%;
  }
}

@media (min-width: 992px) {
  .promo-video {
    padding-top: 50%;
  }
}

.promo-video .video-button {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.promo-video .video-button i {
  position: relative;
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 35px;
  border-radius: 50%;
  display: inline-block;
  color: #ffffff;
  padding-left: 10px;
  text-align: center;
  border: 2px solid #ffffff;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -ms-transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}

.promo-video .video-button:hover i {
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.video-box a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-box a iframe {
  width: 100%;
  height: 100%;
  border: none;
  position: relative;
}

.video-box a:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 35, 59, 0.4);
}

.video-box a img {
  position: relative;
  -webkit-transition: transform 0.7s ease;
  -moz-transition: transform 0.7s ease;
  -ms-transition: transform 0.7s ease;
  -o-transition: transform 0.7s ease;
  transition: transform 0.7s ease;
}

.video-box a:hover img {
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.box-video {
  position: relative;
  max-width: 100%;
  margin: 0 auto 20px;
  cursor: pointer;
  overflow: hidden;
}

.box-video .bg-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 2;
}

.box-video .video-container {
  position: relative;
  margin: 0;
  z-index: 1;
}

.box-video .btn-play {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  display: inline-block;
  width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 50%;
  text-indent: -999em;
  cursor: pointer;
  z-index: 2;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.box-video .btn-play:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  height: 0;
  width: 0;
  margin: -12px 0 0 -6px;
  border: solid transparent;
  border-left-color: #47bac1;
  border-width: 12px 20px;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

.box-video:hover .btn-play {
  transform: scale(1.1);
}

.box-video.open .bg-video {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all .6s .8s;
  transition: all .6s .8s;
}

.box-video.open .video-container {
  opacity: 1;
  -webkit-transition: all .6s .8s;
  transition: all .6s .8s;
}

.fluid-width-video-wrapper {
  width: 100%;
  position: relative;
  padding: 0;
}

.fluid-width-video-wrapper iframe, .fluid-width-video-wrapper object, .fluid-width-video-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0px;
}

p.typo {
  padding-top: 20px;
  padding-bottom: 30px;
}

mark {
  background: transparent;
  color: #f55c5d !important;
}

.styled_list {
  margin-top: 15px;
  position: relative;
  display: inline-block;
}

@media (min-width: 768px) {
  .styled_list {
    margin-top: 15px;
  }
}

.styled_list li {
  font-size: 14px;
  line-height: 30px;
}

@media (min-width: 768px) {
  .styled_list li {
    font-size: 14px;
    float: left;
    width: 50%;
  }
}

.styled_list li i {
  margin-right: 10px;
  font-size: 12px;
}

.styled_list li a {
  color: #797979;
}

@media (min-width: 768px) {
  .styled_list li a {
    font-size: 12px;
  }
}

@media (min-width: 992px) {
  .styled_list li a {
    font-size: 14px;
  }
}

ol.styled_list {
  margin-left: 15px;
}

ol.styled_list li {
  padding-left: 10px;
}

.dropcaps p {
  display: inline-block;
}

.dropcaps p .firstcharacter {
  color: #797979;
  float: left;
  font-size: 22px;
  width: 40px;
  height: 40px;
  background-color: #f0f0f0;
  text-align: center;
  line-height: 40px;
  margin-top: 9px;
  margin-right: 10px;
}
