/*=== 7. PAGE HEADER ===*/

/*=== 7.1 COMMON HEADER ===*/
.pageHeader{
	.col-md-6, .col-sm-6{
		@include mobile-xs{
			width: 100%;
			padding-top: 10px;
		}
		@include mobile{
			width: 50%;
			padding-top: 0;
		}
	}
}
.pageHeader{
	height: 70px;
	.page-title{
		h2{
			color: $text-slider-title;
			text-transform: uppercase;
			font-weight: 400;
			margin-bottom: 0;
			@include mobile-xs{
				font-size: 12px; 
				line-height: 18px;
			}
			@include mobile{
				font-size: 12px; 
				line-height: 70px;
			}
			@include tablet{
				font-size: 24px; 
			}
			@include desktops{
				font-size: 30px; 
			}
		}
	}
	ol.breadcrumb{
		background-color: transparent;
		margin-bottom: 0;
		padding: 0 ;
		display: block;
		@include mobile-xs{
			float: left !important;
			min-height: 15px;
		}
		@include mobile{
			float: right !important;
			min-height: 70px;
		}
		.active{
			color: $secondary-color;
			text-transform: uppercase;
			@include mobile{
				font-size: 9px;
			}
			@include tablet{
				font-size: 12px;
			}
		}
		li{
			display: inline-block;
			+ li::before{
				color: #ccc;
				content: "/\00a0";
				@include mobile{
					padding: 0
				}
				@include tablet{
					padding: 0 10px;
				}
			}
			a{
				
				text-transform: uppercase;
				color: #969696;
				@include transition(all 0.3s ease-in-out);
				&:hover{
					opacity: .9;
				}
				@include mobile{
					font-size: 9px;
					line-height: 15px;
				}
				@include mobile{
					font-size: 9px;
					line-height: 70px;
				}
				@include tablet{
					font-size: 12px;
				}
			}
		}
	}
}
/*=== 7.2 ABOUT US HEADER===*/
.aboutUsInfo{
	.page-header{
		border: none;
		padding: 0;
		margin: 0;
		h3{
			text-transform: capitalize;
			font-weight: 400;
			@include mobile-xs{
				margin-bottom: 20px;
			}
			@include mobile{
				margin-bottom: 20px;
			}
			@include tablet{
				margin-bottom: 40px+5px;
			}
		}
	}
}
/*=== 7.3 CART PAGE HEADER ===*/
.pageHeaderImage{
	@include background-image;
	height: 200px;
	background-image: url(../img/products/page-header-image.jpg);
	
	.tableBlock{
		display: table;
		height: 200px;
		width: 100%;
		.tableInner{
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			height: 73px;
			.page-title{
				h2{
					color: #fffefe;
					font-weight: 400;
					text-transform: uppercase;
					margin-bottom: 15px;
					line-height: 1;
					font-size: 30px;
				}
				.breadcrumb{
					background-color: transparent;
					margin-bottom: 0;
					padding: 0;
					display: block;
					li{
						text-transform: uppercase;
						color: #e6e6e6;
						display: inline-block;
						padding: 0 10px;
						position: relative;
						a {
							color: inherit;
						}
						&:first-child:after {
							content: '/';
							position: absolute;
							top: 0;
							right: -4px;
						}
					}
				}
			}
		}
	}
}