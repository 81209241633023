/*=== 9. PRODUCTS ===*/
/*=== 9.1 PRODUCTS COMMON ===*/
.productsContent{
	.productBox{
		@extend .imageBox;
		.productImage{
			@include tablet{
				width: 100%;
				height: auto;
			}
			@include desktops{
				width: 100%;
				height: auto;
			}
		}
	}
}
.filterArea{
	margin-bottom: 30px;
	.btn-group{
		.btn-default{
			height: 35px;
			padding: 0;
			line-height: 33px;
			border-radius: 0;
			border-color: $border-gray;
			margin: 0 0 0 5px;
			font-size: 15px;
			text-transform: capitalize;
			font-weight: 400;
			color: $secondary-color;
			box-shadow: none;
			@include transition(all 0.3s ease-in-out);
			
			span{
				@include mobile-xs{
					display: none;
				}
				@include mobile{
					padding-left: 8px;
					display: inline-block;
				}
			}
			i {
				@include tablet {
					margin-right: 6px;
				}
			}
			&:hover, &.active{
				background-color: $primary-color;
				color: $white-color;
				border-color: $primary-color;
			}
			@include mobile-xs{
				width: 40px;
			}
			@include mobile{
				width: 88px;
			}
		}
	}
}
.productListSingle{
	.media{
		margin-bottom: 50px;
		.media-left{
			position: relative;
			padding-right: 0;
			overflow: hidden;
			img{
				@include transition(all 0.5s ease-in-out);
			}
			.maskingImage{
				@extend .masking-hover;
				.viewBtn{
					@extend .masking-btn;
					width: 175px;
					padding: 0;
					line-height: 55px;
				}
			}
			@include mobile-xs{
				float: none !important;
				display: block;
				margin: 0 0 20px 0;
				img{
					width: 100%;
					height: auto;
				}
			}
			@include mobile{
				float: none !important;
				display: block;
				margin: 0 0 20px 0;
				img{
					width: 100%;
					height: auto;
				}
			}
			@include tablet{
				float: left !important;
				margin-right: 30px;
				display: inline-block;
				width: 226px;
				img{
					width: 100%;
					height: auto;
				}
			}
		}
		.media-body{
			.media-heading a{
				font-size: 22px;
				letter-spacing: 1px;
				line-height: 26px;
				font-weight: 400;
				margin-bottom: 20px;
				@include transition(all 0.3s ease-in-out);
				color: inherit;
				display: block;
			}
			p{
				font-size: 14px;
				color: #888888;
				line-height: 22px;
				margin-top: 5px;
				margin-bottom: 25px;
			}
			h3{
				color: #c5c5c5;
				font-weight: 700;
				margin-bottom: 25px;
				font-size: 24px;
				@include transition(all 0.3s ease-in-out);
				display: inline-block;
				margin-right: 20px;
				@include desktops{
					display: block;
					margin-right: 0;
				}
			}
			.btn-group{
				.btn-default{
					border-radius: 0;
					width: 35px;
					height: 35px;
					line-height: 33px !important;
					padding: 0;
					text-align: center;
					margin-right: 7px;
					border:1px solid $border-gray;
					background: transparent;
					@include transition(all 0.3s ease-in-out);
					i{
						@include transition(all 0.3s ease-in-out);
						color: #888888;
					}
					&:hover, &:focus{
						background-color: $primary-color;
						border-color:$primary-color;
						i{
							color: $white-color;
						}
					}
				}
			}
		}
		&:hover{
			.pull-left{
				img{
					@include transform(scale(1.2));
				}
			}
			.maskingImage{
				opacity: 1;
				.viewBtn{
					-webkit-animation: itemMove 0.3s linear;
					animation: itemMove 0.3s linear;
				}
			}
		}
		&:hover .media-heading,
		&:hover h3{
			color: $primary-color;
		}
	}
}

/*=== 9.2 SINGLE PRODUCTS ===*/
.singleProduct{
	.media{
		margin-bottom: 70px;
		.productSlider{
			@include mobile-xs{
				width: 100%;
				padding: 0 0 30px 0;
			}
			@include mobile{
				width: 100%;
				padding: 0 0 30px 0;
			}
			@include tablet{
				width: 100%;
				padding: 0 0 30px 0;
			}
			@include desktops{
				width: 489px;
				padding: 0 30px 0 0;
			}
			#carousel{
				margin-bottom: 20px;
				.item{
					img{
						width: 100%
					}
				}
			}
			#thumbcarousel{
				.thumb{
					width: 23.35%;
					cursor: pointer;
					float: left;
					@include transition(all 0.3s ease-in-out);
					@include mobile-xs{
						margin-right: 2.1%;
					}
					@include mobile{
						margin-right: 2.1%;
					}
					@include tablet{
						margin-right: 2.1%;
					}
					@include desktops{
						margin-right: 10px;
					}
					img{
						width: 100%;
					}
					&:last-child{
						margin-right: 0;
					}
					&:hover{
						opacity: .8;
					}
				}
				.carousel-control{
					display: none;
				}
			}
		}
		.media-body{
			@extend %productInfo;
			@include mobile-xs{
				display: block;
				width: 100%;
			}
			@include mobile{
				display: block;
				width: 100%;
			}
			@include desktops{
				display: table-cell;
			}
			.list-inline{
				margin-bottom: 30px;
				li{
					margin-right: 30px;
					a{
						color: $text-slider-title;
						@include transition(all 0.3s ease-in-out);
						i{
							margin-right: 8px;
						}
						&:hover{
							opacity: .8;
						}
					}
				}
			}
			h2 {
				font-size: 32px;
				letter-spacing: 2px;
				margin-bottom: 15px;
			}
			>p {
				margin: 40px 0 45px;
			}
			.btn-area{
				margin-bottom: 40px;
			}
			.tabArea {
				.nav-tabs{
					li{
						a.nav-link{
							text-transform: uppercase;
							border: none;
							line-height: 1;
							color: $text-slider-title;
							@include transition(all 0.3s ease-in-out);
							&:hover{
								color: $secondary-color;
								background-color: transparent;
							}

							@include mobile-xs{
								font-size: 10px;
								padding: 10px;
							}
							@include mobile{
								font-size: 13px;
								padding: 10px 15px;
							}
							&:before {
								bottom: -1px;
								border-bottom: 1px solid $secondary-color;
							}
							&.active{
								color: $secondary-color;
							}
						}
					}
				}
				.tab-content{
					color: $text-slider-title;
					p{
						color: $text-slider-title;
						font-size: 13px;
						line-height: 24px;
						margin-bottom: 16px;
					}
					.list-unstyled{
						padding-left: 15px;
						li{
							list-style-type: square;
							font-size: 13px;
							line-height: 24px;
						}
					}
				}
				@include mobile-xs{
					width: 100%;
				}
				@include mobile{
					width: 348px;
				}
				@include tablet {
					width: 100%;
				}
			}
		}
	}
}

// singleProductTab
.singleProductTab {
	.media {
		.media-body {
			.tabArea {
				.nav-tabs {
					a.nav-link {
						@include tablet {
							padding: 10px 50px !important;
						}
						@include desktops {
							padding: 10px 80px !important;
						}
						@include large-desktops {
							padding: 10px 100px !important;
						}
					}
				}
			}
		}
	}
}

/*=== 9.3 CART PRODUCTS ===*/
%cartInput{
	border-radius: 0;
	box-shadow: none;
	height: 40px;
	background-color: $white-color;
	@include add-border(2px, $border-gray, all);
}
%buttonFont{
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
}
.cartListInner .row.totalAmountArea{
	margin:0;
}
%tableDiv{
	display: block;
	float: left;
	padding: 20px 30px;
	width: 100%;
	@include add-border(5px, $light-color, right-left);
	@include add-border(3px, $light-color, bottom);
}
%tableHead{
	background-color: $primary-color;
	padding: 0;
	height: 55px;
	vertical-align: middle;
	text-align: left;
	font-size: 16px;
	color: $white-color;
	padding-left: 20px;
	@include add-border(1px, rgba(255,255,255,0.2), right);
	border-bottom: none;
}
%tableData{
	vertical-align: middle;
	text-transform: uppercase;
	font-weight: 700;
	@include add-border(3px, $light-color, bottom);
	border-top: none;
	position: relative;
}
%closeBtn{
	position: absolute;
	left: 0;
	float: left;
	font-size: 22px;
	margin-right: 30px;
	color: $primary-color;
	opacity: 1;
	line-height: 74px;
	font-weight: normal;
	@include transition(all 0.3s ease-in-out);
	&:hover{
		opacity: .8;
	}
	@include tablet{
		margin-right: 5px;
	}
	@include desktops{
		position: relative;
		margin-right: 30px;
	}
}
.cartListInner{
	.table-responsive{
		margin-bottom: 0;
		@include mobile{
			overflow-x: auto;
		}
		@include tablet{
			overflow-x: visible;
		}
		.table{
			margin-bottom: 0;
			thead{
				tr{
					@include add-border(3px, $primary-color, right-left);
					th{
						@extend %tableHead;
						letter-spacing: 1px;
						border-top: none;
						&:last-child{
							border-right: none;
						}
						&:first-child{
							@include mobile-xs{
								display: none;
							}
							@include mobile{
								display: none;
							}
							@include tablet{
								display: table-cell;
							}
						}
					}
				}
			}
			tbody{
				tr{
					@include add-border(3px, $light-color, right-left);
					td{
						@extend %tableData;
						font-size: 16px;
						text-transform: capitalize;
						font-weight: 400;
						@include mobile{
							padding: 10px;
						}
						@include tablet{
							padding: 10px;
						}
						@include tablet{
							padding-left: 20px;
						}
						.close{
							@extend %closeBtn;
							position: relative;
							left: 0;
							font-size: 22px;
						}
						.cartImage{
							display: inline-block;
							float: left;
						}
						&:nth-child(3){
							color: #888888;
						}
						input{
							width: 20px;
							height: 40px;
							text-align: center;
							color: #333333;
							font-weight: 700;
							border: 1px solid #e4e4e4;
							margin: 0 -3px;
							border-left: 0px;
							border-right: 0px;
							@include tablet {
								width: 40px;
							}
						}
						&:first-child{
							@include mobile-xs{
								display: none;
							}
							@include mobile{
								display: none;
							}
							@include tablet{
								display: table-cell;
							}
						}
						&.count-input a {
							color: #a5a5a5;
							font-size: 10px;
							padding: 4px 6px 0px;
							display: inline-block;
							border: 1px solid #e4e4e4;
							line-height: 34px;
							height: 40px;
							@include tablet {
								padding: 4px 10px 0px;
							}
						}
					}
				}
			}
		}
	}
	.updateArea{
		@extend %tableDiv;
		.input-group{
			float: left;
			@include mobile-xs{
				width: 100%;
				margin-bottom: 20px;
			}
			@include mobile{
				width: 100%;
				margin-bottom: 20px;
			}
			@include tablet{
				width: 435px;
				margin-bottom: 0;
			}
			.form-control{
				@extend %cartInput;
				margin-right: 10px;
				margin-right: 10px;
				border-color: $border-light;
				font-size: 13px;
				@include mobile-xs{
					width: 50%;
				}
				@include mobile{
					width: 60%;
				}
				@include tablet{
					width: 285px;
				}
			}
			.input-group-addon{
				@extend %cartInput;
				@extend %buttonFont;
				@include transition(all 0.3s ease-in-out);
				&:hover{
					opacity: .8;
				}
				@include mobile-xs{
					width: 45%;
					font-size: 10px;
				}
				@include mobile{
					width: 35%;
				}
				@include tablet{
					width: 140px;
				}
			}
		}
		.btn{
			display: inline-block;
			@include transition(all 0.3s ease-in-out);
			color: $secondary-color;
			font-size: 13px;
			font-weight: 700;
			&.btn-primary-outlined {
				&:hover {
					color: $white-color;
				}
			}
		}
	}
	.totalAmountArea{
		@extend %tableDiv;
		.list-unstyled{
			li{
				font-weight: 400;
				text-align: right;
				margin: 10px 0;
				font-size: 15px;
				span{
					width: 108px;
					display: inline-block;
					color: #888888;
				}
				.grandTotal{
					color: $secondary-color;
				}
			}
		}
	}
	.checkBtnArea{
		@extend %tableDiv;
		border-bottom-width: 5px;
		.btn-primary{
			float: right;
			letter-spacing: 1px;
			font-weight: 700;
			@include transition(all 0.3s ease-in-out);
			i{
				margin-left: 10px;
			}
			&:hover{
				opacity: .8;
			}
		}
	}
}

// SingleListStyle
.SingleListStyle {
	padding-left: 0 !important;
	li {
		list-style-type: none !important;
		font-size: 15px !important;
		color: #000 !important;
		span {
			color: #888 !important;
		}
	}
}

/*=== productSlider ===*/
.SingleProductItem {
	position: relative;
	transition: all 0.3s ease-in-out;
	&:hover {
		.SingleProductImage {
			img {
				transform: scale(1.2);
			}
		}
		.productMasking a,
		.MaskingIcon i,
		.product-content {
			opacity: 1;
		}
		.video-icon {
			a {
				border-color: #fff;
				color: #fff;
			}
		}
	}
	.SingleProductImage {
		img {
			width: 100% !important;
			transition: all .3s;
		}
	}
	.product-content {
		content: "";
		position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($primary-color, 0.9);
    opacity: 0;
    transition: all 0.3s ease-in-out;
	}
	.productMasking a {
    color: #fff;
    font-size: 16px;
    width: 50px;
    height: 50px;
    border: 2px solid #fff;
    display: inline-block;
    text-align: center;
    line-height: 46px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		transition: all 0.3s ease-in-out;
		opacity: 0;
	}
	.MaskingIcon {
		i {
			color: #fff;
			font-size: 16px;
			width: 50px;
			height: 50px;
			border: 2px solid #fff;
			display: inline-block;
			text-align: center;
			line-height: 46px;
			border-radius: 50%;
			position: absolute;
			left: 50%;
			top: 50%;
			-webkit-transform: translate(-50%, -50%);
			transform: translate(-50%, -50%);
			transition: all 0.3s ease-in-out;
			opacity: 0;
		}
	}
	.video-icon {
		position: absolute;
		top: 20px;
		right: 20px;
		z-index: 10;
		a {
			width: 50px;
			height: 50px;
			line-height: 48px;
			border: 2px solid $primary-color;
			border-radius: 50%;
			color: $primary-color;
			display: inline-block;
			text-align: center;
			transition: all 0.3s ease-in-out;
		}
	}
}

// share-all
.share-all {
	.all-link {
		visibility: hidden;
		opacity: 0;
		transition: .5s;
		&.active {
      visibility: visible;
			opacity: 1;
			margin-left: 25px;
    }
	}
}

// SingleSocialIcon
.SingleSocialIcon {
	border: 1px solid $text-slider-title;
	width: 30px;
	height: 30px;
	line-height: 30px;
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	margin-right: 10px;
	&:hover {
		background-color: $primary-color;
		border-color: $primary-color;
		color: #fff !important;
	}
	&:last-child {
		margin-right: 0;
	}
	i {
		margin-right: 0 !important;
	}
}

// ReviewsPost
.ReviewsPost {
	font-size: 13px;
}

// sbOptions
.quantityWidth.resizeWidth .sbHolder, .quantityWidth.resizeWidth .sbSelector, .quantityWidth.resizeWidth .sbOptions {
  width: 196px !important;
}
.single-quick-drop {
    margin: 0 0px 30px 0 !important;
}

// form sbOption
.singleFormWidth.resizeWidth .sbHolder, .singleFormWidth.resizeWidth .sbSelector, .singleFormWidth.resizeWidth .sbOptions {
	width: 100% !important;
	background-color: #f0f0f0;
	border-color: transparent;
}
.singleFormWidth .sbOptions {
	background-color: #fff !important;
	box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
}
.singleFormWidth .sbToggle {
	z-index: 10;
}
.singleFormWidth .sbHolder, .singleFormWidth .sbSelector {
	height: 38px !important;
}

// SingleCartListInner count-input
.SingleCartListInner.cartListInner .table-responsive .table tbody tr td input {
	width: 33px;
	color: $text-slider-title;
	border-color: $border-gray;
	margin: 0 -4px;
  font-weight: 400;
}
.SingleCartListInner.cartListInner .table-responsive .table tbody tr td.count-input a {
	padding: 4px 13px 0px;
	border: 1px solid $border-gray;
}
.SingleCartListInner.cartListInner .table-responsive .table tbody tr td:first-child {
	display: block;
}

// SingleCartListInner table
.SingleCartListWrapper .cartListInner .table-responsive .table tbody tr td:first-child,
.SingleCartListWrapper .cartListInner .table-responsive .table thead tr th:first-child {
  display: table-cell;
}

// SingleCartSizing
.SingleCartSizing .cartListInner .table-responsive .table tbody tr td,
.SingleCartSizing .cartListInner .table-responsive .table thead tr th {
	text-align: center;
}

.SingleCartSizing .cartListInner .table-responsive .table tbody tr td {
	padding: 16px 0 !important;
}

.SingleCartSizing .cartListInner .table-responsive .table thead tr th {
	padding-left: 0 !important;
}

// SingleCartShipping
.SingleCartShipping .cartListInner .table-responsive .table tbody tr td,
.SingleCartShipping .cartListInner .table-responsive .table thead tr th {
	font-size: 14px;
	@include tablet{
		font-size: 16px;
	}
}

.SingleCartShipping .cartListInner .table-responsive .table tbody tr td {
	padding: 16px 0 16px 15px !important;
	@include tablet{
		padding: 16px 0 16px 20px !important;
	}
}

.SingleCartShipping .cartListInner .table-responsive .table thead tr th {
	padding: 10px;
	@include tablet{
		padding: 20px;
	}
}

// single-custom-control
.single-custom-control .custom-control-input:checked~.custom-control-label::before {
  background-color: $primary-color;
  box-shadow: none !important;
}