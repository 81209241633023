/*=== 14. STEPS ===*/
/*=== 14.1 STEP # 01 ===*/
.stepsWrapper{
	.innerWrapper{
		border: 3px solid $light-color;
		display: block;
		padding: 30px 30px 0;
		width: 100%;
		&.stepsPage{
			@include mobile{
				margin-bottom: 90px;
			}
			@include tablet{
				margin-bottom: 30px;
			}
			.panel{
				@extend %panel-style;
			}
			.cartListInner{
				margin-bottom: 50px;
				display: block;
			}
		}
		.well{
			border-radius: 0;
			border: none;
			box-shadow: none;
			margin-bottom: 50px;
			background: $light-color;
			padding: 20px 30px;
			.pager{
				margin: 0;
				li{
					a{
						font-weight: 700;
						letter-spacing: 1px;
						text-transform: uppercase;
						@include transition(all 0.3s ease-in-out);
						&:hover{
							opacity: 0.8;
						}
						i {
							font-size: 18px;
							padding-left: 5px;
						}
						&.hideContent{
							display: none;
						}
					}
					&.previous{
						a{
							background-color: #dedede;
							color: $secondary-color;
							&:hover{
								background-color: $primary-color;
								color: $white-color;
							}
						}
					}
				}
			}
		}
		.orderBox{
			.table-responsive{
				.table{
					tbody{
						tr{
							td{
								text-transform: capitalize;
								.checkboxArea{
									margin: 0;
									float: none;
								}
							}
						}
					}
				}
			}
		}
		
		.step2.checkboxArea {
			margin-bottom: 25px;
			label {
				font-size: 13px;
				color: $text-slider-title;
			}
		}
		.checkboxArea.card-check {
			width: 100%;
			.form-control {
				background: $white-color;
				border: 1px solid $light-color;
				height: 44px;
			}
			span.step-drop {
				display: flex;
				justify-content: space-between;
			}
			.sbHolder {
				border: 1px solid $light-color;
				background: $white-color;
				max-width: 48%;
				height: 44px!important;
				.sbSelector {
					line-height: 44px!important;
					background: transparent;
				}
			}
			.input-group-addon {
				margin-left: -1px;
				z-index: 5;
				background: $white-color;
			}
			ul.sbOptions {
				margin-top: 0px;
				z-index: 55;
			}
			ul {
				margin: 10px 0 0 30px;
				li {
					margin-right: 5px;
				}
			}
		}
	}
	.shipping-info {
		padding: 10px 0 20px;
		h5 {
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 10px;
		}
		address, p {
			color: $text-slider-title;
			line-height: 24px;
		}
	}
	.summery-box {
		border: 3px solid $light-color;
		padding: 40px 25px;
		margin-bottom: 50px;
		
		h4 {
			font-size: 22px;
			margin-bottom: 15px;
			font-weight: 400;
			letter-spacing: 0px;
			color: $secondary-color;
		}
		p {
			font-size: 13px;
			padding-bottom: 5px;
			color: $text-slider-title;
		}
		li {
			font-size: 15px;
			padding-bottom: 20px;
			color: $text-slider-title;
			&:last-child {
				border-top: 3px solid $light-color;
				color: $secondary-color;
				padding: 20px 0 0;
				.val {
					font-weight: 700;
				}
			}
		}
	}
	.review-inner {
		form {
			margin: 0px;
		}
		.table-responsive .table thead tr th {
			border-right: none;
		}
		.table-responsive .table tbody tr td {
			input.quantity {
				border: none;
				background: transparent!important;
			}
		}
	}
	.order-confirm {
		padding: 60px 0 90px;
		h2 {
			font-size: 30px;
			line-height: 1;
			font-weight: 700;
			margin-bottom: 15px;
		}	
		span {
			display: block;
			font-size: 13px;
			color: $text-slider-title;
		}
		p {
			font-size: 15px;
			line-height: 30px;
			color: $text-slider-title;
			margin: 42px 0 50px;
		}
		.btn {
			font-weight: 700;
			letter-spacing: 2px;
			font-size: 15px;
		}
	} 
}
%after{
	content: ' '; 
	width: 16px;
    height: 16px;
	background: $white-color; 
	@include border-radius($radius: 50%);
	position: absolute;
    top: 7px;
    left: 7px;
}
.progress-wizard { 
	padding: 0 15px 50px 15px;
	.progress-wizard-step {
		padding: 0; 
		position: relative;
		.progress-wizard-stepnum {
			color: $text-slider-title;
			padding-bottom: 5px;
			@include mobile-xs{
				font-size: 12px;
				height: 55px;
			}
			@include mobile{
				font-size: 10px;
				height: 36px;
			}
			@include tablet{
				font-size: 12px;
				height: auto;
			}
			@include desktops{
				font-size: 16px;
			}
		}
		.progress-wizard-info {
			color: #999; 
			font-size: 14px;
		}
		.progress-wizard-dot {
			position: absolute; 
			width: 30px; 
			height: 30px; 
			display: block; 
			background: $primary-color; 
			left: 50%; 
			margin-top: -17px; 
			margin-left: -15px; 
			@include border-radius($radius: 50%);
			&:after {
				@extend %after;
			} 
			@include mobile{
				top: 60px;
				margin-top: -14px; 
			}
			@include tablet{
				top: 43px; 
				margin-top: -10px;
			}
		}
		.progress {
			position: relative; 
			@include border-radius(0);
			height: 4px; 
			box-shadow: none;
			background-color: $light-color;
			@include mobile {
				margin: 23px 0 0px;
			}
			.progress-bar {
				width:0px; 
				box-shadow: none; 
				background: $primary-color;
			}
		}
		&.complete{
			.progress{
				.progress-bar{
					width:100%;
				}
			}
			&:first-child{
				.progress{
					left: 0; 
					width: 100%;
					.progress-bar{
						width:50%;

					}
				}
			}
			&:last-child{
				.progress{
					width: 100%;
					.progress-bar{
						width:100%;
					}
				}
			}
			&.fullBar{
				.progress{
					.progress-bar{
						width:100%;
					}
				}
			}
		}
		&.active{
			.progress{
				.progress-bar{
					width:50%;
				}
			}
			.progress-wizard-stepnum {
				color: $secondary-color;
			}
		}
		&.disabled{
			.progress-wizard-dot {
				background-color: $light-color;
				&:after {
					@extend %after;
				}
			}
		}
	}
}
/*=== 14.2 STEP # 02 ===*/
.setp5{
	padding-bottom: 90px;
	.thanksContent{
		@include add-border(5px, $light-color, all);
		padding: 50px 30px;
		h2{
			display: block;
			text-align: center;
			text-transform: capitalize;
			font-weight: 700;
			margin-bottom: 50px+5px;
			@include mobile{
				font-size: 20px+6px;
			}
			@include tablet{
				font-size: 30px;
			}
			small{
				display: block;
				margin-top: 14px;
				font-size: 14px;
			}
		}
		h3{
			@extend %sectionTitleSmall;
			color: $secondary-color;
		} 
		.thanksInner{
			padding: 30px;
			min-height: 214px;
			@include add-border(3px, $light-color, all);
			.tableBlcok{
				display: table;
				height: 154px;
				address{
					display: table-cell;
					vertical-align: middle;
					text-align:left;
					font-size: 15px;
					font-weight: 300;
					line-height: 30px;
					span{
						font-weight: 400;
					}
					a{
						color: #252525;
						text-decoration: none;
					}
				}
			}
			.well{
				background-color: $primary-color;
				border: none;
				float: right;
				border-radius: 0;
				margin-bottom: 0;
				text-align: center;
				height: 154px;
				padding: 50px 0 0 0;
				@include mobile-xs{
					width: 100%;
				}
				@include mobile{
					width: 100%;
				}
				@include tablet{
					width: 250px;
				}
				h2{
					color: $white-color;
					font-weight: 700;
					margin-bottom: 0;
					small{
						color: $white-color;
						font-weight: 700;
						font-size: 15px;
						margin: 0 0 13px 0;
					}
				}
			} 
		} 
	}
}