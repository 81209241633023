/*=== 12. BLOG ===*/
.blogPage{
	.thumbnail{
		padding-bottom: 50px;
		margin-bottom: 50px;
		@include add-border(3px, $light-color, bottom);
		img{
			margin-bottom: 50px;
			max-width: 100%;
		}
		.carousel{
			.carousel-inner{
				.item{
					img{
						margin-bottom: 0;
					}
				}
			}
			.carousel-control{
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				width: auto;
				font-size: 20px;
				color: #fff;
				text-align: center;
				text-shadow: 0 1px 2px rgba(0,0,0,.6);
				background-color: rgba(0,0,0,0);
				&.left {
					left: 50px;
				}
				&.right {
					right: 50px;
					left: auto;
				}
				span{
					@include transition(all 0.3s ease-in-out);
					width: 45px;
					height: 45px;
					background-color: $light-color;
					opacity: 0.5;
					border-radius: 0;
					display: block;
					line-height: 45px;
					font-size: 15px;
					text-shadow: none;
					color: $secondary-color;
					margin-top: -40px;
					
					&:hover {
						opacity: 1;
					}
				}
			}
			.carousel-control.right,
			.carousel-control.left{
				background-image:none;
			}
		}
		.caption{
			padding-top: 0;
			h5{
				float: left;
				width: 100%;
				margin: 0 0 25px 0;
				text-transform: capitalize;
				@include mobile{
					margin: 0 0 10px 0;
				}
				@include tablet{
					margin: 30px 0 10px 0;
				}
			}
			.list-unstyled{
				width: 100%;
				@include mobile-xs{
					display: block;
					margin-bottom: 20px;
					float: left;
				}
				@include mobile{
					display: block;
					margin-bottom: 20px;
					float: left;
				}
				@include tablet{
					display: block;
					margin-bottom: 0;
				}
				li{
					color: $text-slider-title;
					font-size: 14px;
					line-height: 22px;
					@include mobile{
						display: inline-block;
						float: left;
						width: 100%;
					}
					@include tablet{
						display: inline-block;
						float: left;
						width: 100%;
					}
					a{
						color: $text-slider-title;
						@include transition(all 0.3s ease-in-out);
						&:hover{
							opacity: .8;
						}
						i{
							margin-right: 13px;
						} 
					}
					i{
						margin-right: 13px;
					}
				}
			}
			h3{
				text-transform: capitalize;
				margin-bottom: 30px;
				font-weight: 700;
				line-height: 32px;
				a{
					color: $secondary-color;
					@include transition(all 0.3s ease-in-out);
					&:hover{
						opacity: .8;
					}
				}
			}
			p{
				font-size: 14px;
				color: $text-slider-title;
				line-height: 22px;
			}
			.list-inline{
				margin: 0 0 20px 0;
				float: left;
				li{
					padding: 0 10px 0px 0;
					@include mobile {
						padding: 0 10px 10px 0;
					}
					float: left;
					a{
						text-align: center;
						display: block;
						color: $white-color;
						font-size: 14px;
						@include transition(all 0.3s ease-in-out);
						&:hover{
							opacity: .9;
						}
						@include mobile-xs{
						  width: 35px;
						  height: 35px;
						  line-height: 35px;
						}
						@include mobile{
						  width: 35px;
						  height: 35px;
						  line-height: 35px;
						}
						@include tablet{
							width: 27px;
							height: 27px;
							line-height: 27px;
						}
						@include desktops{
						  width: 27px;
						  height: 27px;
						  line-height: 27px;
						}
						@include large-desktops{
						  width: 35px;
						  height: 35px;
						  line-height: 35px;
						}
					}
					&:first-child{
						a{
							background-color: $facebook-color;
						}
					}
					&:nth-child(2){
						a{
							background-color: $twitter-color;
						}
					}
					&:nth-child(3){
						a{
							background-color: $dribbble-color;
						}
					}
					&:last-child{
						a{
							background-color: $tumblr-color;
						}
					}
				}
			}
		}
		.pager{
			background-color: $light-color;
			padding: 20px;
			margin: 30px+6px 0 60px 0;
			li{
				display: inline;
				a{
					display: inline-block;
					text-align: center;
					border-radius: 0;
					background-color: #dedede;
					height: 45px;
					padding: 0;
					line-height: 45px;
					font-weight: 700;
					text-transform: uppercase;
					color: $secondary-color;
					font-size: 15px;
					@include transition(all 0.3s ease-in-out);
					border:none;
					@include mobile-xs{
						width: 110px;
					}
					@include mobile{
						width: 136px;
					}
					&:hover{
						opacity: .8;
					}
				}
			}
		}
	}
	.pagination{
		margin: 0 0 50px 0;
		li{
			a{
				float: none;
				border-radius: 0;
				padding: 0;
				@include add-border(3px, $light-color, all);
				display: inline-block;
				font-size: 18px;
				color: $secondary-color;
				font-weight: 700;
				@include transition(all 0.3s ease-in-out);
				margin: 0 3px;
				text-align: center !important;
				@include mobile-xs{
					width: 35px;
					height: 35px;
					line-height: 30px;
				}
				@include mobile{
					width: 45px;
					height: 45px;
					line-height: 40px;
				}
				span{
					font-size: 24px;
					@include mobile-xs{
						line-height: 20px;
					}
					@include mobile{
						line-height: 30px;
					}
				}
				&:hover{
					color: $white-color;
					background-color: $primary-color;
					border-color: $primary-color;
				}
			}
		}
		li.active{
			a, a:hover, a:focus{
				color: $white-color;
				background-color: $primary-color;
				border-color: $primary-color;
			}
		}
	}
}
%sectionTitle{
	font-weight: 400;
	text-transform: capitalize;
	margin-bottom: 30px;
}
.commentsArea{
	h3{
		@extend %sectionTitle;
	}
	.media{
		margin: 0 0 50px 0;
		a{
			padding-right: 20px;
		}
		.media-body{
			.media{
				margin: 50px 0 0 0;
			}
			h4, p{
				text-transform: capitalize;
				font-size: 14px;
				margin: 0 0 14px 0;
				display: block;
				span{
					color: $text-slider-title;
					font-weight: 400;
					i{
						margin-right: 14px;
					}
				}
			}
			p{
				color: $text-slider-title;
				line-height: 22px; 
			}
			.btn-link{
				padding: 0;
				color: $text-slider-title;
				@include transition(all 0.3s ease-in-out);
				text-transform: capitalize;
				font-size: 14px;
				&:hover{
					color: $primary-color;
					text-decoration: none;
				}
			}
		}
	}
}
.singleBlog{
	.thumbnail{
		padding-bottom: 0;
	}
}
