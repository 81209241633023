/*=== 4.1 FOOTER V1 ===*/

/*=== 4.1.1 MAIN FOOTER ===*/
.footer{
	display: block;
	width: 100%;
	padding: 38px 0; 
	background-color: $secondary-color;
	@include add-border(8px, $primary-color, top);
	.footerLink{
		@include mobile-xs{
			text-align: center;
			margin-bottom: 25px;
		}
		@include mobile{
			text-align: center;
			margin-bottom: 25px;
		}
		@include tablet{
			text-align: left;
			margin-bottom: 0;
		}
		h5{
			letter-spacing: 1px;
			color: #5f5f5f;
			text-transform: uppercase;
			margin-bottom: 13px;
		}
		ul{
			li{
				color: #acacac;
				font-size: 12px;
				line-height: 20px;
				padding-left: 5px;
				padding-right: 5px;
				a{
					color: #acacac;
					font-size: 12px;
					line-height: 20px;
					&:hover{
						color: rgba($white-color,0.5);
					}
				}
			}
		}
	}
	.newsletter{
		display: block;
		text-align: center;
		@include mobile{
			float: none;
			margin: 0 auto;
			margin-bottom: 25px;
			width: 75%;
		}
		@include tablet{
			float: right;
			margin-bottom: 0;
			width: 90%;
		}
		@include desktops{
			width: 75%;
		}
		h4{
			color: #5f5f5f;
			font-size: 18px;
			text-transform: uppercase;
			margin-bottom: 0;
		}	
		h3{
			color: #acacac;
			text-transform: uppercase;
			margin-bottom: 10px;
			font-size: 24px;
		}
		p{
			color: #acacac;
		}
		.input-group{
			display: block;
			width: 100%;
			.form-control, .input-group-addon{
				float: left;
				margin-right: 2%;
				border-radius: 0;
				border: none;
				background-color: #3b3b3b;
				height: 40px;
				line-height: 40px;
				padding: 0 14px;
				font-size: 13px;
				color: #999999;
				&:focus{
					box-shadow: none;
				}
				@include mobile-xs{
					width: 73%;
				}
				@include mobile{
					width: 73%;
				}
				@include tablet{
					width: 63%;
				}
				@include desktops{
					width: 65%;	
					margin-right: 5%;
				}
			}
			.input-group-addon{
				display: inline-block;
				margin-right: 0;
				height: 40;
				padding: 0;
				text-align: center;
				line-height: 36px;
				border:3px solid $primary-color;
				font-size: 14px;
				text-transform: uppercase;
				font-weight: 700;
				color: $white-color;
				@include transition(all 0.3s ease-in-out);
				@include mobile-xs{
					width: 25%;
				}
				@include mobile{
					width: 25%;
				}
				@include tablet{
					width: 35%;
				}
				@include desktops{
					width: 27%;	
				}
				i{
					@include transition(all 0.3s ease-in-out);
					color: $primary-color;					
					@include mobile{
						margin-left: 7px;	
					}
					@include tablet{
						margin-left: 3px;	
					}
					@include desktops{
						margin-left: 7px;	
					}
				}
				&:hover{
					background-color: $primary-color;
					border-color: $primary-color;
					color: $white-color;
					i{
						color: $white-color;
					}
				}
			}
		}
	}
}
.footer .footerLink{
	.list-inline{
		margin-top: 25px;
		li a{
			font-size: 17px;
		}
	}
}
/*=== 4.1.2 COPY RIGHT ===*/
.copyRight{
	display: block;
	width: 100%;
	min-height: 53px;
	background-color: #101010;
	p{
		color: #acacac;
		margin-bottom: 0;
		font-size: 13px;
		padding-top:17px;
		@include mobile-xs{
			text-align: center;
			font-size: 11px;
		}
		@include mobile{
			text-align: center;
		}
		@include tablet{
			text-align: left;
		}
		a {
			color: inherit;
		}
	}
	ul{
		@include mobile-xs{
			text-align: center;
		}
		@include mobile{
			text-align: center;
		}
		@include tablet{
			text-align: right;
		}
		
		li{
			line-height: 53px;
			padding-left: 5px;
			padding-right: 5px;
			a{
				display: block;
				line-height: 53px;
				font-size: 14px;
			}
		}
	}
}