* {
    outline: 0;
    margin: 0;
    padding: 0;
}
html, * {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import url("https://fonts.googleapis.com/css?family=Oxygen:400,300,700");
body {
    font-family: 'Oxygen', sans-serif;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-color;
    font-weight: 400;
}
ul{
	margin:0;
	padding:0;
	li{
		list-style-type:none;
	}
}
p{
    font-size: 13px;
    color: $secondary-color;
    font-weight: 400;
    line-height: 18px;
}
h1, h2, h3, h4, h5{
    font-family: 'Oxygen', sans-serif;
    color: $secondary-color;
    margin-top: 0;
    margin-bottom: 0px;
}
h4{
   font-size: 16px;
   font-weight: 700;
}
h5{
   font-size: 14px;
   font-weight: 700; 
}
