/*=== 4.2 FOOTER V2 ===*/

/*=== 4.2.1 MAIN FOOTER ===*/
.footer.version2{
	background-color: #f5f5f5;
	.footerLink{
		ul{
			li{
				color: $secondary-color;
				a{
					color:$secondary-color;
					&:hover{
						color: #000;
					}
				}
			}
		}
	}
	.footerLink{
		.list-inline li a{
			color:#8d8d8d;
		}
	}
	.newsletter{
		h3{
			color: $secondary-color;
		}
		p{
			color: $secondary-color;
		}
		.input-group{
			.form-control, .input-group-addon{
				background-color: #e9e9e9;
			}
			.input-group-addon{
				color: $secondary-color;
				&:hover{
					background-color: $primary-color;
					border-color: $primary-color;
					color: $white-color;
					i{
						color: $white-color;
					}
				}
			}
		}
	}
}

/*=== 4.2.2 COPY RIGHT ===*/
.copyRight.version2{
	background-color: #ececec;
	p{
		color: $secondary-color;
		a{
			color: $secondary-color;
			&:hover{
				color: $primary-color;
			}
		}
	}
}