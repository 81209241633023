/*=== 5. FORM ===*/
/*=== 5.1 FORM COMMON ===*/
%formFieldPart{
	border-radius: 0;
	background-color: $light-color;
	border: none;
	height: 44px;
	line-height: 44px;
	box-shadow: none;
	&:focus{
		box-shadow: none;
	}
}

.form-field{
	padding: 15px 30px;
	.form-group{
		margin-bottom: 30px;
		label {
			font-size: 13px;
		}
		.form-control{
			@extend %formFieldPart;
		}
	}
	.checkbox{
		margin: 0 0 30px 0;
		label{
			font-size: 13px;
		}
	}
	.btn-primary{
		@include transition(all 0.3s ease-in-out);
		&:hover{
			opacity: .9;
		}
	}
	.btn-link{
		color: $primary-color;
		text-transform: capitalize;
		@include transition(all 0.3s ease-in-out);
		&:hover{
			text-decoration: none;
			color: $primary-color-light;
		}
	}
}
.form-body{
	.panel{
		border-radius: 0;
		border: none;
		.panel-heading{
			background-color: $primary-color;
			border-radius: 0;
			padding: 0 15px;
			height: 60px;
			h3{
				text-align: center;
				text-transform: capitalize;
				margin-bottom: 0;
				font-weight: 400;
				color: $white-color;
				line-height: 60px;
			}
		}
		.panel-body{
			@include add-border(3px, $light-color, all);
			border-top: none;
			@extend .form-field;
			.btn-link{
				span{
					color: $text-slider-title;
				}
			}
			.form-check {
				.form-check-label {
					font-size: 13px;
					font-weight: 400;
					&:before {
						margin-top: -4px;
						background: $light-color;
						border: none;
					}
				}
			}
			.checkbox-custom:checked + .checkbox-custom-label:before {
				content: "\f00c";
				font-family: 'FontAwesome';
				color: #ffffff;
				background: $primary-color;
				border: 0px solid $primary-color;
			}
		}
	}	
}

/*=== 5.2 FORM SIGN UP ===*/
.signUp{
	@extend .form-body;
	@include mobile{
		padding: 40px+5px 0 25px 0;
	}
	@include mobile{
		padding: 90px 0 70px 0;
	}
	.btn-primary {
		letter-spacing: 2px;
		font-weight: 700;
	}
}

/*=== 5.3 FORM LOST PASSWORD ===*/
.lostPassword{
	@extend .form-body;
	.panel-body{
		padding: 50px 30px !important;  
	}
	.help-block{
		color: $secondary-color;
		font-size: 13px;
		line-height: 22px;
		margin: 0 0 20px 0;
	}
	.btn {
		letter-spacing: 2px;
		font-weight: 700;
	}
	@include mobile{
		padding: 40px+5px 0 25px 0;
	}
	@include mobile{
		padding: 90px 0 70px 0;
	}
}

/*=== 5.4 FORM LOG IN ===*/
.logIn{
	@extend .form-body;
	.btn-default{
		text-transform: uppercase;
		@include transition(all 0.3s ease-in-out);
		font-weight: 700;
		letter-spacing: 1px;
		i{
			margin-right: 5px;
		}
		&:hover,&:active, &:focus{
			opacity: .9;
			color: $white-color;
		}
		@include mobile-xs{
			width: 100%;
			font-size: 11px; 
		}
		@include mobile{
			width: 48%;
			font-size: 11px; 
		}
		@include desktops{
			font-size: 13px; 
		}
		@include large-desktops{
			font-size: 16px; 
		}
	}
	.btn.btn-block {
		width: 100%;
	}
	.social-login {
		margin: 30px 0 20px;
		min-height: 45px;
		
		.btn.btn-social {
			height: 45px;
			line-height: 45px;
			font-size: 14px;
			margin-bottom: 10px;
			display: inline-block;
			max-width: 100%;
			text-align: center;
			text-transform: uppercase;
			font-weight: 700;
			letter-spacing: 1px;
		}
	}
	.btn-primary{
		color: $white-color;
	}
	@include mobile{
		padding: 40px+5px 0 25px 0;
	}
	@include tablet{
		padding: 90px 0 70px 0;
	}
	.checkbox input[type=checkbox], 
	.checkbox-inline input[type=checkbox], 
	.radio input[type=radio], 
	.radio-inline input[type=radio] {
	    margin-left: -21px;
	}
}

/*=== 5.5 BLOG SINGLE COMMENTS FORM ===*/
.commentsForm{
	@include mobile{
		padding: 0 0 90px 0;
	}
	@include tablet{
		padding: 0 0 30px 0;
	}
	h3{
		@extend %sectionTitle;
	}
	@extend .form-field;
	textarea.form-control{
		height: 150px !important;
	}
	.btn-primary{
		@include mobile-xs{
			width: 100%;
		}
		@include mobile{
			width: 100%;
		}
		@include tablet{
			width: 178px;
		}
	}
}
/*=== 5.6 STEPS PAGE FORM ===*/
.stepsPage{
	form{
		margin: 0 -15px;
		.form-group{
			margin-bottom: 0px;
			label{
				font-size: 13px;
				display: block;
				line-height: 1;
			}
			.form-control{
				@extend %formFieldPart;

			}
			.selectOptions{
				margin-bottom: 30px;
				@include tablet{
					margin-bottom: 0;
				}
			}
			.sbHolder {
				border: none;
			} 
			.sbSelector {
				background-color: #f0f0f0;
				position: relative;
				&:after {
					content: '\f0d7';
					font-family: FontAwesome;
					position: absolute;
					right: 15px;
				}
			}
			textarea{
				&.form-control{
					height: 150px;
				}
			}
		}
		.checkboxArea{
			float: left;
			margin: 10px+3 0 50px 0;
			input[type=radio]:not(old){
				margin    : 0;
				padding   : 0;
				font-size : 10px;
				opacity   : 0;
				& + label{
					display      : inline-block;
					margin-left  : -20px;
					line-height  : 20px;
					font-weight: 400; 
					font-size: 16px;
					span{
						background-color: $white-color;
						@include add-border(1px, $border-gray, all);
						@include border-radius(0);
						display          : inline-block;
						height           : 20px;
						margin           : 0 10px 0 0;
						vertical-align   : middle;
						width            : 20px;
						border-radius	: 50%;
						@include transition(all 0.3s ease-in-out);
					}
				}
				&:checked{
					& + label{
						span{
							background-color: $primary-color;
							@include add-border(1px, $primary-color, all);
							border-radius: 50%;
							position: relative;
							&:before{
								color       : $white-color;
								content     : "\f00c";
								font-family: 'FontAwesome';
								display     : block;
								font-size   : 12px;
								font-weight : normal;
								height      : 20px;
								line-height : 17px;
								text-align  : center;
								width       : 20px;
								position: absolute;
								left: -1px;
								top: 1px;
							}
						}
					}
				}
			}
			small {
				display: block;
				margin-left: 32px;
				line-height: 24px;
				font-size: 13px;
				color: $text-slider-title;
			}
		}
	}
}