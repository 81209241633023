/* ================================================
--> Elements input_group style
================================================== */
.element-input_group {
	
}
.input-group {
	.form-check-label {
		margin-bottom: 0px;
	}
	.input-group-addon {
		input[type=checkbox], input[type=radio] {
		    margin-top: 6px;
		}
	}
	.form-check {
		margin-bottom: 0px;
		.checkbox-custom + .checkbox-custom-label, .radio-custom + .radio-custom-label  {
			&:before {
				margin-right: 0px;
			}
		}
	}
}
label {
	font-size: 15px;
}
.input-group-addon {
    border-radius: 0px;
    background: $light-color;
    padding-left: 17px;
    padding-right: 17px;
    border: 1px solid #e5e5e5;
    font-size: 16px;
    color: $text-slider-title;
}
input.form-control {
    font-size: 15px;
    color: $text-slider-title;
}
.cr-addon {
	.input-group {
		margin-bottom: 30px;
	}
}