// scss file

/* ====== index =====
1. COMMON
2.HEADER
	2.1 HEADER V1
	2.1 HEADER V2
	2.1 HEADER V3
MAIN SLIDER 
	BANNER
	BANNER V1
	BANNER V2
	BANNER V3
	BANNER V4
3.HOME 
	3.1 HOME
		3.1.1 WHITE SECTION
		3.1.2 LIGHT SECTION
	3.2 HOME V2
		3.2.1 ABOUT
		3.2.2 FEATURE COLLECTION
		3.2.3 FEATURE PRODUCT
		3.2.4 FEATURES
		3.2.5 INSTAGRAM SECTION
	3.3 HOME V3
		3.3.1 DEAL SECTION
		3.3.2 FEATURE PRODUCT SECTION
		3.3.3 FEATURES
		3.3.4 TESTIMONIAL SECTION
		3.3.5 LIGHT SECTION
	3.4 HOME V4
		3.4.1 FEATURE COLLECTION
		3.4.2 CATEGORY SECTION
4.FOOTER
	4.1 FOOTER V1
	4.2 FOOTER V2
5. FORM
	5.1 FORM COMMON
	5.2 FORM SIGN UP
	5.3 FORM LOST PASSWORD
	5.4 FORM LOG IN
	5.5 BLOG SINGLE COMMENTS FORM
	5.6 STEPS PAGE FORM
6. MODAL
	6.1 LOGIN MODAL
	6.2 PORDUCT QUICK VIEW MODAL
7. PAGE HEADER
	7.1 COMMON HEADER
	7.2 ABOUT US HEADER
	7.3 CART PAGE HEADER
8. SIDEBARS
9. PRODUCTS
	7.1 PRODUCTS COMMON
	7.2 SINGLE PRODUCTS
	7.3 CART PRODUCTS
10. GENERIC CONTENT
11. 404 PAGE
12. BLOG
13. ABOUT US
14. STEPS
	14.1 STEP # 01
	14.2 STEP # 02
15. USER PROFILE
	15.1 COMMON PART
	15.2 PROFILE
16. COMMING SOON

===================== */
@import 'partials/mixins';
@import 'partials/variables';
@import 'partials/media-query';
@import 'reset';
@import 'common';
@import 'header-v1';
@import 'header-v2';
@import 'header-v3';
@import 'header-v4';
@import 'main-slider';
@import 'home';
@import 'home-v2';
@import 'home-v3';
@import 'home-v4';
@import 'home-v5';
@import 'footer-v1';
@import 'footer-v2';
@import 'form';
@import 'modal';
@import 'page-header';
@import 'sidebars';
@import 'products';
@import 'generic-content';
@import '404';
@import 'blog';
@import 'about-us';
@import 'steps';
@import 'user-profile';
@import 'comming-soon';
@import 'short-code';