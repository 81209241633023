/* ================================================
--> Elements collapse style
================================================== */
.card.card-accordion {
	margin: 10px 0; 
	
	.card-header {
	    padding: 0px;
	    border-radius: 0px;
	    border: none;
	    h5 {
	    	position: relative;
	    	a:after {
	    		top: 50%;
	    		@include transform(translateY(-50%))	    		
	    	}
	    }
		a {
			display: block;
			padding: 15px 20px;
	    	line-height: 24px;
			background-color: $primary-color;
			color: $white-color;
			border: 1px solid $primary-color;
			border-radius: 2px;

		}
		a[data-toggle=collapse]:after {
		    font-family: FontAwesome;
		    font-style: normal;
		    speak: none;
		    display: inline-block;
		    content: '\f106';
		    float: right;
		    line-height: 1;
		    width: 22px;
		    font-size: 20px;
		    text-align: center;
		    position: absolute;
		    right: 15px;
		}
		a.collapsed {
			line-height: 24px;
			color: $secondary-color;
			background-color: $light-color;
			border: 1px solid #e5e5e5;
		}
		a[data-toggle=collapse].collapsed:after {
		    font-family: FontAwesome;
		    font-style: normal;
		    speak: none;
		    display: inline-block;
		    content: '\f107';
		}
	}
	.card-block {
		border: 1px solid #e5e5e5;
		border-top: 0px;
		padding: 30px 20px;
	    font-size: 15px;
	    line-height: 26px;
	}
}
.icon-left.card.card-accordion {
	.card-header {
	    padding: 0px;
	    border-radius: 0px;
	    border: none;
		a {
			display: block;
			padding: 15px 20px;
			line-height: 24px;
			padding-left: 50px;
			border-radius: 2px;
		}
		&.header-inverse {
			a {
				background-color: $white-color;
				color: $primary-color;
				border: 1px solid $primary-color;
			}
		}
		a[data-toggle=collapse]:after {
		    font-family: FontAwesome;
		    font-style: normal;
		    display: inline-block;
		    content: '\f106';
		    line-height: 1;
		    width: 22px;
		    margin-right: 10px;
		    font-size: 20px;
		    text-align: center;
		    position: absolute;
		    left: 15px;
		}
		a.collapsed {
			line-height: 24px;
			color: $secondary-color;
			background-color: $light-color;
			border: 1px solid #e5e5e5;
		}
		a[data-toggle=collapse].collapsed:after {
		    font-family: FontAwesome;
		    font-style: normal;
		    speak: none;
		    display: inline-block;
		    content: '\f107';
		}
	}
} 