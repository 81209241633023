/*=== MIXINS ===*/
@mixin box-sizing($box) {
  -webkit-box-sizing: $box;
  -moz-box-sizing: $box;
  box-sizing: $box;
}
@mixin border-radius($radius: 4px) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}
@mixin box-shadow($horizontal: 0px, $vertical: 1px, $blur: 2px, $color: #CCC) {
  -webkit-box-shadow: $horizontal $vertical $blur $color;
  -moz-box-shadow: $horizontal $vertical $blur $color;
  box-shadow: $horizontal $vertical $blur $color;
}
@mixin opacity ( $value: 0.5 ) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=$value)";
  filter: alpha(opacity=$value);
  -moz-opacity: $value;
  -khtml-opacity: $value; 
  opacity: $value;
}
@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
@mixin add-border($size, $color, $position){
  @if $position == 'all'{
    border:$size solid $color;
  } @else if $position == 'top'{
    border-top:$size solid $color;
  } @else if $position == 'bottom'{
    border-bottom:$size solid $color;
  } @else if $position == 'right'{
    border-right:$size solid $color;
  } @else if $position == 'left'{
    border-left:$size solid $color;
  } @else if $position == 'top-bottom'{
    border-top:$size solid $color;
    border-bottom:$size solid $color;
  } @else if $position == 'right-left'{
    border-left:$size solid $color;
    border-right:$size solid $color;
  } @else if $position == 'top-left'{
    border-left:$size solid $color;
    border-right:$size solid $color;
  } @else if $position == 'top-right'{
    border-left:$size solid $color;
    border-right:$size solid $color;
  } @else if $position == 'bottom-left'{
    border-left:$size solid $color;
    border-right:$size solid $color;
  } @else if $position == 'bottom-right'{
    border-left:$size solid $color;
    border-right:$size solid $color;
  }
}
@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}
@mixin background-image{
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  height: auto;
}
@mixin placeholder {
  &::-webkit-input-placeholder {@content};
  &:-moz-placeholder           {@content};
  &::-moz-placeholder          {@content};
  &:-ms-input-placeholder      {@content}; 
}
